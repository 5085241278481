import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { YYYYMMDDString } from '@rootTypes';
import { UntypedFormControl } from '@angular/forms';
import { YearMonthDay } from '@rootTypes/utils/common/date';

@Component({
  selector: 'wp-week-filter',
  templateUrl: './week-filter.component.html',
  styleUrls: ['./week-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekFilterComponent implements OnInit, OnChanges {
  @Input() public selectedDates: YYYYMMDDString[] = [];
  @Input() public widthStr = 'auto';
  @Input() public isSimpleInput: boolean;
  @Input() public isInputField: boolean;
  @Input() public inputLabel = 'Week';
  @Input() public weekSelectCount = 1;
  @Input() public isStartWeekFromMonday = true;
  @Input() public disableDatesBefore?: YYYYMMDDString;
  @Input() public disableDatesAfter?: YYYYMMDDString;
  @Input() public isDisabled: boolean;
  @Output() public selectedChanged = new EventEmitter<YYYYMMDDString[]>();
  public isOpened: boolean;

  public control = new UntypedFormControl();

  constructor() {}

  ngOnInit(): void {}

  public onMenuOpenChanged(isOpened: boolean): void {
    this.isOpened = isOpened;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDates) {
      this.updateControlValue();
    }
  }

  public updateControlValue(): void {
    if (this.selectedDates && this.selectedDates.length) {
      this.control.setValue(
        `${this.yyyyMMDDToDisplay(this.selectedDates[0])} - ${this.yyyyMMDDToDisplay(
          this.selectedDates[this.selectedDates.length - 1],
        )}`,
      );
    } else {
      this.control.setValue(undefined);
    }
  }

  private yyyyMMDDToDisplay(s: YYYYMMDDString): string {
    const f = new YearMonthDay(s);
    return f.formatTo('dateMedium');
  }
}
