import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ButtonsModule } from '../buttons/buttons.module';
import { DisplayPhotoComponent } from './display-photo/display-photo.component';
import { PhotoPlaceholderComponent } from './photo-placeholder/photo-placeholder.component';
import { DisplayPhotoSquareMultiComponent } from './display-photo-square-multi/display-photo-square-multi.component';
import { PhotoInputPopupComponent } from './photo-input-popup/photo-input-popup.component';
import { TypographyModule } from '../typography/typography.module';
import { PhotoInputSyncComponent } from './photo-input-sync/photo-input-sync.component';
import { PhotoInputComponent } from './photo-input/photo-input.component';
import { ComponentsModule } from '../components/components.module';
import { ImageZoomComponent } from './image-zoom/image-zoom.component';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { MultiPhotoInputPopupComponent } from './multi-photo-input-popup/multi-photo-input-popup.component';
import { AlertsModule } from '../alerts/alerts.module';

const containers = [
  DisplayPhotoComponent,
  DisplayPhotoSquareMultiComponent,
  PhotoInputSyncComponent,
  PhotoInputComponent,
  ImageZoomComponent,
];

@NgModule({
  declarations: [...containers, PhotoPlaceholderComponent, PhotoInputPopupComponent, MultiPhotoInputPopupComponent],
  imports: [
    CommonModule,
    ImageCropperModule,
    ButtonsModule,
    TypographyModule,
    ComponentsModule,
    IconCloseModule,
    AlertsModule,
  ],
  exports: [...containers, PhotoPlaceholderComponent],
})
export class PhotosModule {}
