export const lightenDarkenColor = (color: string, percent: number): string => {
  // let usePound = false;
  // if (col[0] == '#') {
  //   col = col.slice(1);
  //   usePound = true;
  // }
  //
  // const num = parseInt(col, 16);
  //
  // let r = (num >> 16) + amt;
  //
  // if (r > 255) r = 255;
  // else if (r < 0) r = 0;
  //
  // let b = ((num >> 8) & 0x00ff) + amt;
  //
  // if (b > 255) b = 255;
  // else if (b < 0) b = 0;
  //
  // let g = (num & 0x0000ff) + amt;
  //
  // if (g > 255) g = 255;
  // else if (g < 0) g = 0;
  //
  // return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100) as any);
  G = parseInt(((G * (100 + percent)) / 100) as any);
  B = parseInt(((B * (100 + percent)) / 100) as any);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};
