<div
  class="wp-photo-input"
  [ngStyle]="{'width': containerWidth, 'height': containerHeight}"
  (keyup)="onInputKeyUp($event)"
>
  <wp-section-spinner
    *ngIf="initialImageLoading; else imageLoaded"
    [sectionHeight]="containerHeight"
  >
  </wp-section-spinner>

  <ng-template #imageLoaded>
    <div
      class="label"
      [ngClass]="{error: isRequiredErrorShown || initialImageError}"
    >
      {{label}}
    </div>

    <wp-display-photo
      [width]="previewWidth"
      [height]="previewHeight"
      [isRound]="isRoundPreview"
      [imageBase64]="useFormControlForPreview ? (controlValueChanges$ | async) : previewImageBase64"
      [placeholderType]="imagePlaceholder"
    >
    </wp-display-photo>

    <wp-link-btn [tabIndex]="tabIndex" class="button" (clicked)="open()"
      >{{buttonText}}
    </wp-link-btn>

    <div *ngIf="isRequiredErrorShown$ | async" class="error-message">
      Image is required
    </div>

    <div *ngIf="initialImageError" class="error-message">
      Failed to load image
    </div>
  </ng-template>
</div>
