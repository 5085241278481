import { Injectable } from '@angular/core';
import { Address } from '@rootTypes';

@Injectable({
  providedIn: 'root',
})
export class GeocoderService {
  private geocoder: google.maps.Geocoder;

  constructor() {
    this.geocoder = new google.maps.Geocoder();
  }

  public getAddressFromLocation(location: google.maps.LatLng): Promise<Address | undefined> {
    return new Promise<Address>((resolve, reject) => {
      this.geocoder.geocode({ location }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const geocode: google.maps.GeocoderResult = results.find((item) => !!item.address_components);
          const address = geocode ? this.getAddressFromGeocode(geocode, location) : undefined;
          return resolve(address);
        }

        const err = new Error(`GeocoderService.getAddressFromLocation() failed, status ${status}`);
        reject(err);
        console.error(err);
      });
    });
  }

  private getAddressFromGeocode(geocode: google.maps.GeocoderResult, location: google.maps.LatLng): Address {
    return {
      formatted_address: geocode.formatted_address,
      name: geocode.formatted_address,
      geometry: {
        // set user defined location
        location: location.toJSON(),
      },
      address_components: geocode.address_components,
    };
  }
}
