import { EntityState } from '@rootTypes';
import { RouteGroup, RouteGroupScheduleInfo, RouteGroupScheduleVersion } from '@apiEntities/route-group/route-group';
import { SelectOption } from '../../../shared/form-controls';
import { createReducer, on } from '@ngrx/store';
import {
  getRouteGroupActions,
  getRouteGroupFailed,
  getRouteGroupScheduleFailed,
  getRouteGroupScheduleRequestedFromRouteGroupDetails,
  getRouteGroupScheduleSuccess,
  getRouteGroupScheduleVersionsByWeekFailed,
  getRouteGroupScheduleVersionsByWeekFromRouteGroupDetails,
  getRouteGroupScheduleVersionsByWeekSuccess,
  getRouteGroupSuccess,
  setRouteGroupActivitySyncFlagFailed,
  setRouteGroupActivitySyncFlagRequested,
  setRouteGroupActivitySyncFlagSuccess,
} from '../actions/route-group.actions';
import { stringifyDateRange } from '../utils/stringify-date-range';
import { scheduleVersionToSelectOption } from '../utils/schedule-version-to-select-option';

export interface RouteGroupDataState {
  routeGroups: {
    [routeGroupId: string]: EntityState<RouteGroup>;
  };
  syncWithActivity: {
    [routeGroupId: string]: {
      enabled: boolean;
      isLoading: boolean;
    };
  };
  routeGroupScheduleOptionsByWeeks: {
    [routeGroupId: string]: {
      // weekId = "startYYYYMMDD-endYYYYMMDD"
      [weekId: string]: EntityState<RouteGroupScheduleOptionsByWeeksState>;
    };
  };
  routeGroupSchedules: {
    [routeGroupScheduleId: string]: EntityState<RouteGroupScheduleInfo>;
  };
}

export interface RouteGroupScheduleOptionsByWeeksState {
  defaultId: string;
  versions: SelectOption[];
}

export const getInitialRouteGroupDataState = (): RouteGroupDataState => {
  return {
    routeGroups: {},
    routeGroupScheduleOptionsByWeeks: {},
    routeGroupSchedules: {},
    syncWithActivity: {},
  };
};

export const routeGroupDataReducer = createReducer<RouteGroupDataState>(
  getInitialRouteGroupDataState(),
  on(...getRouteGroupActions, (state, action) => {
    const { routeGroupId } = action;
    const prevState = state.routeGroups[routeGroupId] || { isLoading: false };
    return {
      ...state,
      routeGroups: {
        ...state.routeGroups,
        [routeGroupId]: {
          ...prevState,
          isLoading: true,
        },
      },
    };
  }),
  on(getRouteGroupSuccess, (state, action) => {
    const { routeGroup } = action;
    const { routeGroupId, routeGroupSchedule, routeGroupSchedulesForThisWeek } = routeGroup;
    const resultState: RouteGroupDataState = {
      ...state,
      routeGroups: {
        ...state.routeGroups,
        [routeGroupId]: {
          isLoading: false,
          entity: routeGroup,
          error: undefined,
        },
      },
      syncWithActivity: {
        ...state.syncWithActivity,
        [routeGroupId]: {
          enabled: (routeGroup as RouteGroup).isActivitySyncEnabled,
          isLoading: false,
        },
      },
    };
    if (routeGroupSchedule) {
      return saveRouteGroupScheduleVersionsToState(
        routeGroupId,
        stringifyDateRange({ startDate: routeGroupSchedule.effectiveFrom, endDate: routeGroupSchedule.effectiveTo }),
        routeGroupSchedule,
        routeGroupSchedulesForThisWeek,
        resultState,
      );
    } else {
      return resultState;
    }
  }),
  on(getRouteGroupFailed, (state, action) => {
    const { routeGroupId, error } = action;
    return {
      ...state,
      routeGroups: {
        [routeGroupId]: {
          entity: undefined,
          isLoading: false,
          error,
        },
      },
    };
  }),
  on(getRouteGroupScheduleVersionsByWeekFromRouteGroupDetails, (state, action) => {
    const { routeGroupId, week } = action;
    const stringifiedWeek = stringifyDateRange(week);
    return {
      ...state,
      routeGroupScheduleOptionsByWeeks: {
        ...state.routeGroupScheduleOptionsByWeeks,
        [routeGroupId]: {
          ...(state.routeGroupScheduleOptionsByWeeks[routeGroupId] || {}),
          [stringifiedWeek]: {
            isLoading: true,
          },
        },
      },
    };
  }),

  on(getRouteGroupScheduleVersionsByWeekSuccess, (state, action) => {
    const { routeGroupId, week, routeGroup } = action;
    const { routeGroupSchedule, routeGroupSchedulesForThisWeek } = routeGroup;
    if (routeGroupSchedule) {
      return saveRouteGroupScheduleVersionsToState(
        routeGroupId,
        stringifyDateRange(week),
        routeGroupSchedule,
        routeGroupSchedulesForThisWeek,
        state,
      );
    } else {
      return {
        ...state,
        routeGroupScheduleOptionsByWeeks: {
          ...state.routeGroupScheduleOptionsByWeeks,
          [routeGroupId]: {
            ...(state.routeGroupScheduleOptionsByWeeks[routeGroupId] || {}),
            [stringifyDateRange(week)]: {
              isLoading: false,
              entity: {
                defaultId: undefined,
                versions: [],
              },
            },
          },
        },
      };
    }
  }),
  on(getRouteGroupScheduleVersionsByWeekFailed, (state, action) => {
    const { routeGroupId, week, error } = action;
    const stringifiedWeek = stringifyDateRange(week);
    return {
      ...state,
      routeGroupScheduleOptionsByWeeks: {
        ...state.routeGroupScheduleOptionsByWeeks,
        [routeGroupId]: {
          ...(state.routeGroupScheduleOptionsByWeeks[routeGroupId] || {}),
          [stringifiedWeek]: {
            isLoading: false,
            error,
          },
        },
      },
    };
  }),
  on(getRouteGroupScheduleRequestedFromRouteGroupDetails, (state, action) => {
    const { routeGroupScheduleId } = action;
    return {
      ...state,
      routeGroupSchedules: {
        ...state.routeGroupSchedules,
        [routeGroupScheduleId]: {
          isLoading: true,
        },
      },
    };
  }),
  on(getRouteGroupScheduleSuccess, (state, action) => {
    const { routeGroupSchedule } = action;
    const { routeGroupScheduleId } = routeGroupSchedule;
    return {
      ...state,
      routeGroupSchedules: {
        ...state.routeGroupSchedules,
        [routeGroupScheduleId]: {
          isLoading: false,
          entity: routeGroupSchedule,
        },
      },
    };
  }),
  on(getRouteGroupScheduleFailed, (state, action) => {
    const { routeGroupScheduleId, error } = action;
    return {
      ...state,
      routeGroupSchedules: {
        ...state.routeGroupSchedules,
        [routeGroupScheduleId]: {
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Activity sync toggle
   */
  on(setRouteGroupActivitySyncFlagRequested, (state, action) => {
    const { routeGroupId } = action;
    return {
      ...state,
      syncWithActivity: {
        ...state.syncWithActivity,
        [routeGroupId]: {
          ...(state.syncWithActivity[routeGroupId] || {}),
          isLoading: true,
        },
      },
    } as RouteGroupDataState;
  }),
  on(setRouteGroupActivitySyncFlagSuccess, (state, action) => {
    const { routeGroupId, enabled } = action;
    return {
      ...state,
      syncWithActivity: {
        ...state.syncWithActivity,
        [routeGroupId]: {
          ...(state.syncWithActivity[routeGroupId] || {}),
          isLoading: false,
          enabled,
        },
      },
    } as RouteGroupDataState;
  }),
  on(setRouteGroupActivitySyncFlagFailed, (state, action) => {
    const { routeGroupId } = action;
    return {
      ...state,
      syncWithActivity: {
        ...state.syncWithActivity,
        [routeGroupId]: {
          ...(state.syncWithActivity[routeGroupId] || {}),
          isLoading: false,
        },
      },
    } as RouteGroupDataState;
  }),
);

function saveRouteGroupScheduleVersionsToState(
  routeGroupId: string,
  stringifiedWeek: string,
  routeGroupSchedule: RouteGroupScheduleInfo,
  routeGroupSchedulesForThisWeek: RouteGroupScheduleVersion[],
  state: RouteGroupDataState,
): RouteGroupDataState {
  const { routeGroupScheduleId } = routeGroupSchedule;
  const versions = (routeGroupSchedulesForThisWeek || []).map((version) => scheduleVersionToSelectOption(version));
  const includeDefaultVersion = versions.some((v) => v.value === routeGroupScheduleId);
  if (!includeDefaultVersion) {
    versions.unshift({ value: routeGroupScheduleId, displayLabel: routeGroupSchedule.version });
  }
  return {
    ...state,
    routeGroupScheduleOptionsByWeeks: {
      ...state.routeGroupScheduleOptionsByWeeks,
      [routeGroupId]: {
        ...(state.routeGroupScheduleOptionsByWeeks[routeGroupId] || {}),
        [stringifiedWeek]: {
          isLoading: false,
          entity: {
            defaultId: routeGroupScheduleId,
            versions,
          },
        },
      },
    },
    routeGroupSchedules: {
      ...state.routeGroupSchedules,
      [routeGroupScheduleId]: {
        isLoading: false,
        entity: routeGroupSchedule,
      },
    },
  };
}
