import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { YardsApiService } from '../../services/yards-api.service';
import * as fromTypes from '../../types';
import * as fromYardDataActions from '../actions/yard-data.actions';
import * as fromVendorDataActions from '../../../vendors/store/actions/vendor-data.actions';
import { getYardState } from '../selectors';

@Injectable()
export class YardDataEffects {
  constructor(
    private actions$: Actions,
    private api: YardsApiService,
    private store: Store,
  ) {}

  public loadYardRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromYardDataActions.loadYardRequested),
      mergeMap((action) => this.onLoadYardRequested(action.yardId, action.options)),
    ),
  );

  public lazyLoadYardRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromYardDataActions.lazyLoadYardRequested),
      concatLatestFrom(({ yardId }) => this.store.select(getYardState(yardId))),
      filter(([, state]) => !state.isLoading && !state.entity),
      map(([{ yardId }]) => {
        return fromYardDataActions.loadYardRequested({ yardId });
      }),
    ),
  );

  /**
   * Save vendor from projection
   */
  public loadedYardWithVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromYardDataActions.loadYardSuccess),
      filter((action) => !!action.vendor),
      map((action) => {
        const { vendor } = action;
        return fromVendorDataActions.loadVendorSuccess({ vendorId: vendor.id, vendor });
      }),
    ),
  );

  private onLoadYardRequested(yardId: string, options?: fromTypes.LoadYardOptions): Observable<Action> {
    return this.api.getYard(yardId, options).pipe(
      map((resp) => {
        const { yard, vendor } = resp;
        return fromYardDataActions.loadYardSuccess({
          yardId,
          yard,
          vendor,
        });
      }),
      catchError((err) => {
        console.log(err);
        return of(
          fromYardDataActions.loadYardFailed({
            yardId,
            error: {
              text: 'Failed to load yard',
              originalError: err,
            },
          }),
        );
      }),
    );
  }
}
