import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subscription, timer } from 'rxjs';
import { map, shareReplay, startWith, take } from 'rxjs/operators';
import { DateRange, YYYYMMDDString } from '@rootTypes';
import * as fromTypes from '@rootTypes';
import { defaultDisplayDateFn } from '../input-date-multi/default-display-date-fn';
import { DropdownComponent } from '../dropdown/dropdown/dropdown.component';
import { dateRangeToArray } from './date-range-array-fn';
import { YearMonthDay } from '@rootTypes/utils/common/date';

@Component({
  selector: 'wp-input-date-range',
  templateUrl: './input-date-range.component.html',
  styleUrl: './input-date-range.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateRangeComponent implements OnInit {
  @Input() public displayDateFn: (dates: fromTypes.utils.date.YYYYMMDDString[]) => string = defaultDisplayDateFn;
  @Input() public label: string;
  @Input() public control: UntypedFormControl = new UntypedFormControl(null);
  @Input() public weekSelectCount: number;
  @Input() public isStrictDisableWeeks: boolean;
  @Input() public monthCount = 2;
  @Input() public alignDropdown: 'left' | 'right' | 'center';
  @Input() public disableDatesBefore: fromTypes.utils.date.YYYYMMDDString;
  @Input() public disableDatesAfter: fromTypes.utils.date.YYYYMMDDString;
  @Input() isIcon: boolean;

  @Output() public selectedDatesRange: EventEmitter<YYYYMMDDString[]> = new EventEmitter<YYYYMMDDString[]>();

  public highlighted$: Observable<boolean>;
  public isDisabled$: Observable<boolean>;
  public selectedDateArr$: Observable<fromTypes.YYYYMMDDString[]>;
  public displayDateControl: UntypedFormControl = new UntypedFormControl();
  public selectedDates: DateRange;

  private focused$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private subscripitions: Subscription = new Subscription();
  @ViewChild('maskEl') private triggerMaskEl: ElementRef;
  @ViewChild('dropdownEl') private dropdownEl: DropdownComponent;

  constructor() {}

  ngOnInit(): void {
    this.checkInputFormat();
    this.highlighted$ = this.getHighLightedObs();
    this.isDisabled$ = this.getDisabledObs$();
    this.selectedDateArr$ = this.control.valueChanges.pipe(
      startWith(this.control.value),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );
    const sub = this.control.valueChanges.pipe(startWith(this.control.value)).subscribe((val: DateRange) => {
      if (val) {
        const displayStartDate = new YearMonthDay(val.startDate).formatTo('monthAndDay');
        const displayEndDate = new YearMonthDay(val.endDate).formatTo('monthAndDay');
        this.displayDateControl.setValue(`${displayStartDate} - ${displayEndDate}`);
        this.selectedDatesRange.emit(dateRangeToArray(val));
      } else {
        this.displayDateControl.setValue(null);
        this.selectedDatesRange.emit([]);
      }
    });
    const sub2 = this.control.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        this.displayDateControl.setErrors(this.control.errors);
        this.displayDateControl.markAsTouched();
      } else {
        this.displayDateControl.setErrors(null);
      }
    });
    this.subscripitions.add(sub);
    this.subscripitions.add(sub2);
  }

  public onTriggerClick(event: MouseEvent): void {
    this.isDisabled$.pipe(take(1)).subscribe((disabled) => {
      if (!disabled) {
        this.focused$$.next(true);
        this.displayDateControl.markAsTouched();
      } else {
        event.stopPropagation();
      }
    });
    if (this.control.value) {
      this.selectedDates = this.control.value;
    }
  }

  public onCloseDropdown(): void {
    this.focused$$.next(false);
    this.selectedDates = null;
  }

  public onValueChangedByUserAction(val: DateRange): void {
    this.control.setValue(val || null);
    this.selectedDatesRange.emit(dateRangeToArray(val));
  }

  public onClearDatesClicked(): void {
    this.control.setValue(null);
    this.selectedDatesRange.emit(dateRangeToArray(null));
  }

  public onApplyClicked(): void {
    if (this.dropdownEl) {
      this.dropdownEl.onCloseDropdown();
    }
  }

  private getHighLightedObs(): Observable<boolean> {
    const focused$ = this.focused$$.asObservable();
    const valid$ = this.control.statusChanges.pipe(
      startWith('VALID'),
      map((status) => status !== 'INVALID'),
    );
    return combineLatest([focused$, valid$]).pipe(map(([focused, valid]) => focused && valid));
  }

  private checkInputFormat(): void {
    const value = this.control.value;
    if (value !== null && value !== undefined) {
      if (!(typeof value === 'object' && Array.isArray(value))) {
        throw new Error(
          'Invalid value provided to input date. Should be an array of YYYYMMDD string. Got ' +
            typeof value +
            ' instead.',
        );
      }
      if (value.length) {
        const firstItem = value[0];
        if (!(typeof firstItem === 'string')) {
          throw new Error(
            'Invalid value provided to input date. The array should contain YYYYMMDD strings. Got ' +
              typeof firstItem +
              ' instead.',
          );
        }
      }
    }
  }

  private getDisabledObs$(): Observable<boolean> {
    return timer(0, 600).pipe(
      map(() => this.control.disabled),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}
