import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PortalRouterLinkData } from '../../../router/types/portal-router-link-data';

@Component({
  selector: 'wp-portal-router-link',
  template: `
    <a
      [routerLink]="routerLinkData?.routerLink"
      [queryParams]="routerLinkData?.queryParams || {}"
      [queryParamsHandling]="routerLinkData?.queryParamsHandling"
      [replaceUrl]="routerLinkData?.replaceUrl"
      class="portal-router-link"
      [ngClass]="{ 'no-formatting': noFormatting }"
      (click)="onLinkClick($event)"
    >
      <ng-content></ng-content>
    </a>
  `,
  styleUrl: `./portal-router-link.component.scss`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalRouterLinkComponent {
  @Input() public routerLinkData: PortalRouterLinkData;
  @Input() public stopPropagationOnClick = true;
  @Input() public noFormatting = false;

  onLinkClick(event: MouseEvent): void {
    if (this.stopPropagationOnClick) {
      event.stopPropagation();
    }
  }
}
