<!--matPrefix and matSuffix are for consistent layout-->
<mat-form-field
  class="wp-address-input"
  [floatLabel]="floatLabel"
  [hideRequiredMarker]="true"
>
  <mat-label *ngIf="!!label">{{label}}</mat-label>
  <div matPrefix></div>
  <input
    matInput
    [placeholder]="placeholder"
    [formControl]="displayControl"
    [required]="required"
    #inputElement
    (input)="onUserInput()"
  />
  <div matSuffix></div>
  <mat-error
    *ngIf="displayControl.hasError('required') && !displayControl.hasError('notPickedFromList')"
  >
    Required field
  </mat-error>
  <mat-error *ngIf="displayControl.hasError('notPickedFromList')">
    Address not recognized, pick an address from the list
  </mat-error>
</mat-form-field>
