// BD-3569 TODO
import { MapPortalShapeConfig } from '../types/portal-shape';
import { PortalPolygonOptions } from '../types/portal-polygon';
import { MapPopup } from './map-popup-renderer';
import LatLngLiteral = google.maps.LatLngLiteral;

type PortalPolygonRendered = MapPortalShapeConfig<google.maps.Polygon, google.maps.Marker>;

export class PolygonRenderer {
  private gPolygons: PortalPolygonRendered[] = [];
  private activePopup: MapPopup | null = null;

  constructor(private map: google.maps.Map) {}

  public async setPolygons(polygons: PortalPolygonOptions[]): Promise<void> {
    if (!polygons) {
      return;
    }
    const removedPolygons = this.getRemovedPolygons(this.gPolygons, polygons);
    const addedPolygons = this.getAddedPolygons(this.gPolygons, polygons);
    removedPolygons.forEach((removed) => {
      removed.shape.setMap(null);
      // call remove for custom polygons
      if ('remove' in removed) {
        (removed as any).remove();
      }
      const removedIndex = this.gPolygons.findIndex((gp) => gp === removed);
      this.gPolygons.splice(removedIndex, 1);
    });

    const promises = addedPolygons.map((p) => this.addPolygonToMap(p));
    await Promise.all(promises);
  }

  public fitMapToPolygons(): void {
    if (!this.gPolygons.length) {
      return;
    }
    if (this.gPolygons.length === 1) {
      const polygon = this.gPolygons[0];
      this.map.setCenter(this.getPolygonCenter(polygon.shape));
      this.map.setZoom(17);
      return;
    }
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < this.gPolygons.length; i++) {
      bounds.extend(this.getPolygonCenter(this.gPolygons[i].shape));
    }
    if (this.map) {
      this.map.fitBounds(bounds);
    }
  }

  private async addPolygonToMap(polygon: PortalPolygonOptions): Promise<void> {
    if (polygon) {
      return this.addPolygonToMapHandler(polygon);
    }
  }

  private async addPolygonToMapHandler(polygon: PortalPolygonOptions): Promise<void> {
    const gPolygon = new google.maps.Polygon({
      paths: polygon.shape.paths,
      strokeColor: polygon.shape.strokeColor,
      strokeOpacity: polygon.shape.strokeOpacity,
      strokeWeight: polygon.shape.strokeWeight,
      fillColor: polygon.shape.fillColor,
      fillOpacity: polygon.shape.fillOpacity,
      editable: polygon.shape.editable,
      draggable: polygon.shape.draggable,
    });

    let polygonMarker: google.maps.Marker = null;

    const gPolygonData = {
      shape: gPolygon,
      marker: null,
    };

    if (polygon.marker) {
      polygonMarker = new google.maps.Marker({
        ...polygon.marker,
        position: polygon.marker.position
          ? ({ lat: polygon.marker.position.lat, lng: polygon.marker.position.lng } as LatLngLiteral)
          : this.getPolygonCenter(gPolygon),
      });
      gPolygonData.marker = polygonMarker;
      polygonMarker.setMap(this.map);
    }

    if (polygon.tooltip) {
      gPolygon.addListener('click', () => {
        this.removeTooltip();
        import('./map-popup-renderer').then((mp) => {
          this.activePopup = new mp.MapPopup(
            new google.maps.LatLng(this.getPolygonCenter(gPolygon).lat(), this.getPolygonCenter(gPolygon).lng()),
            polygon.tooltip,
            polygon.tooltipClickHandler,
          );
          this.activePopup.setMap(this.map);
        });
      });

      this.map.addListener('click', () => {
        this.removeTooltip();
      });
    }

    if (polygon.shape.onPolygonMoved) {
      gPolygon.addListener('dragend', () => {
        polygon.shape.onPolygonMoved(gPolygon.getPath().getArray());
      });
    }

    if (polygon.shape.onPointChanged) {
      google.maps.event.addListener(gPolygon.getPath(), 'set_at', () => {
        polygon.shape.onPointChanged(gPolygon.getPath().getArray());
      });
    }

    gPolygon.set('compareId', polygon.shape.compareId);

    this.gPolygons.push(gPolygonData);
    gPolygon.setMap(this.map);

    this.map.addListener('click', () => {
      this.removeTooltip();
    });
  }

  removeTooltip(): void {
    if (this.activePopup) {
      this.activePopup.setMap(null);
      this.activePopup = null;
    }
  }

  private removePolygon(gPolygonData: PortalPolygonRendered) {
    gPolygonData.shape.setMap(null);
    gPolygonData.marker.setMap(null);

    const index = this.gPolygons.indexOf(gPolygonData);
    if (index !== -1) {
      this.gPolygons.splice(index, 1);
    }
  }

  private getAddedPolygons(
    previous: PortalPolygonRendered[],
    newPolygons: PortalPolygonOptions[],
  ): PortalPolygonOptions[] {
    const result = [];
    newPolygons.forEach((newPolygon) => {
      if (!newPolygon.shape.compareId) {
        result.push(newPolygon);
      } else {
        const wasAdded = previous.some(
          (prevPolygon) => prevPolygon.shape.get('compareId') === newPolygon.shape.compareId,
        );
        if (!wasAdded) {
          result.push(newPolygon);
        }
      }
    });
    return result;
  }

  private getRemovedPolygons(
    previous: PortalPolygonRendered[],
    newPolygons: PortalPolygonOptions[],
  ): PortalPolygonRendered[] {
    const result = [];
    previous.forEach((prevPolygon) => {
      const prevCompareId = prevPolygon.shape.get('compareId');
      if (!prevCompareId) {
        result.push(prevPolygon);
      } else {
        const isPresentInTheNewBatch = newPolygons.some((newPolygon) => newPolygon.shape.compareId === prevCompareId);
        if (!isPresentInTheNewBatch) {
          result.push(prevPolygon);
        }
      }
    });
    return result;
  }

  public getPolygonCenter(polygon: google.maps.Polygon) {
    const path = polygon.getPath().getArray();

    let latSum = 0,
      lngSum = 0;

    for (let i = 0; i < path.length; i++) {
      latSum += path[i].lat();
      lngSum += path[i].lng();
    }

    const latCenter = latSum / path.length;
    const lngCenter = lngSum / path.length;

    return new google.maps.LatLng({ lat: latCenter, lng: lngCenter });
  }
}
