<div class="example-full-width">
  <wp-input-date
    [label]="model.label"
    [isIcon]="true"
    [control]="model.control"
    [controlType]="'yyyymmdd'"
    [closeOnSelect]="true"
    [disableDatesBefore]="model.minDate"
    [disableDatesAfter]="model.maxDate"
    [monthCount]="1"
    [tabIndex]="tabIndex"
  ></wp-input-date>
</div>
