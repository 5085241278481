import * as fromApi from '../get-route';
import * as fromCommonApi from '../../common';
import * as fromEntities from '../../../../types/entities/route';
import { metersToDisplayDistance, millisToDisplayDuration } from 'src/app/types/utils/common';
import { dbToRouteRepeatMap } from './db-to-route-repeat-map';
import { getUserTimezone } from '@rootTypes/utils/common/date-time-fns';
import { getShortTimezone } from '@rootTypes/utils/common/date';

const getDistanceMeters = (sequencedEvents: fromCommonApi.RouteEvent[]): number => {
  let distanceMeters = 0;
  sequencedEvents.forEach((event) => {
    distanceMeters += event.distance;
  });
  return distanceMeters;
};

/**
 *
 * @param events - route events in the correct sequence
 */
const getRouteDurationMillis = (events: fromCommonApi.RouteEvent[]): number => {
  const firstEvent = events[0];
  const lastEvent = events[events.length - 1];
  const dayKey = Object.keys(firstEvent.start_times)[0];
  const lastEventDayTime = lastEvent.start_times[dayKey];
  const lastEventEndTime = lastEventDayTime.start_time + lastEvent.duration_wait_time;
  return lastEventEndTime - firstEvent.start_times[dayKey].start_time;
};

const getRouteStatus = (src: fromApi.GetRouteResponse): fromEntities.RouteStatusType => {
  switch (src.status.value) {
    case fromApi.RouteStatus.ACTIVE:
      return fromEntities.RouteStatusType.ACTIVE;
    case fromApi.RouteStatus.DRAFT:
      return fromEntities.RouteStatusType.DRAFT;
    case fromApi.RouteStatus.INACTIVE:
      return fromEntities.RouteStatusType.INACTIVE;
  }
};

const getSequencedEvents = (src: fromApi.GetRouteResponse): fromCommonApi.RouteEvent[] => {
  if (!(src && src.events)) {
    return [];
  }
  const events = Object.keys(src.events).map((evId) => src.events[evId]);
  events.sort((a, b) => a.event_sequence - b.event_sequence);

  return events;
};

export const dbToRoute = (source: fromApi.GetRouteResponse): fromEntities.Route => {
  const sequencedEvents = getSequencedEvents(source);
  const durationMillis = getRouteDurationMillis(sequencedEvents);
  const distanceMeters = getDistanceMeters(sequencedEvents);
  const target = {
    routeId: source.route_id,
    districtId: source.zum_enterprise_id,
    ownerId: source.owner_id,
    displayRouteName: source.display_name,
    status: getRouteStatus(source),
    displayRouteId: source.display_id,
    stopCount: source.events ? Object.keys(source.events).length : null,
    studentCount: source.riders ? Object.keys(source.riders).length : null,
    displayDistance: distanceMeters ? metersToDisplayDistance(distanceMeters) : null,
    displayDuration: durationMillis ? millisToDisplayDuration(durationMillis, true) : null,
    timezoneAbbreviation: getShortTimezone(source.timezone),
    isInUserTimezone: source.timezone === getUserTimezone(),
    group: source.group,
    campuses: [],
  } as fromEntities.Route;
  if (source.exceptions) {
    target.timeExceptions = Object.keys(source.exceptions).map((excDate) => ({
      date: excDate,
      time: source.exceptions[excDate].startTime,
      displayName: source.exceptions[excDate].startTimeName,
    }));
  }
  const repeatMap = dbToRouteRepeatMap(source.repeat);
  if (repeatMap) {
    target.repeat = repeatMap;
  }
  if (source.start_date) {
    target.startDate = source.start_date;
  }
  sequencedEvents.forEach((event) => {
    if (event.campus) {
      target.campuses.push(event.campus);
    }
  });
  return target;
};
