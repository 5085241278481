import { Injectable } from '@angular/core';
import { PopupableService } from '../../core/popupable/popupable.service';
import { WpError } from '@rootTypes';
import { ErrorParserService, ErrorStatements } from './error-parser.service';
import { isRejectedByUserError } from '../../api/prompt-api';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { ErrorPopupComponent } from '../../containers/error-popup/error-popup.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorPopupService {
  constructor(
    private popupService: PopupableService,
    private errorParser: ErrorParserService,
    private snackbar: SnackbarService,
  ) {}

  public openDefaultErrorPopup(
    error: WpError,
    hideErrorHeader?: boolean,
    closePrevious?: boolean,
    hideRejectedByUserToast?: boolean,
  ): void {
    const isRejectedByUser = isRejectedByUserError(error.originalError);
    if (isRejectedByUser) {
      if (hideRejectedByUserToast) {
        return;
      }
      return this.openRejectedByUserPopup();
    }
    const errorStatements = this.errorParser.getErrorStatements(error);
    this.popupService.openPopup<any, any>(
      ErrorPopupComponent,
      {
        errorStatements,
        hideErrorHeader,
        error,
        confirmBtnText: 'OK',
      },
      {
        contentZIndex: 11,
        pageMaskZIndex: 10,
        closePrevious,
        popupWidth: '500px',
      },
    );
  }

  private openRejectedByUserPopup(): void {
    this.snackbar.warn('Aborted by user');
  }

  /**
   * HTML utils
   */
  private getLegitimateErrorPopupHtml(
    { generalErrorMessage, specialErrorMessage, errorReason, correctiveAction, contextId }: ErrorStatements,
    hideErrorHeader?: boolean,
  ): string {
    const generalMessageHTML = generalErrorMessage ? this.encloseInParagraphDiv(generalErrorMessage) : '';
    const specialMessageHTML = specialErrorMessage ? this.encloseInParagraphDiv(specialErrorMessage) : '';
    const errorReasonHTML = errorReason ? this.encloseInParagraphDiv(errorReason) : '';
    const contextIdHTML = contextId ? this.encloseInParagraphDiv(`contextId: <i>${contextId}</i>`) : '';
    const correctiveActionHTML = correctiveAction ? this.encloseInParagraphDiv(correctiveAction) : '';
    const iconHtml = hideErrorHeader
      ? ''
      : `
      <div style="display: flex; justify-content: center; margin-top: 10px;">
        <img src="/assets/icons/common/nonsystem-error.svg" width="59" height="42" alt="error icon">
      </div>
    `;
    const headerHtml = hideErrorHeader
      ? ''
      : `<div style="font-size: 24px; font-weight: 600; margin: 18px 0 16px;">Hmm.. something’s gone awry</div>`;
    return `
      ${iconHtml}
      ${headerHtml}
      ${generalMessageHTML} 
      ${specialMessageHTML}
      ${errorReasonHTML}
      ${correctiveActionHTML}
      ${contextIdHTML}
    `;
  }

  private encloseInParagraphDiv(htmlOrString: string): string {
    return `<div style="margin-bottom: 21px; white-space: pre-wrap; white-space: -moz-pre-wrap; white-space: -pre-wrap; white-space: -o-pre-wrap; word-wrap: break-word;">${htmlOrString}</div>`;
  }
}
