<div class="add-edit-action-buttons-wrap">
  <div class="action-button">
    <wp-btn-secondary
      [width]="'160px'"
      [height]="'42px'"
      (clicked)="onCancelBtnClick()"
    >
      Cancel
    </wp-btn-secondary>
  </div>
  <div class="action-button">
    <wp-btn-primary
      [width]="'160px'"
      [height]="'42px'"
      [disabled]="isSaveBtnDisabled"
      [loading]="isSaveBtnLoading"
      (clicked)="$event.preventDefault(); onSaveBtnClick($event)"
    >
      {{saveButtonText}}
    </wp-btn-primary>
  </div>
</div>
