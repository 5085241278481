import { Employee } from '@rootTypes/entities/employees';
import { ApiSchoolEmployee } from '../../../get-school-employee';

export const getEmployeeFromApiSchoolEmployee = (source: ApiSchoolEmployee): Employee => {
  const copy = { ...source };
  delete copy.districtId;
  delete copy.schoolEmployeeImagePath;
  const result: Employee = {
    ...copy,
    parentEntityId: source.districtId,
    photo: source.schoolEmployeeImagePath,
  };
  return result;
};
