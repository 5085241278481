import { createReducer, on } from '@ngrx/store';

import { cleanUpStore, createEntityState, VehicleDataState } from '../../types';
import * as fromActions from '../actions';

const createInitialVehicleState = (): VehicleDataState => {
  return {
    vehicles: {},
    primaryDriversByVehicles: {},
    evStatusesByVehicles: {},
  };
};

export const vehicleDataReducer = createReducer<VehicleDataState>(
  createInitialVehicleState(),
  on(cleanUpStore, createInitialVehicleState),
  // load vehicle
  on(fromActions.loadVehicleRequested, (state, action) => {
    const { vehicleId } = action;
    const vehicleEntityState = state.vehicles[vehicleId] || createEntityState();
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        [vehicleId]: {
          ...vehicleEntityState,
          isLoading: true,
          error: undefined,
        },
      },
    };
  }),
  on(fromActions.loadVehicleSuccess, fromActions.updateVehicleSuccess, (state, action) => {
    const { vehicle, evStatus } = action;
    const vehicleId = vehicle.id;
    const primaryDriverId = vehicle.primaryDriver?.driverId;

    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        [vehicleId]: createEntityState(vehicle),
      },
      primaryDriversByVehicles: {
        ...state.primaryDriversByVehicles,
        [vehicleId]: {
          driverId: primaryDriverId,
        },
      },
      evStatusesByVehicles: {
        ...state.evStatusesByVehicles,
        [vehicleId]: evStatus,
      },
    };
  }),
  on(fromActions.loadVehicleFailed, (state, action) => {
    const { vehicleId, error } = action;
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        [vehicleId]: createEntityState(undefined, error),
      },
    };
  }),
);
