import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, filter, map, switchMap, take } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { openErrorPopup, RouterCommonFacade, State } from '@rootStore';
import * as fromEditorActions from '../actions/employee-profile-editor.actions';
import * as fromDataActions from '../actions/employee-data.actions';
import * as fromDistrictDataActions from '../../../districts/store/actions/district-data.actions';
import * as fromEditorSelectors from '../selectors/employee-profile-editor.selector';
import * as fromTypes from '../../types';
import { Observable } from 'rxjs/internal/Observable';
import { combineLatest, of } from 'rxjs';
import { SnackbarService } from '../../../../shared/snackbar/snackbar.service';
import { SchoolEmployeeApiService } from '../../services/school-employee-api.service';
import { updatedList } from '../actions';

@Injectable()
export class EmployeeProfileEditorEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private api: SchoolEmployeeApiService,
    private snackbar: SnackbarService,
    private managePermissionsRouter: fromTypes.SchoolEmployeeManagePermissionsRouterService,
    private commonRouter: RouterCommonFacade,
  ) {}

  public editorInitialized = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEditorActions.initEmployeeProfileEditor),
      filter((action) => !!action.createForDistrictId),
      map((action) => fromDistrictDataActions.loadDistrictRequested({ districtId: action.createForDistrictId })),
    ),
  );

  public createEmployeeRequested = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEditorActions.createEmployeeRequested),
      switchMap((action) => {
        const valid$ = this.store.select(fromEditorSelectors.getCurrentUpdatedModelValid);
        const employee$ = this.store.select(fromEditorSelectors.getCurrentUpdatedModel);
        const districtId$ = this.store.select(fromEditorSelectors.getCreateForDistrictId);
        return combineLatest([valid$, employee$, districtId$]).pipe(
          take(1),
          filter(([valid]) => valid),
          switchMap(([valid, employee, districtId]) => this.onCreateEmployee(employee, districtId)),
        );
      }),
    ),
  );

  public createEmployeeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromEditorActions.createEmployeeSuccess),
        map((action) => {
          this.snackbar.success('Successfully created employee');
          this.managePermissionsRouter.navigate({ employeeId: action.employee.id });
        }),
      ),
    { dispatch: false },
  );

  /**
   * Updated main employee list.
   * Wait 3 sec to allow for re-indexing (on BE)
   */
  public createEmployeeSuccessForReloadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEditorActions.createEmployeeSuccess),
      switchMap((action) => {
        return of(updatedList({ listName: fromTypes.EmployeeListName.MAIN_EMPLOYEE_LIST })).pipe(
          delay(fromTypes.configs.SEARCH_INDEX_REFRESH_DELAY),
        );
      }),
    ),
  );

  public createEmployeeFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEditorActions.createEmployeeFailed),
      map((action) => {
        return openErrorPopup({ error: action.error });
      }),
    ),
  );

  public updateEmployeeRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEditorActions.updateEmployeeRequested),
      switchMap((action) => {
        const valid$ = this.store.select(fromEditorSelectors.getCurrentUpdatedModelValid);
        const employee$ = this.store.select(fromEditorSelectors.getCurrentUpdatedModel);
        return combineLatest([valid$, employee$]).pipe(
          take(1),
          filter(([valid]) => valid),
          switchMap(([valid, employee]) => {
            return this.onUpdateEmployee(employee);
          }),
        );
      }),
    ),
  );

  public updateEmployeeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEditorActions.updateEmployeeSuccess),
      map((action) => {
        const { employee } = action;
        this.snackbar.success('Successfully updated employee');
        this.commonRouter.onGoBack();
        return fromDataActions.loadEmployeeSuccess({ employee, employeeId: employee.id });
      }),
    ),
  );

  public updateEmployeeFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEditorActions.updateEmployeeFailed),
      map((action) => {
        return openErrorPopup({ error: action.error });
      }),
    ),
  );

  private onCreateEmployee(employeeModel: fromTypes.EmployeeProfileEditModel, vendorId: string): Observable<Action> {
    const photoUploaded = employeeModel.photo.imageData && !employeeModel.photo.imagePath;
    const imgPath$ = photoUploaded ? this.api.uploadPhoto(employeeModel.photo.imageData) : of(undefined);
    return imgPath$.pipe(
      switchMap((imagePath) => {
        const request = fromTypes.utils.getEmployeeAddRequest(employeeModel, imagePath, vendorId);
        return this.api
          .createEmployee(request)
          .pipe(map((employee) => fromEditorActions.createEmployeeSuccess({ employee })));
      }),
      catchError((err) => {
        console.log(err);
        const wpError = {
          text: 'Failed to create employee',
          originalError: err,
        };
        return of(fromEditorActions.createEmployeeFailed({ error: wpError }));
      }),
    );
  }

  private onUpdateEmployee(targetModel: fromTypes.EmployeeProfileEditModel): Observable<Action> {
    const photoUploaded = targetModel.photo?.imageData && !targetModel.photo?.imagePath;
    const imgPath$ = photoUploaded
      ? this.api.uploadPhoto(targetModel.photo.imageData)
      : of(targetModel.photo?.imagePath);
    return imgPath$.pipe(
      switchMap((imagePath) => {
        const copyTargetWithImgPath = {
          ...targetModel,
          photo: { ...targetModel.photo, imagePath },
        } as fromTypes.EmployeeProfileEditModel;
        return this.store.select(fromEditorSelectors.getOpenEmployeeEditModel).pipe(
          take(1),
          switchMap((initialEditModel) => {
            const request = fromTypes.utils.getUpdateEmployeeRequest(initialEditModel, copyTargetWithImgPath);
            return this.api.updateEmployee(request).pipe(
              map((employee) => {
                return fromEditorActions.updateEmployeeSuccess({ employee });
              }),
            );
          }),
        );
      }),
      catchError((err) => {
        console.log(err);
        return of(
          fromEditorActions.updateEmployeeFailed({
            error: {
              text: 'Failed to update employee',
              originalError: err,
            },
          }),
        );
      }),
    );
  }
}
