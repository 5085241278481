/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../../api/api.service';
import {
  getRouteGroupActions,
  getRouteGroupFailed,
  getRouteGroupScheduleFailed,
  getRouteGroupScheduleRequestedFromRouteGroupDetails,
  getRouteGroupScheduleSuccess,
  getRouteGroupScheduleVersionsByWeekFailed,
  getRouteGroupScheduleVersionsByWeekFromRouteGroupDetails,
  getRouteGroupScheduleVersionsByWeekSuccess,
  getRouteGroupSuccess,
  routeGroupActivitySyncFlagToggled,
  setRouteGroupActivitySyncFlagFailed,
  setRouteGroupActivitySyncFlagRequested,
  setRouteGroupActivitySyncFlagSuccess,
} from '../actions/route-group.actions';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WpError } from '@rootTypes';
import { Store } from '@ngrx/store';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import { openErrorPopup } from '@rootStore';
import { GetRouteGroupRequest } from '../../../api/endpoints/get-route-group';

@Injectable()
export class RouteGroupDataEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store,
    private snackbar: SnackbarService,
  ) {}

  public getRouteGroupRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...getRouteGroupActions),
      mergeMap(({ routeGroupId, dateRange }) => {
        const request = { routeGroupId } as GetRouteGroupRequest;
        if (dateRange) {
          request.dateRange = {
            from: dateRange.startDate,
            to: dateRange.endDate,
          };
        }
        return this.api.getRouteGroup(request).pipe(
          map((response) => getRouteGroupSuccess({ routeGroup: response })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load route',
            };
            return of(getRouteGroupFailed({ routeGroupId, error }));
          }),
        );
      }),
    ),
  );

  public getRouteGroupScheduleVersionsByWeekRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRouteGroupScheduleVersionsByWeekFromRouteGroupDetails),
      mergeMap(({ routeGroupId, week }) => {
        return this.api.getRouteGroup({ routeGroupId, dateRange: { from: week.startDate, to: week.endDate } }).pipe(
          map((response) => {
            return getRouteGroupScheduleVersionsByWeekSuccess({ routeGroupId, week, routeGroup: response });
          }),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load schedule versions by week for a route',
            };
            return of(getRouteGroupScheduleVersionsByWeekFailed({ routeGroupId, week, error }));
          }),
        );
      }),
    ),
  );

  public getRouteGroupScheduleRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRouteGroupScheduleRequestedFromRouteGroupDetails),
      filter(({ routeGroupScheduleId }) => !!routeGroupScheduleId),
      mergeMap(({ routeGroupId, routeGroupScheduleId }) => {
        return this.api.getRouteGroup({ routeGroupId, routeGroupScheduleId }).pipe(
          map((response) => getRouteGroupScheduleSuccess({ routeGroupSchedule: response.routeGroupSchedule })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load schedule',
            };
            return of(getRouteGroupScheduleFailed({ routeGroupScheduleId, error }));
          }),
        );
      }),
    ),
  );

  public routeGroupActivitySyncFlagToggled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routeGroupActivitySyncFlagToggled),
      map(({ routeGroupId, enabled }) => setRouteGroupActivitySyncFlagRequested({ routeGroupId, enabled })),
    ),
  );

  public setRouteGroupActivitySyncFlagRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setRouteGroupActivitySyncFlagRequested),
      mergeMap(({ routeGroupId, enabled }) => {
        return this.api.setRouteGroupActivitySyncFlag({ routeGroupId, isActivitySyncEnabled: enabled }).pipe(
          map(() => setRouteGroupActivitySyncFlagSuccess({ routeGroupId, enabled })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to set activity sync',
            };
            return of(setRouteGroupActivitySyncFlagFailed({ routeGroupId, error }));
          }),
        );
      }),
    ),
  );

  public setRouteGroupActivitySyncFlagSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setRouteGroupActivitySyncFlagSuccess),
        tap(() => {
          this.snackbar.success('Successfully updated settings');
        }),
      ),
    { dispatch: false },
  );

  public setRouteGroupActivitySyncFlagFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setRouteGroupActivitySyncFlagFailed),
      map(({ error }) => {
        return openErrorPopup({ error, closePrevious: true });
      }),
    ),
  );
}
