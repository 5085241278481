import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[wpDoubleRangeSelectColorTick]',
})
export class DoubleRangeSelectColorTickDirective implements AfterViewInit, OnChanges {
  @Input() public colorLow: string;
  @Input() public colorHigh: string;
  @Input() public colorNonSelected: string;
  @Input() public doubleColorFloor: number;
  @Input() public doubleColorCeil: number;
  @Input() public doubleColorStep: number;
  @Input() public doubleColorLowValue: number;
  @Input() public doubleColorHighValue: number;

  private tickElements: NodeListOf<HTMLSpanElement>;

  constructor(private element: ElementRef<HTMLDivElement>) {}

  ngAfterViewInit(): void {
    this.tickElements = this.element.nativeElement.querySelectorAll('.ngx-slider-tick');
    this.updateColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.doubleColorLowValue || changes.doubleColorHighValue) {
      this.updateColor();
    }
  }

  private updateColor(): void {
    if (!this.tickElements) {
      return;
    }
    const halfTickIndex = (this.doubleColorCeil - this.doubleColorFloor) / this.doubleColorStep / 2;
    this.tickElements.forEach((tick, index) => {
      if (index < halfTickIndex) {
        if (tick.classList.contains('ngx-slider-selected')) {
          tick.style.backgroundColor = this.colorLow;
        } else {
          tick.style.backgroundColor = this.colorNonSelected;
        }
      } else {
        if (tick.classList.contains('ngx-slider-selected')) {
          tick.style.backgroundColor = this.colorHigh;
        } else {
          tick.style.backgroundColor = this.colorNonSelected;
        }
      }
    });
  }
}
