<div
  [matMenuTriggerFor]="dropdownTemplate"
  [ngClass]="{'trigger-disabled': isDisabled}"
  (menuOpened)="onMenuOpenChanged(true)"
  (menuClosed)="onMenuOpenChanged(false)"
>
  <ng-container *ngIf="isSimpleInput; else filterTemplate">
    <ng-container *ngIf="isInputField; else plainTemplate">
      <wp-input [control]="control" [label]="inputLabel" [readOnly]="true">
        <wp-input-suffix>
          <wp-icon-calendar></wp-icon-calendar>
        </wp-input-suffix>
      </wp-input>
    </ng-container>
    <ng-template #plainTemplate>
      <wp-dropdown-trigger>
        <ng-container *ngIf="selectedDates?.length; else noValueTemplate;">
          <span class="bold"> {{selectedDates[0] | displayYYYYMMDD }} </span
          >&nbsp;-&nbsp;<span class="bold">
            {{selectedDates[selectedDates.length - 1] | displayYYYYMMDD }}
          </span>
        </ng-container>
        <ng-template #noValueTemplate> Select dates </ng-template>
      </wp-dropdown-trigger>
    </ng-template>
  </ng-container>
  <ng-template #filterTemplate>
    <wp-square-border
      [highlighted]="isOpened"
      [width]="widthStr"
      [disabled]="isDisabled"
    >
      <wp-dropdown-trigger>
        <ng-container *ngIf="selectedDates?.length; else noValueTemplate;">
          <span class="bold"> {{selectedDates[0] | displayYYYYMMDD }} </span
          >&nbsp;-&nbsp;<span class="bold">
            {{selectedDates[selectedDates.length - 1] | displayYYYYMMDD }}
          </span>
        </ng-container>
        <ng-template #noValueTemplate> Select dates </ng-template>
      </wp-dropdown-trigger>
    </wp-square-border>
  </ng-template>
</div>

<mat-menu #dropdownTemplate>
  <div class="week-select-wrap">
    <wp-multi-date-picker
      [selected]="selectedDates"
      [weekSelectCount]="weekSelectCount"
      [isSingleSelect]="true"
      [isDeselectOnSelectedClick]="false"
      [isStrictDisableWeek]="true"
      [isStartWeekFromMonday]="isStartWeekFromMonday"
      [disableDatesBefore]="disableDatesBefore"
      [disableDatesAfter]="disableDatesAfter"
      (selectedChanged)="selectedDates = $event; selectedChanged.emit($event); updateControlValue()"
    ></wp-multi-date-picker>
  </div>
</mat-menu>
