import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';

export interface ManageEmployeePermissionRouteData {
  employeeId: string;
  displayBackText?: string;
}

export const manageEmployeePermissionRoute: PortalRoute<ManageEmployeePermissionRouteData> = {
  path: '',
  isOnRoute: (state: RouterStateUrl): boolean => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): ManageEmployeePermissionRouteData => {
    let data: ManageEmployeePermissionRouteData = {
      employeeId: state.drawer.params.employeeId,
    };
    if (state.queryParams) {
      data = {
        ...data,
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (
    data: ManageEmployeePermissionRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?,
  ): NavigationRequest => {
    const path = ['_', 'drawer', 'school-employees', ':employeeId', 'edit-permissions'];
    const routeData = { ...data };
    return getRoutingRequest(path, routeData, queryParamStrategy, replaceUrl);
  },
};

@Injectable({ providedIn: 'root' })
export class SchoolEmployeeManagePermissionsRouterService extends PortalRouterService<ManageEmployeePermissionRouteData> {
  outlet = RouterOutlets.DRAWER;
  route: PortalRoute<ManageEmployeePermissionRouteData> = manageEmployeePermissionRoute;
}
