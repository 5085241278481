import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PopupComponent } from './popup/popup.component';
import { DrawerComponent } from './drawer/widget-menu.component';
import { AbstractPopupComponent } from './types/abstract-popup-component';
import { ConfirmPopupConfig, PopupableOptions } from './types';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { DrawerRef } from './types/drawer-ref';
import { DrawerConfig } from './types/drawer-config';

@Injectable({
  providedIn: 'root',
})
export class PopupableService {
  private resultDataSubj: Subject<any> = new Subject<any>();

  constructor() {}

  public openPopup<F = any, D = any, R = any>(
    component: new (...args: any[]) => AbstractPopupComponent<D, R>,
    data: D,
    options?: PopupableOptions,
  ): Observable<R> {
    if (options && options.closePrevious) {
      PopupComponent.close();
    }
    return PopupComponent.open<any, D, R>(component, data, options);
  }

  public openDrawer<C extends AbstractPopupComponent<D, R>, D, R>(config: DrawerConfig): DrawerRef {
    return DrawerComponent.open(config);
  }

  public openConfirmPopup(confirmPopupConfig: ConfirmPopupConfig, options?: PopupableOptions): Observable<boolean> {
    if (options && options.closePrevious) {
      PopupComponent.close();
    }
    return PopupComponent.open<any, ConfirmPopupConfig, boolean>(ConfirmPopupComponent, confirmPopupConfig, options);
  }

  public closeAll(): void {
    PopupComponent.close();
    DrawerComponent.close();
  }
}
