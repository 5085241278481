<div
  #ellipsisWrap
  class="wp-ellipsis-wrap"
  [ngStyle]="{
    textAlign: textAlign
  }"
  [ngClass]="{
    link: isLink
  }"
>
  <div
    *ngIf="isTooltip"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPosition"
    class="wp-ellipsis-wrap__tooltip-mask"
  ></div>
  <ng-content></ng-content>
</div>
