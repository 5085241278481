import { createSelector } from '@ngrx/store';

import { getDriversFeature } from './get-feature';
import {
  Driver,
  driverGenderToDisplay,
  DriverStatus,
  driverStatusToDisplay,
  DriverType,
  EntityState,
  yyyymmddToDate,
  ServiceAreaOption,
} from '../../types';
import {
  DriverDataState,
  DriverRatingState,
  VehicleByDriverState,
  YardByDriverState,
} from '../reducers/driver-data.reducer';

export const getDriverDataState = createSelector(getDriversFeature, (state): DriverDataState => state.data);

export const getAllDrivers = createSelector(getDriverDataState, (state) => state.drivers);

export const getDriverEntityState = createSelector(
  getAllDrivers,
  (state, props: { driverId: string }): EntityState<Driver> | undefined => state[props.driverId],
);

export const getDriver = createSelector(getDriverEntityState, (state): Driver => state?.entity);

export const getDriverById = (driverId: string) =>
  createSelector(getAllDrivers, (allDrivers) => {
    return allDrivers[driverId]?.entity;
  });

export const getDriverLoading = createSelector(getDriverEntityState, (state) => state?.isLoading || false);

export const getDriverEntityStateLoading = createSelector(getDriverEntityState, (state) => {
  if (state?.entity || state?.error) {
    return false;
  }
  return true;
});

export const getDriverError = createSelector(getDriverEntityState, (state) => state?.error);

export const getDriverErrorMessage = createSelector(getDriverError, (state) => state?.text);

// About a driver
export const driverFirstName = createSelector(getDriver, (driver) => {
  return driver?.firstName || '';
});
export const driverLastName = createSelector(getDriver, (driver) => {
  return driver?.lastName || '';
});
export const driverFullName = createSelector(getDriver, (driver) => {
  if (driver) {
    return `${driver.firstName} ${driver.lastName}`;
  }
  return '';
});

export const driverPhotoPath = createSelector(getDriver, (driver) => {
  if (driver) {
    return driver.driverImagePath;
  }
  return '';
});

export const driverEmail = createSelector(getDriver, (driver) => {
  if (driver) {
    return driver.email;
  }
  return '';
});

export const driverPhoneNumber = createSelector(getDriver, (driver) => {
  if (driver) {
    return driver.phoneNumber;
  }
  return '';
});

export const driverStatus = createSelector(getDriver, (driver) => driver?.status);
export const isDriverDeleted = createSelector(driverStatus, (status) => status === DriverStatus.DELETED);

export const driverStatusDisplay = createSelector(getDriver, (driver) => {
  if (driver) {
    return driverStatusToDisplay(driver.status);
  }
  return '';
});

export const driverType = createSelector(getDriver, (driver) => driver?.type);

export const isVendorDriver = createSelector(getDriver, (driver) => {
  if (driver) {
    return driver.type === DriverType.VENDOR;
  }
  return undefined;
});

export const driverGender = createSelector(getDriver, (driver) => {
  if (driver) {
    return driverGenderToDisplay(driver.gender);
  }
  return undefined;
});

export const driverLicenseType = createSelector(getDriver, (driver) => {
  if (driver) {
    return driver.license?.type;
  }
  return undefined;
});

export const driverLicenseExpDate = createSelector(getDriver, (driver) => {
  if (driver?.license?.expirationDate) {
    return yyyymmddToDate(driver.license.expirationDate);
  }
  return undefined;
});

export const driverExternalEmployeeId = createSelector(getDriver, (driver) => {
  if (driver) {
    return driver.externalEmployeeId;
  }
  return undefined;
});

export const getDriverSeniority = (driverId: string) =>
  createSelector(getDriverById(driverId), (driver) => driver?.seniority);
// EOF: About a driver

// Driver rating
export const getAllRatingsByDriverState = createSelector(getDriverDataState, (state) => state.driverRating);
export const getDriverRatingState = createSelector(
  getAllRatingsByDriverState,
  (state, props: { driverId: string }): DriverRatingState | undefined => state[props.driverId],
);
export const getDriverRating = createSelector(getDriverRatingState, (state) => state?.rating || undefined);
export const getDriverRatingDisplay = createSelector(getDriverRating, (rating) => {
  if (typeof rating === 'number') {
    return rating.toFixed(1);
  }
  return '';
});

// Vehicles by driver
export const getAllVehiclesByDriverState = createSelector(getDriverDataState, (state) => state.vehiclesByDriver);

const getVehicleByDriverState = createSelector(
  getAllVehiclesByDriverState,
  (state, props: { driverId: string }): VehicleByDriverState | undefined => state[props.driverId],
);

export const getDriverVehicleId = createSelector(getVehicleByDriverState, (state) => state?.vehicleId || undefined);

export const isDriverVehicle = createSelector(getVehicleByDriverState, (state) => !!state && !!state.vehicleId);

// Vendors by driver
export const getDriverVendorId = createSelector(getDriver, (driver) => driver?.vendorId);

// Yards by driver
const getAllYardsByDriverState = createSelector(getDriverDataState, (state) => state.yardsByDriver);
const getYardByDriverState = createSelector(
  getAllYardsByDriverState,
  (state, props: { driverId: string }): YardByDriverState | undefined => state[props.driverId],
);
export const getDriverYardId = createSelector(getYardByDriverState, (state) => state?.yardId);

const serviceAreasState = createSelector(getDriverDataState, (state) => state.serviceAreas);
const getServiceAreas = createSelector(serviceAreasState, (state) => state.entity);
export const isServiceAreasLoading = createSelector(serviceAreasState, (state) => state.isLoading);
export const getServiceAreasErrorMessage = createSelector(serviceAreasState, (state) => state.error?.text);
export const getServiceAreaOptions = createSelector(getServiceAreas, (areas): ServiceAreaOption[] | undefined => {
  if (!areas) {
    return undefined;
  }
  return areas.map((area) => {
    return {
      area,
      searchIndex: `${area.city.toLowerCase()} ${area.state.toLowerCase()}`,
      displayLabel: `${area.city}, ${area.state}`,
    };
  });
});

/**
 * Login security settings
 */
export const getAllSecuritySettings = createSelector(getDriverDataState, (state) => state.loginSettings);

const getSingleDriverSecuritySettingState = createSelector(
  getAllSecuritySettings,
  (allSettings, props: { driverId: string }) => {
    return allSettings[props.driverId];
  },
);

export const getDriverSecuritySettings = createSelector(getSingleDriverSecuritySettingState, (state) => state?.entity);

export const getDriverSecuritySettingsLoading = createSelector(
  getSingleDriverSecuritySettingState,
  (state) => state?.isLoading || false,
);

export const getDriverSecuritySettingsError = createSelector(
  getSingleDriverSecuritySettingState,
  (state) => state?.error,
);

const getAllStandbyStatusByDriverState = createSelector(getDriverDataState, (state) => state.standbyStatus);
export const getStandbyStatusByDriver = (driverId: string) =>
  createSelector(getAllStandbyStatusByDriverState, (state) => state[driverId]);
