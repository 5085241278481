import { Injectable } from '@angular/core';
import { RouterOutlets } from '@rootTypes/entities/router';
import { YardDetailsTab } from '@rootTypes/entities/yard';
import { PortalRouterService } from '../../types/portal-router-service';
import { createPortalRoute } from '../../utils/createPortalRoute';

export type YardDetailsRouteData = {
  yardId: string;
  tab?: YardDetailsTab;

  // Query Params
  displayBackText?: string;
};

export const yardDetailsRoute = createPortalRoute<YardDetailsRouteData>({
  path: 'yards/:yardId/details/:tab',

  outlet: RouterOutlets.DRAWER,

  extractParams: (data) => ({
    yardId: data.yardId,
    tab: data.tab ?? YardDetailsTab.DEFAULT,
  }),

  extractQueryParams: (data) => ({
    displayBackText: data.displayBackText,
  }),
});

@Injectable({ providedIn: 'root' })
export class YardDetailsRouterService extends PortalRouterService<YardDetailsRouteData> {
  public readonly route = yardDetailsRoute;
}
