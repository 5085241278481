import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmartAddressWithExactLocation } from '../../../../shared/smart-forms/models/smart-address-with-exact-location';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { SelectOption } from '../../../../shared/form-controls';
import { BusAddress } from '@apiEntities/district/bus-address';
import { Address, MapLocation, MapMarker, WpError } from '@rootTypes';
import { delay, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';
import { iconPaths } from '@rootTypes/utils';

@Component({
  selector: 'wp-closest-bus-address-lookup-editor',
  template: `
    <ng-container *ngIf="model.isBusAddressesLoading$ | async; else loadedBusStopOptionsTemplate">
      <wp-section-spinner [sectionHeight]="'100px'" [spinnerSize]="37"></wp-section-spinner>
    </ng-container>
    <ng-template #loadedBusStopOptionsTemplate>
      <div class="closest-bus-address-lookup-editor">
        <div class="editor-left" [ngClass]="{ compact: isCompactView }">
          <div class="assignment-input-wrap">
            <wp-autocomplete-simple
              [label]="'Stop assignment'"
              [control]="model.busAddressSelect"
              [options]="model.busAddressOptions$ | async"
              (valueChanged)="onBusAssignmentInputChanged($event)"
            ></wp-autocomplete-simple>
            <ng-container *ngIf="model.busAddressLoadError$ | async; let error">
              <div style="margin: 12px 0">
                <wp-section-error [sectionHeightStr]="'60px'">{{ error.text }}</wp-section-error>
              </div>
            </ng-container>
          </div>
          <div class="search-stop-wrap">
            <div class="search-stop-title">
              <wp-title-four>Search for stops near an address</wp-title-four>
            </div>
            <div class="search-address-input">
              <wp-smart-input-address
                [model]="model.searchBusStopsNearAddress"
                (valueChangedByUser)="onSearchForAddressInputChanged($event)"
              ></wp-smart-input-address>
            </div>
            <div class="search-address-options-wrap">
              <div class="search-address-options-title">
                <wp-title-five>Nearby stops</wp-title-five>
              </div>
              <div class="search-address-options" [ngClass]="{ compact: isCompactView }">
                <wp-loaded-content
                  [loading]="isClosestBusStopOptionsLoading$ | async"
                  [error]="loadClosestBusStopError$ | async"
                  [contentTemplate]="contentTemplate"
                  [loadingTemplate]="loadingTemplate"
                  [errorTemplate]="errorTemplate"
                ></wp-loaded-content>
                <ng-template #contentTemplate>
                  <ng-container
                    *ngIf="selectClosestBusAddressFromListControl && (closestBusStopOptions$ | async); let options"
                  >
                    <ng-container *ngIf="options?.length; else emptyOptionsTempl">
                      <mat-radio-group
                        class="type-block"
                        [formControl]="selectClosestBusAddressFromListControl"
                        [color]="'primary'"
                        (change)="onClosestStopRadioChange($event)"
                      >
                        <ng-container *ngFor="let option of options">
                          <mat-radio-button class="stop-option" [value]="option">
                            <div class="option-content">
                              <div class="option-content-left">
                                {{ option.displayLabel }}
                              </div>
                              <div class="option-content-right">
                                <ng-container *ngIf="option?.meta?.walkDistanceMiles !== undefined">
                                  <div class="walking-icon-wrap">
                                    <wp-portal-icon [path]="iconWalking"></wp-portal-icon>
                                  </div>
                                  <wp-grey-label>{{ option.meta.walkDistanceMiles.toFixed(1) }} mi</wp-grey-label>
                                </ng-container>
                              </div>
                            </div>
                          </mat-radio-button>
                          <div class="option-divider"></div>
                        </ng-container>
                      </mat-radio-group>
                    </ng-container>
                    <ng-template #emptyOptionsTempl>
                      <div style="margin-bottom: 12px">
                        <wp-grey-label> There are no nearby stops. </wp-grey-label>
                      </div>

                      <wp-grey-label> Search using a different address or use the map to find a stop. </wp-grey-label>
                    </ng-template>
                  </ng-container>
                </ng-template>
                <ng-template #loadingTemplate>
                  <wp-section-spinner [sectionHeight]="'80px'" [spinnerSize]="37"></wp-section-spinner>
                </ng-template>
                <ng-template #errorTemplate>
                  <wp-section-error [error]="loadClosestBusStopError$ | async"></wp-section-error>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="editor-right">
          <div class="map-wrap">
            <wp-google-map
              [isAutoFocusOnLocations]="false"
              [markers]="googleMarkers$ | async"
              [isDefaultMapControls]="true"
              (userInteracted)="onMapClicked()"
              (mapInitialized)="onMapTilesLoaded($event)"
            ></wp-google-map>
            <div *ngIf="isMapInstructionOverlay" class="map-instruction-overlay">
              Click on an existing stop to use it.
            </div>
          </div>

          <div class="legend-wrap">
            <div class="legend-item"><wp-title-five>Legend:</wp-title-five></div>
            <div class="legend-item">
              <div class="legend-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_214_6858)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.9995 13.0001C9.79014 13.0001 7.99951 11.2082 7.99951 9.00006C7.99951 6.79069 9.79014 5.00006 11.9995 5.00006C14.2077 5.00006 15.9995 6.79069 15.9995 9.00006C15.9995 11.2082 14.2077 13.0001 11.9995 13.0001ZM11.9995 1.00006C7.58071 1.00006 3.99951 4.51719 3.99951 8.85693C3.99951 16.7138 11.9995 23.0001 11.9995 23.0001C11.9995 23.0001 19.9995 16.7138 19.9995 8.85693C19.9995 4.51719 16.4183 1.00006 11.9995 1.00006Z"
                      fill="#47A540"
                      stroke="#47A540"
                      stroke-width="2.4"
                    />
                    <circle cx="12" cy="9" r="3" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_214_6858">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="legend-text">Address</div>
            </div>
            <div class="legend-item">
              <div class="legend-icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="12" height="12" fill="#EEC8F0" stroke="#DD40D7" stroke-width="2" />
                </svg>
              </div>
              <div class="legend-text">Bus stop</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styles: `
    .closest-bus-address-lookup-editor {
      display: flex;
      flex-wrap: wrap;
    }

    .editor-left {
      margin-right: 28px;
      max-width: 290px;
    }

    .editor-left.compact {
      max-width: 100%;
      width: 100%;
      margin-bottom: 10px;
      margin-right: 0;
    }

    .editor-right {
      flex: 1;
      position: relative;
      min-height: 396px;
    }

    .assignment-input-wrap {
      margin-bottom: 20px;
    }

    .search-address-input {
      margin-bottom: 20px;
    }

    .search-address-options {
      width: 285px;
    }

    .search-address-options.compact {
      width: 100%;
    }

    .search-address-options-title {
      margin-bottom: 12px;
    }

    .stop-option ::ng-deep .mdc-form-field {
      width: 100%;
    }
    .stop-option ::ng-deep .mdc-label {
      flex: 1;
    }

    .option-divider {
      border-bottom: 1px solid #dfdfe3;
      margin: 5px 0;
    }

    .option-content {
      display: flex;
      justify-content: space-between;
    }

    .option-content-left {
      flex: 1;
      margin-right: 12px;
    }

    .option-content-right {
      position: relative;
    }

    .walking-icon-wrap {
      position: absolute;
      left: -15px;
      top: 1px;
    }

    .map-wrap {
      position: relative;
      height: 395px;
    }

    .map-instruction-overlay {
      position: absolute;
      bottom: 20px;
      padding: 8px;
      color: white;
      background-color: rgba(25, 25, 25, 0.75);
      border-radius: 4px;
      left: 50%;
      transform: translateX(-50%);
    }

    .legend-wrap {
      display: flex;
      align-items: center;
      height: 32px;
      margin-top: 9px;
    }

    .legend-item:not(:last-child) {
      margin-right: 20px;
    }

    .legend-item {
      display: flex;
      align-items: center;
    }

    .legend-icon {
      height: 32px;
      display: flex;
      align-items: center;
      margin-right: 8px;
    }

    .legend-text {
      height: 32px;
      display: flex;
      align-items: center;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClosestBusAddressLookupEditorComponent implements OnInit, OnDestroy {
  @Input() public model: SmartAddressWithExactLocation;
  @Input() public defaultMapCenter: MapLocation;
  @Input() public isCompactView: boolean;

  public selectClosestBusAddressFromListControl: FormControl<SelectOption<string, BusAddress>>;
  public closestBusStopOptions$: Observable<SelectOption<string, BusAddress>[]>;
  public isClosestBusStopOptionsLoading$: Observable<boolean>;
  public loadClosestBusStopError$: Observable<WpError>;
  public googleMarkers$: Observable<MapMarker[]>;
  public centerOnLocation$ = new BehaviorSubject<MapLocation>(null);
  public focusOnLocations$: Observable<MapLocation[]>;
  public iconWalking = iconPaths.DIRECTIONS_WALKING_GREY;
  public isMapInstructionOverlay = true;

  public subs = new Subscription();

  ngOnInit(): void {
    this.isMapInstructionOverlay = !this.model.config.disabled;
    this.selectClosestBusAddressFromListControl = new FormControl({
      value: null,
      disabled: this.model.config.disabled,
    });
    const assignedBusStopLocation = this.model.getValue()?.address?.geometry?.location;
    const searchAddressLocation = this.model.searchBusStopsNearAddress.getValue()?.geometry?.location;
    if (searchAddressLocation) {
      this.model.searchClosestBusStopOptionsForLocation(searchAddressLocation);
    }
    this.centerOnLocation$.next(searchAddressLocation || this.defaultMapCenter);
    this.closestBusStopOptions$ = this.model.closestBusStopOptions$;
    this.isClosestBusStopOptionsLoading$ = this.model.isClosestBusStopOptionsLoading$;
    this.loadClosestBusStopError$ = this.model.closestBusStopOptionsLoadError$;
    this.focusOnLocations$ = combineLatest([this.model.closestBusStopOptions$]).pipe(
      map(([closestBusStopOptions]) => {
        let result: MapLocation[] = [];
        if (closestBusStopOptions?.length) {
          result = closestBusStopOptions.map((option) => (option.meta as BusAddress).address.geometry.location);
        }
        if (assignedBusStopLocation) {
          // in case the assigned location is not one of the closest locations - make sure it's visible
          result.push(assignedBusStopLocation);
        }
        return result;
      }),
    );
    this.googleMarkers$ = combineLatest([
      this.model.searchBusStopsNearAddress
        .getValueChanges()
        .pipe(startWith(this.model.searchBusStopsNearAddress.getValue())),
      this.model.formGroup.valueChanges.pipe(
        startWith(this.model.formGroup.getRawValue()),
        map((value) => value?.busAddress?.value),
      ),
      this.model.busAddressOptions$,
      this.model.closestBusStopOptions$,
    ]).pipe(
      map(([searchAddress, selectedBusAddressId, busStopOptions, closestOptions]) => {
        const result: MapMarker[] = [];
        if (searchAddress?.geometry?.location) {
          const searchMarker: MapMarker = {
            location: searchAddress.geometry.location,
            html: `
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_214_6858)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9995 13.0001C9.79014 13.0001 7.99951 11.2082 7.99951 9.00006C7.99951 6.79069 9.79014 5.00006 11.9995 5.00006C14.2077 5.00006 15.9995 6.79069 15.9995 9.00006C15.9995 11.2082 14.2077 13.0001 11.9995 13.0001ZM11.9995 1.00006C7.58071 1.00006 3.99951 4.51719 3.99951 8.85693C3.99951 16.7138 11.9995 23.0001 11.9995 23.0001C11.9995 23.0001 19.9995 16.7138 19.9995 8.85693C19.9995 4.51719 16.4183 1.00006 11.9995 1.00006Z" fill="#47A540" stroke="#47A540" stroke-width="2.4"/>
                <circle cx="12" cy="9" r="3" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_214_6858">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
               </svg>
            `,
            width: 24,
            height: 24,
          };
          result.push(searchMarker);
        }
        if (busStopOptions?.length) {
          const busOptionMarkers = busStopOptions.map((option) => {
            const selected = (option.meta as BusAddress).busAddressId === selectedBusAddressId;
            const zIndex = selected ? 10 : 5;
            return {
              location: (option.meta as BusAddress).address.geometry.location,
              html: selected
                ? `<svg style="z-index: 10" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.16667" y="1.16667" width="11.6667" height="11.6667" fill="#DD40D7" stroke="#DD40D7" stroke-width="2.33333"/>
                   </svg>
                  `
                : `
                <svg style="z-index: 5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="12" height="12" fill="#EEC8F0" stroke="#DD40D7" stroke-width="2"/>
                </svg>
              `,
              width: 24,
              height: 24,
              clickCallbackFn: () => {
                if (this.model.config.disabled) {
                  return;
                }
                this.model.busAddressSelect.setValue(option);
                this.onMapClicked();
              },
              zIndex,
            };
          });
          result.push(...busOptionMarkers);
        }
        return result;
      }),
    );
    // change radio value
    const selectedBusStopId$ = this.model.busAddressSelect.valueChanges.pipe(
      startWith(this.model.busAddressSelect.value),
      map((o) => o?.value),
      distinctUntilChanged(),
    );
    const setRadioValueSub = combineLatest([selectedBusStopId$, this.closestBusStopOptions$]).subscribe(
      ([selectedBusStopId, closestOptions]) => {
        if (!selectedBusStopId) {
          this.selectClosestBusAddressFromListControl.setValue(null);
        }
        if (!closestOptions?.length) {
          this.selectClosestBusAddressFromListControl.setValue(null);
        }
        const selectedOption = closestOptions.find((o) => o.value === selectedBusStopId);
        if (selectedOption) {
          this.selectClosestBusAddressFromListControl.setValue(selectedOption);
        } else {
          this.selectClosestBusAddressFromListControl.setValue(null);
        }
      },
    );
    this.subs.add(setRadioValueSub);
  }

  onMapTilesLoaded(map: google.maps.Map): void {
    const mapFocusSub = combineLatest([this.centerOnLocation$, this.focusOnLocations$])
      .pipe(delay(200))
      .subscribe(([centerLocation, focusLocations]) => {
        if (focusLocations?.length) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(centerLocation);
          focusLocations.forEach((l) => bounds.extend(l));
          map.fitBounds(bounds);
        } else {
          map.fitBounds(
            new google.maps.Circle({
              center: centerLocation,
              radius: 2400, // ~1.5 mile, TODO: get center and radius via API
            }).getBounds(),
          );
        }
      });
    this.subs.add(mapFocusSub);
  }

  onBusAssignmentInputChanged(event: SelectOption): void {
    this.model.busAddressSelect.setValue(event);
  }

  onSearchForAddressInputChanged(event: Address | null): void {
    if (event) {
      this.model.searchClosestBusStopOptionsForLocation(event.geometry.location);
      this.centerOnLocation$.next(event.geometry.location);
    }
  }

  onClosestStopRadioChange(event: MatRadioChange): void {
    const option = event.value as SelectOption;
    this.model.busAddressSelect.setValue(option);
  }

  onMapClicked(): void {
    this.isMapInstructionOverlay = false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
