import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DateRangePickerService } from '../date-range-picker.service';
import { Observable, Subscription } from 'rxjs';
import * as fromTypes from '../types';
import { UntypedFormControl } from '@angular/forms';
import { filter, distinctUntilChanged, skip, startWith } from 'rxjs/operators';
import { DateRange, YYYYMMDDString } from '@rootTypes';

interface FormControlValue {
  startDate: string | null;
  endDate: string | null;
}

@Component({
  selector: 'wp-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [DateRangePickerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent implements OnDestroy, OnInit, OnChanges {
  /**
   * { startDate: YYYYMMDD, endDate: YYYYMMDD }
   */
  @Input() public control!: UntypedFormControl;
  @Input() public selected: DateRange;
  @Input() public monthView!: number;
  @Input() public readonly = false;
  @Input() public isStartWeekFromMonday: boolean;
  @Input() public disableDatesBefore: YYYYMMDDString = null;
  @Input() public disableDatesAfter: YYYYMMDDString = null;

  @Output() public selectedChanged = new EventEmitter<DateRange>();

  public months$!: Observable<fromTypes.RangePickerMonth[]>;

  private subscriptions: Subscription;

  constructor(
    private store: DateRangePickerService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnInit(): void {
    setTimeout(() => this.init());
  }

  public onForwardClick(): void {
    this.store.forward();
  }

  public onBackwardClick(): void {
    this.store.backward();
  }

  private init(): void {
    this.store.setStartWeekFromMonday(this.isStartWeekFromMonday);
    if (this.monthView) {
      this.store.setMonthView(this.monthView);
    }
    if (this.readonly) {
      this.store.setReadonly();
    }

    this.months$ = this.store.months$();
    this.subscriptions = new Subscription();
    const userSelectSub = this.store.dateRangeSelectedByUser().subscribe((value) => this.selectedChanged.emit(value));
    this.subscriptions.add(userSelectSub);
    if (this.control) {
      if (this.control.value && this.control.value.startDate) {
        this.store.setCurrentMonth(this.control.value.startDate);
      }
      const sub1 = this.control.valueChanges
        .pipe(
          startWith(this.control.value),
          filter((val) => !!val),
          distinctUntilChanged((prev, curr) => {
            return prev.startDate === curr.startDate && prev.endDate === curr.endDate;
          }),
        )
        .subscribe((val: FormControlValue) => {
          const { startDate, endDate } = val;
          if (startDate) {
            this.store.setStartDate(startDate);
          } else {
            this.store.setStartDate(null);
          }
          if (endDate) {
            this.store.setEndDate(endDate);
          } else {
            this.store.setEndDate(null);
          }
        });
      const sub2 = this.store
        .selectedChanged$()
        .pipe(
          distinctUntilChanged((prev, curr) => {
            return prev.startDate === curr.startDate && prev.endDate === curr.endDate;
          }),
          skip(1),
        )
        .subscribe((val) => {
          const { startDate, endDate } = val;
          const resultFormValue: FormControlValue = {
            startDate: null,
            endDate: null,
          };
          if (startDate) {
            resultFormValue.startDate = startDate;
          }
          if (endDate) {
            resultFormValue.endDate = endDate;
          }
          this.control.setValue(resultFormValue);
        });
      this.subscriptions.add(sub1);
      this.subscriptions.add(sub2);
    }
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      const selectedDateRange: DateRange = changes.selected.currentValue;
      if (selectedDateRange && selectedDateRange.startDate && selectedDateRange.endDate) {
        this.store.setCurrentMonth(selectedDateRange.startDate);
        this.store.setStartDate(selectedDateRange.startDate);
        this.store.setEndDate(selectedDateRange.endDate);
      } else {
        this.store.setStartDate(undefined);
        this.store.setEndDate(undefined);
      }
    }
    if (changes.disableDatesBefore) {
      this.store.setDisableDatesBefore(this.disableDatesBefore ? this.disableDatesBefore : null);
    }
    if (changes.disableDatesAfter) {
      this.store.setDisableDatesAfter(this.disableDatesAfter ? this.disableDatesAfter : null);
    }
  }
}
