/**
 * This directive is to apply conditional styling if elements wrap to next row of their container.
 * Usage:
 * 1. Use this directive on parent element (container) e.g. [wpApplyClassOnWrap]="'wrapped'".
 * 2. Each child of the container will be assigned the class you specify, if it wraps to the second
 *    (and subsequent) rows of its container
 */

import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[wpApplyClassOnWrap]',
})
export class ApplyClassOnWrapDirective implements AfterViewInit, OnDestroy {
  @Input() public wpApplyClassOnWrap: string;
  private sub = new Subscription();
  private observer: MutationObserver;
  private resizeObserver: ResizeObserver;
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (!this.wpApplyClassOnWrap) {
      throw new Error('wpApplyStylesOnWrap directive requires a className');
    }
    this.setWrapAttributes();

    // identify an element to observe
    const elementToObserve = this.el.nativeElement;

    // create a new instance of `MutationObserver` named `observer`,
    // passing it a callback function
    this.observer = new MutationObserver(() => {
      this.setWrapAttributes();
    });

    // call `observe()` on that MutationObserver instance,
    // passing it the element to observe, and the options object
    this.observer.observe(elementToObserve, { subtree: true, childList: true, characterData: true });

    // create a new instance of `ResizeObserver` named `resizeObserver`,
    this.resizeObserver = new ResizeObserver(() => {
      this.setWrapAttributes();
    });
    this.resizeObserver.observe(elementToObserve);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.observer.disconnect();
    this.resizeObserver.disconnect();
  }

  private setWrapAttributes(): void {
    const items = this.el.nativeElement.children;
    let row = 0;
    for (let i = 1; i < items.length; i++) {
      const item: HTMLDivElement = items[i] as HTMLDivElement;
      const prev: HTMLDivElement = items[i - 1] as HTMLDivElement;
      if (prev.offsetLeft >= item.offsetLeft) {
        row++;
      }
      item.dataset.row = '' + row;
      if (row > 0) {
        item.classList.add(this.wpApplyClassOnWrap);
      } else {
        item.classList.remove(this.wpApplyClassOnWrap);
      }
    }
  }
}
