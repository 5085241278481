<wp-input class="wp-input-search" [control]="control" [label]="placeholder">
  <wp-input-prefix [marginRight]="'20px'" moveDown="3px" moveRight="10px">
    <wp-icon-search [disabled]="disabled$ | async"></wp-icon-search>
  </wp-input-prefix>
  <wp-input-suffix
    [marginRight]="'10px'"
    *ngIf="!!control.value && !(disabled$ | async)"
    (onClicked)="clearInput()"
  >
    <wp-icon-close [size]="'small'"></wp-icon-close>
  </wp-input-suffix>
</wp-input>
