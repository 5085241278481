import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wp-labeled-toggle',
  templateUrl: './labeled-toggle.component.html',
  styleUrls: ['./labeled-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledToggleComponent implements OnInit {
  @Input() public label: string;
  @Input() public selected: boolean;
  @Input() public disabled: boolean;
  @Input() public loading: boolean;
  @Input() public highlightOnHover = true;

  @Output() public toggled: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onToggle(): void {
    if (!this.disabled) {
      this.toggled.emit();
    }
  }
}
