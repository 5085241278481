import * as fromEntities from '../entities';
import { CreateSchoolEmployeeRequest } from '../../../../api/endpoints/create-school-employee';

export const getEmployeeAddRequest = (
  employee: fromEntities.EmployeeProfileEditModel,
  imgPath: string,
  districtId: string,
): CreateSchoolEmployeeRequest => {
  const employeeCopy = { ...employee };
  delete employeeCopy.photo;
  const request: CreateSchoolEmployeeRequest = {
    ...employeeCopy,
    schoolEmployeeImagePath: imgPath,
    districtId,
  };
  return request;
};
