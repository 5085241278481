import { UntypedFormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { SmartCheckbox, SmartCheckboxConfig, SmartCheckboxValue } from './smart-checkbox';
import { SmartInputModel } from './smart-input-model';

export interface SmartCheckboxGroupConfig {}

export interface SmartCheckboxGroupValue<CheckedValue, UncheckedValue> {
  snapshot: { [checkboxId: string]: boolean };
  all: SmartCheckboxValue<CheckedValue, UncheckedValue>[];
  checked: SmartCheckboxValue<CheckedValue, UncheckedValue>[];
  unchecked: SmartCheckboxValue<CheckedValue, UncheckedValue>[];
  changes: {
    checked: SmartCheckboxValue<CheckedValue, UncheckedValue>[];
    unchecked: SmartCheckboxValue<CheckedValue, UncheckedValue>[];
  };
}

export class SmartCheckboxGroup<CheckedValue, UncheckedValue> implements SmartInputModel {
  public map: { [checkboxId: string]: SmartCheckbox<CheckedValue, UncheckedValue> };
  public array: SmartCheckbox<CheckedValue, UncheckedValue>[];
  public form: UntypedFormArray;

  constructor(checkboxes: SmartCheckboxConfig<CheckedValue, UncheckedValue>[], config?: SmartCheckboxGroupConfig) {
    this.array = checkboxes.map((item) => new SmartCheckbox<CheckedValue, UncheckedValue>(item));
    this.map = {};
    this.array.forEach((item) => {
      this.map[item.id] = item;
    });
    this.form = new UntypedFormArray(this.array.map((checkbox) => checkbox.control));
  }

  public hasChanges(): boolean {
    return this.array.some((item) => item.hasChanges());
  }

  public getValue(): SmartCheckboxGroupValue<CheckedValue, UncheckedValue> {
    const snapshot: { [checkboxId: string]: boolean } = {};
    const all: SmartCheckboxValue<CheckedValue, UncheckedValue>[] = [];
    const checked: SmartCheckboxValue<CheckedValue, UncheckedValue>[] = [];
    const unchecked: SmartCheckboxValue<CheckedValue, UncheckedValue>[] = [];
    const changes = { checked: [], unchecked: [] };

    this.array.forEach((item) => {
      const value = item.getValue();
      snapshot[item.id] = value.checked;
      all.push(value);
      if (value.checked) {
        checked.push(value);
        if (item.hasChanges()) {
          changes.checked.push(value);
        }
      } else {
        unchecked.push(value);
        if (item.hasChanges()) {
          changes.unchecked.push(value);
        }
      }
    });

    return {
      snapshot,
      all,
      checked,
      unchecked,
      changes,
    };
  }

  public isValid(): boolean {
    return true;
  }

  public showErrorIfAny(): void {}

  public getValueChanges(): Observable<any> {
    return this.form.valueChanges;
  }
}
