<!--matPrefix and matSuffix are for consistent padding-->
<mat-form-field class="wp-select" [hideRequiredMarker]="true">
  <mat-label>{{label}}</mat-label>
  <div matPrefix></div>
  <mat-select [formControl]="control" [compareWith]="compareWithFn">
    <mat-option *ngFor="let opt of displayOptions" [value]="opt.value"
      >{{opt.displayLabel}}
    </mat-option>
  </mat-select>
  <div matSuffix></div>
  <mat-error *ngIf="control.invalid && control.hasError('required')">
    Required field
  </mat-error>
</mat-form-field>
