<wp-common-popup-page>
  <wp-common-popup-page-top (closeClicked)="onCancel()">
    <wp-common-popup-page-title>{{title}}</wp-common-popup-page-title>
  </wp-common-popup-page-top>
  <wp-common-popup-page-body>
    <div class="body">
      <div class="message" [innerHTML]="messageHTML"></div>
      <div class="buttons">
        <wp-stack-layout [spacing]="20" [mainAxisAlignment]="'flex-end'">
          <wp-stack-layout-item *ngFor="let o of options">
            <ng-container
              *ngIf="o.style === 'POSITIVE' || o.style === 'DESTRUCTIVE'; else dismissOptionTemplate"
            >
              <wp-btn-primary
                [color]="o.style === 'DESTRUCTIVE' ? 'red' : 'green'"
                [width]="'auto'"
                (clicked)="onOptionClick(o)"
              >
                {{ o.displayText }}
              </wp-btn-primary>
            </ng-container>
            <ng-template #dismissOptionTemplate>
              <wp-btn-secondary [width]="'auto'" (clicked)="onOptionClick(o)">
                {{ o.displayText }}
              </wp-btn-secondary>
            </ng-template>
          </wp-stack-layout-item>
        </wp-stack-layout>
      </div>
    </div>
  </wp-common-popup-page-body>
</wp-common-popup-page>
