import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ShortWeekdayPipe } from './short-weekday.pipe';
import { DisplayQuantityPipe } from './display-quantity.pipe';
import { DisplayYYYYMMDDPipe } from './display-yyyymmdd.pipe';
import { DisplayTimeNumberPipe } from './display-time-number.pipe';
import { JoinPipe } from './join.pipe';
import { DisplayHHMMTimeStringPipe } from './display-hhmm-time-string.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';
import { MillisDisplayDurationPipe } from './millis-display-duration.pipe';
import { UtcTimeFromNowPipe } from './utc-time-from-now.pipe';
import { DisplayDateTimeToUtcPipe } from './display-date-time-to-utc.pipe';
import { DisplayEntityTypePipe } from './display-entity-type.pipe';
import { VehicleNamePipe } from './vehicle-name.pipe';
import { VehicleLicenseNamePipe } from './vehicle-license-name.pipe';
import { CampusGradesDisplayPipe } from './campus-grades-display.pipe';
import { CampusScheduleWeekdaysPipe } from './campus-schedule-weekdays.pipe';
import { DisplayYyyymmddMultiPipe } from './display-yyyymmdd-multi.pipe';
import { DisplayDriverGenderPipe } from './display-driver-gender.pipe';
import { DisplayStatePipe } from './display-state.pipe';
import { UtcStampToLocalDisplayDateTimePipe } from './utc-stamp-to-local-display-date-time.pipe';
import { UtcStampToLocalDisplayTimePipe } from './utc-stamp-to-local-display-time.pipe';
import { UtcStampToLocalDisplayDatePipe } from './utc-stamp-to-local-display-date.pipe';
import { VehicleDisplayEquipmentPipe } from './vehicle-display-equipment.pipe';
import { CentsToDollarsPipe } from './cents-to-dollars.pipe';
import { DisplayArrayPipe } from './display-array.pipe';
import { FirstNSymbolsPipe } from './first-n-symbols.pipe';
import { PluralNounPipe } from './plural-noun.pipe';
import { ActivityDisplayStatusPipe } from './activity-display-status.pipe';
import { ActivityStatusColorPipe } from './activity-status-color.pipe';
import { ErrorGeneralMessagePipe } from './error-general-message.pipe';
import { ErrorSpecialMessagePipe } from './error-special-message.pipe';
import { ErrorReasonPipe } from './error-reason.pipe';
import { DisplayDateRangePipe } from './display-date-range.pipe';
import { DisplayActivityTypePipe } from './display-activity-type.pipe';
import { DisplayActivitySubtypePipe } from './display-activity-subtype.pipe';
import { XsWeekdayPipe } from './xs-weekday.pipe';
import { StudentEquipmentV2Pipe } from './student-equipment-v2.pipe';
import { StudentV2CampusPipe } from './student-v2-campus.pipe';
import { DobToAgePipe } from './dob-to-age.pipe';
import { StudentV2ScheduleDaysPipe } from './student-v2-schedule-days.pipe';
import { DisplayWeekdaysWithDashesPipe } from './display-weekdays-with-dashes.pipe';
import { DurationMinutesToHHMMPipe } from './duration-minutes-to-hhmm.pipe';
import { Time24FromHHMMPipe } from './time-24-from-hhmm.pipe';
import { MinutesDisplayDurationPipe } from './minutes-display-duration.pipe';
import { DisplayNumberPipe } from './display-number.pipe';
import { DisplayUnderscoreStringPipe } from './display-underscore-string.pipe';
import { DisplayRegionPipe } from './display-region.pipe';
import { DisplayCatalogLabelPipe } from './display-catalog-label.pipe';
import { DisplayEntityLabelPipe } from './display-entity-label.pipe';
import { DisplayCatalogOptionLabelPipe } from './display-catalog-option-label.pipe';
import { BooleanToYesOrNoPipe } from './boolean-to-yes-or-no.pipe';
import { DisplayShortTimezonePipe } from './display-short-timezone.pipe';
import { PathToDownloadUrlPipe } from './path-to-download-url.pipe';
import { DisplayListItemNumberPipe } from './display-list-item-number.pipe';
import { YYYYMMDDToWeekdayPipe } from './yyyymmdd-to-weekday.pipe';
import { YYYYMMDDToMDPipe } from './yyyymmdd-to-md.pipe';
import { PortalEntityPipe } from './portal-entity.pipe';
import { PortalEntityVehiclePipe } from './portal-entity-vehicle.pipe';
import { DisplayEllipsisPipe } from './display-ellipsis.pipe';
import { FileSizeDisplayPipe } from './file-size-display.pipe';
import { MetersToDisplayDistanceMilesPipe } from './meters-to-display-distance-miles.pipe';
import { DisplayWeekdaysCommaShortPipe } from './display-weekdays-comma-short.pipe';
import { GradeListDisplayPipe } from './grade-list-display.pipe';
import { DisplayCharterTripIdPipe } from './display-charter-trip-id.pipe';

const pipes = [
  ShortWeekdayPipe,
  DisplayDateTimeToUtcPipe,
  DisplayQuantityPipe,
  DisplayYYYYMMDDPipe,
  DisplayEntityTypePipe,
  DisplayTimeNumberPipe,
  JoinPipe,
  PhoneFormatPipe,
  DisplayHHMMTimeStringPipe,
  MillisDisplayDurationPipe,
  UtcTimeFromNowPipe,
  VehicleNamePipe,
  VehicleLicenseNamePipe,
  CampusGradesDisplayPipe,
  CampusScheduleWeekdaysPipe,
  DisplayYyyymmddMultiPipe,
  DisplayDriverGenderPipe,
  DisplayStatePipe,
  UtcStampToLocalDisplayDateTimePipe,
  UtcStampToLocalDisplayDatePipe,
  UtcStampToLocalDisplayTimePipe,
  VehicleDisplayEquipmentPipe,
  CentsToDollarsPipe,
  DisplayArrayPipe,
  FirstNSymbolsPipe,
  PluralNounPipe,
  ActivityDisplayStatusPipe,
  ActivityStatusColorPipe,
  ErrorGeneralMessagePipe,
  ErrorSpecialMessagePipe,
  ErrorReasonPipe,
  DisplayDateRangePipe,
  DisplayActivityTypePipe,
  DisplayActivitySubtypePipe,
  XsWeekdayPipe,
  StudentEquipmentV2Pipe,
  StudentV2CampusPipe,
  DobToAgePipe,
  StudentV2ScheduleDaysPipe,
  MinutesDisplayDurationPipe,
  DurationMinutesToHHMMPipe,
  Time24FromHHMMPipe,
  DisplayNumberPipe,
  DisplayWeekdaysWithDashesPipe,
  DisplayUnderscoreStringPipe,
  DisplayRegionPipe,
  DisplayCatalogLabelPipe,
  DisplayEntityLabelPipe,
  DisplayCatalogOptionLabelPipe,
  PathToDownloadUrlPipe,
  YYYYMMDDToWeekdayPipe,
  YYYYMMDDToMDPipe,
  DisplayListItemNumberPipe,
  DisplayEllipsisPipe,
  PortalEntityPipe,
  PortalEntityVehiclePipe,
  FileSizeDisplayPipe,
  MetersToDisplayDistanceMilesPipe,
  DisplayWeekdaysCommaShortPipe,
  DisplayCharterTripIdPipe,
  GradeListDisplayPipe,
];

@NgModule({
  declarations: [...pipes, BooleanToYesOrNoPipe, DisplayShortTimezonePipe],
  imports: [CommonModule],
  exports: [...pipes, BooleanToYesOrNoPipe, DisplayShortTimezonePipe],
  providers: [CurrencyPipe],
})
export class PipesModule {}
