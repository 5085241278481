import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as fromTypes from '@rootTypes';
import { BehaviorSubject, combineLatest, Observable, Subscription, timer } from 'rxjs';
import { map, shareReplay, startWith, take } from 'rxjs/operators';
import { DropdownComponent } from '../dropdown/dropdown/dropdown.component';
import { defaultDisplayDateFn } from './default-display-date-fn';

/**
 * Control for selecting multiple dates
 * Binds to formControl of YYYYMMDD[]
 */
@Component({
  selector: 'wp-input-date-multi',
  templateUrl: './input-date-multi.component.html',
  styleUrls: ['./input-date-multi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateMultiComponent implements OnInit {
  @Input() public displayDateFn: (dates: fromTypes.utils.date.YYYYMMDDString[]) => string = defaultDisplayDateFn;
  @Input() public label: string;
  @Input() public control: UntypedFormControl = new UntypedFormControl([]); // YYYYMMDD[]
  @Input() public weekSelectCount: number;
  @Input() public isStrictDisableWeeks: boolean;
  @Input() public monthCount = 2;
  @Input() public alignDropdown: 'left' | 'right' | 'center';
  @Input() public disableDatesBefore: fromTypes.utils.date.YYYYMMDDString;
  @Input() public disableDatesAfter: fromTypes.utils.date.YYYYMMDDString;
  @Input() isIcon: boolean;

  public highlighted$: Observable<boolean>;
  public isDisabled$: Observable<boolean>;
  public selectedDateArr$: Observable<fromTypes.YYYYMMDDString[]>;
  public displayDateControl: UntypedFormControl = new UntypedFormControl();

  private focused$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private subscripitions: Subscription = new Subscription();
  @ViewChild('maskEl') private triggerMaskEl: ElementRef;
  @ViewChild('dropdownEl') private dropdownEl: DropdownComponent;

  constructor() {}

  ngOnInit(): void {
    this.checkInputFormat();
    this.highlighted$ = this.getHighLightedObs();
    this.isDisabled$ = this.getDisabledObs$();
    this.selectedDateArr$ = this.control.valueChanges.pipe(
      startWith(this.control.value),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );
    const sub = this.control.valueChanges
      .pipe(startWith(this.control.value))
      .subscribe((val: fromTypes.YYYYMMDDString[]) => {
        if (val && val.length) {
          this.displayDateControl.setValue(this.displayDateFn(val));
        } else {
          this.displayDateControl.setValue(null);
        }
      });
    const sub2 = this.control.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        this.displayDateControl.setErrors(this.control.errors);
        this.displayDateControl.markAsTouched();
      } else {
        this.displayDateControl.setErrors(null);
      }
    });
    this.subscripitions.add(sub);
    this.subscripitions.add(sub2);
  }

  public onTriggerClick(event: MouseEvent): void {
    this.isDisabled$.pipe(take(1)).subscribe((disabled) => {
      if (!disabled) {
        this.focused$$.next(true);
        this.displayDateControl.markAsTouched();
      } else {
        event.stopPropagation();
      }
    });
  }

  public onCloseDropdown(): void {
    this.focused$$.next(false);
  }

  public onValueChangedByUserAction(val: fromTypes.YYYYMMDDString[]): void {
    this.control.setValue(val || []);
  }

  public onClearDatesClicked(): void {
    this.control.setValue([]);
  }

  public onApplyClicked(): void {
    if (this.dropdownEl) {
      this.dropdownEl.onCloseDropdown();
    }
  }

  private getHighLightedObs(): Observable<boolean> {
    const focused$ = this.focused$$.asObservable();
    const valid$ = this.control.statusChanges.pipe(
      startWith('VALID'),
      map((status) => status !== 'INVALID'),
    );
    return combineLatest([focused$, valid$]).pipe(map(([focused, valid]) => focused && valid));
  }

  private checkInputFormat(): void {
    const value = this.control.value;
    if (value !== null && value !== undefined) {
      if (!(typeof value === 'object' && Array.isArray(value))) {
        throw new Error(
          'Invalid value provided to input date. Should be an array of YYYYMMDD string. Got ' +
            typeof value +
            ' instead.',
        );
      }
      if (value.length) {
        const firstItem = value[0];
        if (!(typeof firstItem === 'string')) {
          throw new Error(
            'Invalid value provided to input date. The array should contain YYYYMMDD strings. Got ' +
              typeof firstItem +
              ' instead.',
          );
        }
      }
    }
  }

  private getDisabledObs$(): Observable<boolean> {
    return timer(0, 600).pipe(
      map(() => this.control.disabled),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}
