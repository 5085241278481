import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { FormControlsModule } from '../form-controls/form-controls.module';
import { EntityFilterModule } from '../entity-filter/entity-filter.module';
import { ComponentsModule } from '../components/components.module';

import { SmartFormFieldDisplayComponent } from './components/smart-form-field-display/smart-form-field-display.component';
import { SmartInputTextComponent } from './components/smart-input-text/smart-input-text.component';
import { SmartInputEmailComponent } from './components/smart-input-email/smart-input-email.component';
import { SmartInputPhoneComponent } from './components/smart-input-phone/smart-input-phone.component';
import { SmartInputAddressComponent } from './components/smart-input-address/smart-input-address.component';
import { SmartInputNumberComponent } from './components/smart-input-number/smart-input-number.component';
import { SmartTextareaComponent } from './components/smart-textarea/smart-textarea.component';
import { SmartInputTransportationProviderComponent } from './components/smart-input-transportation-provider/smart-input-transportation-provider.component';
import { SmartInputTransportationProviderVendorComponent } from './components/smart-input-transportation-provider-vendor/smart-input-transportation-provider-vendor.component';
import { SmartInputTransportationProviderYardComponent } from './components/smart-input-transportation-provider-yard/smart-input-transportation-provider-yard.component';
import { SmartSelectComponent } from './components/smart-select/smart-select.component';
import { SmartInputDateComponent } from './components/smart-input-date/smart-input-date.component';
import { SmartInputDateRangeComponent } from './components/smart-input-date-range/smart-input-date-range.component';
import { SmartInputDateRangeFromComponent } from './components/smart-input-date-range-from/smart-input-date-range-from.component';
import { SmartInputDateRangeToComponent } from './components/smart-input-date-range-to/smart-input-date-range-to.component';
import { SmartCheckboxComponent } from './components/smart-checkbox/smart-checkbox.component';
import { SmartInputSchoolComponent } from './components/smart-input-school/smart-input-school.component';
import { SmartInputSchoolDistrictComponent } from './components/smart-input-school-district/smart-input-school-district.component';
import { SmartInputSchoolCampusComponent } from './components/smart-input-school-campus/smart-input-school-campus.component';
import { SmartInputStudentTransportationStatusComponent } from './components/smart-input-student-transportation-status/smart-input-student-transportation-status.component';
import { SmartInputRiderEquipmentComponent } from './components/smart-input-rider-equipment/smart-input-rider-equipment.component';
import { SmartInputRiderEquipmentItemComponent } from './components/smart-input-rider-equipment-item/smart-input-rider-equipment-item.component';
import { SmartInputRiderSpecialNeedsComponent } from './components/smart-input-rider-special-needs/smart-input-rider-special-needs.component';
import { SmartInputRiderSpecialNeedsItemComponent } from './components/smart-input-rider-special-needs-item/smart-input-rider-special-needs-item.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SmartInputStudentSchoolInfoComponent } from './components/smart-input-student-school-info/smart-input-student-school-info/smart-input-student-school-info.component';
import { SmartInputStudentSchoolInfoDistrictComponent } from './components/smart-input-student-school-info/smart-input-student-school-info-district/smart-input-student-school-info-district.component';
import { SmartInputStudentSchoolInfoCampusComponent } from './components/smart-input-student-school-info/smart-input-student-school-info-campus/smart-input-student-school-info-campus.component';
import { SmartInputStudentSchoolInfoSchoolYearComponent } from './components/smart-input-student-school-info/smart-input-student-school-info-school-year/smart-input-student-school-info-school-year.component';
import { SmartInputStudentSchoolInfoGradeComponent } from './components/smart-input-student-school-info/smart-input-student-school-info-grade/smart-input-student-school-info-grade.component';
import { SmartSelectAsyncComponent } from './components/smart-select-async/smart-select-async.component';
import { MatInputModule } from '@angular/material/input';
import { PhotosModule } from '../photos/photos.module';
import { SmartInputDistrictComponent } from './components/smart-input-district/smart-input-district.component';
import { SmartInputVendorComponent } from './components/smart-input-vendor/smart-input-vendor.component';
import { SmartAutocompleteComponent } from './components/smart-autocomplete/smart-autocomplete.component';
import { SmartInputTimeComponent } from './components/smart-input-time/smart-input-time.component';
import { DirectivesModule } from '../directives/directives.module';
import { SmartInputPhotoMultiComponent } from './components/smart-input-photo-multi/smart-input-photo-multi.component';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { SmartInputAddressMapComponent } from './components/smart-input-address-map/smart-input-address-map.component';
import { SmartInputDistrictStudentComponent } from './components/smart-input-district-student/smart-input-district-student/smart-input-district-student.component';
import { SmartInputDistrictStudentDistrictComponent } from './components/smart-input-district-student/smart-input-district-student-district/smart-input-district-student-district.component';
import { SmartInputDistrictStudentStudentComponent } from './components/smart-input-district-student/smart-input-district-student-student/smart-input-district-student-student.component';
import { SmartAddressWithExactLocationComponent } from './components/smart-address-with-exact-location/smart-address-with-exact-location.component';
import { TypographyModule } from '../typography/typography.module';
import { SmartInputCurrencyComponent } from './components/smart-input-currency/smart-input-currency.component';
import { SmartWeekdayCheckboxComponent } from './components/smart-weekday-checkbox/smart-weekday-checkbox.component';
import { ArrowIconsModule } from '../icons/arrow-icons/arrow-icons.module';
import { SmartInputStudentHomeAddressComponent } from './components/smart-input-student-home-address/smart-input-student-home-address.component';
import { StudentChangeRequestsCommonModule } from '../../features/common/student-change-requests-common/student-change-requests-common.module';
import { MatRadioModule } from '@angular/material/radio';
import { ClosestBusAddressLookupEditorComponent } from './components/closest-bus-address-lookup-editor/closest-bus-address-lookup-editor.component';
import { LoadingContentErrorModule } from '../page/loading-content-error/loading-content-error.module';
import { GoogleMapModule } from '../google-map/google-map.module';
import { SmartMultiSelectComponent } from './components/smart-multi-select/smart-multi-select.component';

@NgModule({
  declarations: [
    SmartFormFieldDisplayComponent,
    SmartInputTextComponent,
    SmartInputEmailComponent,
    SmartInputPhoneComponent,
    SmartInputAddressComponent,
    SmartInputNumberComponent,
    SmartTextareaComponent,
    SmartInputTransportationProviderComponent,
    SmartInputTransportationProviderVendorComponent,
    SmartInputTransportationProviderYardComponent,
    SmartSelectComponent,
    SmartInputDateComponent,
    SmartInputDateRangeComponent,
    SmartInputDateRangeFromComponent,
    SmartInputDateRangeToComponent,
    SmartCheckboxComponent,
    SmartInputSchoolComponent,
    SmartInputSchoolDistrictComponent,
    SmartInputSchoolCampusComponent,
    SmartInputStudentTransportationStatusComponent,
    SmartInputRiderEquipmentComponent,
    SmartInputRiderEquipmentItemComponent,
    SmartInputRiderSpecialNeedsComponent,
    SmartInputRiderSpecialNeedsItemComponent,
    SmartInputStudentSchoolInfoComponent,
    SmartInputStudentSchoolInfoDistrictComponent,
    SmartInputStudentSchoolInfoCampusComponent,
    SmartInputStudentSchoolInfoSchoolYearComponent,
    SmartInputStudentSchoolInfoGradeComponent,
    SmartSelectAsyncComponent,
    SmartInputDistrictComponent,
    SmartInputVendorComponent,
    SmartAutocompleteComponent,
    SmartInputTimeComponent,
    SmartInputPhotoMultiComponent,
    SmartInputAddressMapComponent,
    SmartInputDistrictStudentComponent,
    SmartInputDistrictStudentDistrictComponent,
    SmartInputDistrictStudentStudentComponent,
    SmartAddressWithExactLocationComponent,
    SmartInputCurrencyComponent,
    SmartWeekdayCheckboxComponent,
    SmartInputStudentHomeAddressComponent,
    ClosestBusAddressLookupEditorComponent,
    SmartMultiSelectComponent,
  ],
  imports: [
    CommonModule,
    FormControlsModule,
    EntityFilterModule,
    ComponentsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSelectModule,
    PhotosModule,
    DirectivesModule,
    IconCloseModule,
    TypographyModule,
    ArrowIconsModule,
    StudentChangeRequestsCommonModule,
    MatRadioModule,
    LoadingContentErrorModule,
    GoogleMapModule,
  ],
  exports: [
    SmartFormFieldDisplayComponent,
    SmartInputTextComponent,
    SmartInputEmailComponent,
    SmartInputPhoneComponent,
    SmartInputAddressComponent,
    SmartInputNumberComponent,
    SmartTextareaComponent,
    SmartInputTransportationProviderComponent,
    SmartInputTransportationProviderVendorComponent,
    SmartInputTransportationProviderYardComponent,
    SmartSelectComponent,
    SmartInputDateComponent,
    SmartInputDateRangeComponent,
    SmartInputDateRangeFromComponent,
    SmartInputDateRangeToComponent,
    SmartCheckboxComponent,
    SmartInputSchoolComponent,
    SmartInputSchoolDistrictComponent,
    SmartInputSchoolCampusComponent,
    SmartInputStudentTransportationStatusComponent,
    SmartInputRiderEquipmentComponent,
    SmartInputRiderEquipmentItemComponent,
    SmartInputRiderSpecialNeedsComponent,
    SmartInputRiderSpecialNeedsItemComponent,
    SmartInputStudentSchoolInfoComponent,
    SmartInputStudentSchoolInfoDistrictComponent,
    SmartInputStudentSchoolInfoCampusComponent,
    SmartInputStudentSchoolInfoSchoolYearComponent,
    SmartInputStudentSchoolInfoGradeComponent,
    SmartSelectAsyncComponent,
    SmartInputDistrictComponent,
    SmartInputVendorComponent,
    SmartAutocompleteComponent,
    SmartInputTimeComponent,
    SmartInputPhotoMultiComponent,
    SmartInputAddressMapComponent,
    SmartInputDistrictStudentComponent,
    SmartInputDistrictStudentDistrictComponent,
    SmartInputDistrictStudentStudentComponent,
    SmartAddressWithExactLocationComponent,
    SmartInputCurrencyComponent,
    SmartWeekdayCheckboxComponent,
    SmartInputStudentHomeAddressComponent,
    ClosestBusAddressLookupEditorComponent,
    SmartMultiSelectComponent,
  ],
})
export class SmartFormsModule {}
