export const iconPaths = {
  ACCESS_LOCK_GREEN: '/assets/icons/access-lock-green.svg',
  ACCESS_LOCK_GREY: '/assets/icons/access-lock-grey.svg',
  ACTIVITIES_GREEN: '/assets/icons/activities-menu-icon-green.svg',
  ACTIVITIES_GREY: '/assets/icons/activities-menu-icon-grey.svg',
  ADDRESS_LIGHT_GREY: '/assets/icons/addresses-grey-light.svg',
  AI_GREEN: '/assets/icons/ai-green.svg',
  ANALYTICS_GREEN: 'assets/icons/ic-analytics-green.svg',
  ANALYTICS_GREY: 'assets/icons/ic-analytics.svg',
  APPROVED_PARKOUT_LOCATION_GREEN: '/assets/icons/approved-parkout-location-green.svg',
  ARROW_DIRECTION_LEFT_GREEN: '/assets/icons/arrow-direction-left-green.svg',
  ARROW_DIRECTION_LEFT_GREY: '/assets/icons/arrow-direction-left-grey.svg',
  ARROW_DIRECTION_RIGHT_GREEN: '/assets/icons/arrow-direction-right-green.svg',
  ARROW_DIRECTION_RIGHT_GREY: '/assets/icons/arrow-direction-right-grey.svg',
  ARROW_DOWN_GREY: '/assets/icons/arrow-down-grey.svg',
  ARROW_DOWN_WHITE: '/assets/icons/arrow-down-white.svg',
  ARROW_LEFT_GRAY: '/assets/icons/arrow-left-gray.svg',
  ARROW_RIGHT_GRAY: '/assets/icons/arrow-right-gray.svg',
  ARROW_UP_GREY: '/assets/icons/arrow-up-grey.svg',
  ARROW_WIDE_RIGHT_GREEN_FILLED: '/assets/icons/arrow-wide-green.svg',
  BELL_CROSSED_OUT_GREY: '/assets/icons/bell-crossed-out-grey.svg',
  BELL_GREY: '/assets/icons/bell-grey.svg',
  BUS_STOP_GREY: '/assets/icons/bus-stop-grey.svg',
  CALENDAR_GREEN: '/assets/icons/ic-calendar.svg',
  CALENDAR_GREY: '/assets/icons/ic-calendar-grey.svg',
  CAMPUS_GREEN: '/assets/icons/campus-green.svg',
  CHECKED_CIRCLE_LARGE: '/assets/icons/checked-circle-large.svg',
  CHECK_CIRCLE_GREEN_STROKE: '/assets/icons/check-circle-green-stroke.svg',
  CHECK_SMALL_STROKE: '/assets/icons/check-small-stroke.svg',
  CIRCLE_CROSSED_GRAY: './assets/icons/circle-crossed-gray.svg',
  CLOCK_ARROW_UP_GRAY: '/assets/icons/clock-arrow-up-gray.svg',
  CLOCK_GREY: '/assets/icons/clock-grey.svg',
  CLOCK_IN_GREY: '/assets/icons/clock-in-grey.svg',
  CLOCK_LIGHT_GREY: '/assets/icons/clock-light-grey.svg',
  CLOCK_OUT_GREY: '/assets/icons/clock-out-grey.svg',
  CLOCK_TIME_GREEN: '/assets/icons/clock-time-green.svg',
  COMPASS_GREY: '/assets/icons/compass_grey.svg',
  COPY_TO_CLIPBOARD: '/assets/icons/ic-copy.svg',
  COPY_TO_CLIPBOARD_BOLD: '/assets/icons/copy-bold.svg',
  DIRECTIONS_WALKING_GREY: '/assets/icons/direction-walking-grey.svg',
  DOWNLOAD: '/assets/icons/download.svg',
  DOWNLOAD_CARD_BLACK: '/assets/icons/download-card-black.svg',
  DOWNLOAD_CARD_GREEN: '/assets/icons/download-card-green.svg',
  DRIVER_BUS_REPORT_GRAY: '/assets/icons/driver-bus-report-gray.svg',
  DRIVER_LOGIN_MODE_EMAIL: '/assets/icons/ic-email-login.svg',
  DRIVER_LOGIN_MODE_PIN: '/assets/icons/ic-pin-login.svg',
  EDIT_PENCIL_GREEN: '/assets/icons/edit-pencil-square-green.svg',
  EDIT_PENCIL_GREY: '/assets/icons/edit-pencil-grey.svg',
  ELECTRIC_GREEN: '/assets/icons/electric-green.svg',
  ELECTRIC_GREY: '/assets/icons/electric-grey.svg',
  ELECTRIC_RED: '/assets/icons/electric-red.svg',
  EMPLOYEE_CARD_GREEN: '/assets/icons/employee-card-green.svg',
  EQUIPMENT_AIDE: '/assets/icons/student-designation/aide.svg',
  EQUIPMENT_BOOSTER_SEAT: '/assets/icons/rider-equipment/booster-seat.svg',
  EQUIPMENT_CAR_SEAT: '/assets/icons/rider-equipment/car-seat.svg',
  EQUIPMENT_HARNESS: '/assets/icons/rider-equipment/harness.svg',
  EQUIPMENT_SEAT_BELT_GUARD: '/assets/icons/rider-equipment/seat-belt-guard.svg',
  EQUIPMENT_SPECIAL_NEEDS: '/assets/icons/rider-equipment/special-needs.svg',
  EQUIPMENT_WALKER: '/assets/icons/rider-equipment/walker.svg',
  EQUIPMENT_WHEELCHAIR: '/assets/icons/rider-equipment/wheelchair.svg',
  EXCLAMATION_SIGN_CIRCLED_FILLED_ORANGE: '/assets/icons/exclamation-orange-filled-circle.svg',
  EXCLAMATION_SIGN_CIRCLED_ORANGE: '/assets/icons/exclamation-sign-circled-orange.svg',
  EXCLAMATION_SIGN_ORANGE_BOLD: '/assets/icons/exclamation-sign-orange.svg',
  EXCLAMATION_SIGN_RED_TRIANGLE: '/assets/icons/exclamation-red-triangle.svg',
  FILTER_GREEN: '/assets/icons/filter.svg',
  FILTER_GREY: '/assets/icons/quick-filter-grey.svg',
  FILTER_TOGGLES_GREEN: '/assets/icons/filter-toggles-green.svg',
  FILTER_TOGGLES_GREY: '/assets/icons/filter-toggles-grey.svg',
  GUARDIANS_LIGHT_GREY: '/assets/icons/guardians-grey-light.svg',
  HOME_STROKE_GREY: '/assets/icons/home-gray-stroke.png',
  INBOX_GREY: '/assets/icons/inbox-grey.svg',
  INBOX_LIGHT_GREY: '/assets/icons/inbox-light-grey.svg',
  INFO_CIRCLE: '/assets/icons/info-circle.svg',
  INFO_CIRCLE_20: '/assets/icons/info-circle-20.svg',
  INFO_CIRCLE_GREY: '/assets/icons/info-circle-grey.svg',
  INFO_CIRCLE_ORANGE: '/assets/icons/info-circle-orange.svg',
  INFO_CIRCLE_RED: '/assets/icons/info-circle-red.svg',
  LIGHT_BULB: '/assets/icons/light-bulb.svg',
  LINK_EXTERNAL_DARK_GREY: '/assets/icons/ic-external-link-dark-grey.svg',
  LINK_EXTERNAL_GREY: '/assets/icons/ic-external-link.svg',
  MAP_CAMPUS_GREEN: '/assets/icons/campus-light-green.svg',
  MAP_CAR: '/assets/icons/map-car.svg',
  MAP_DRAW_CIRCLE_GREY: '/assets/icons/map-draw-circle-grey.svg',
  MAP_DRAW_CIRCLE_LIGHT_GREY: '/assets/icons/map-draw-circle-light-grey.svg',
  MAP_DRAW_POLYGON_BLUE: '/assets/icons/map-draw-polygon-blue.svg',
  MAP_DRAW_POLYGON_GREY: '/assets/icons/map-draw-polygon-grey.svg',
  MAP_DRAW_POLYGON_LIGHT_GREY: '/assets/icons/map-draw-polygon-light-grey.svg',
  MAP_GEOFENCE_ADDRESS: '/assets/icons/map-geofence-address.svg',
  MAP_GREY: '/assets/icons/map-grey.svg',
  MAP_LOCATOR_BLUE: 'assets/icons/map-pin-locator-blue.svg',
  MAP_LOCATOR_GREEN: 'assets/icons/map-pin-locator-green.svg',
  MAP_LOCATOR_GREY: 'assets/icons/map-pin-locator-grey.svg',
  MAP_LOCATOR_ORANGE: 'assets/icons/map-pin-locator-orange.svg',
  MAP_LOCATOR_RED: 'assets/icons/map-pin-locator-red.svg',
  MAP_PARKOUT_LOCATION_BLUE: '/assets/icons/map-parkout-location-blue.svg',
  MAP_PARKOUT_LOCATION_GRAY: '/assets/icons/map-parkout-location-gray.svg',
  MAP_PIN_BLUE: '/assets/icons/stop-marker-blue.svg',
  MAP_PIN_GRAY: '/assets/icons/map-pin-grey.svg',
  MAP_PIN_GREEN: '/assets/icons/map-pin-green.svg',
  MAP_PIN_GREEN_WHITE_BG: '/assets/icons/icon-green-pin-white-bg.svg',
  MAP_PROHIBITED_LOCATION_RED: '/assets/icons/prohibited-location-red.svg',
  MAP_RECENTER: '/assets/icons/map-recenter.svg',
  MODIFIED_GRAY: '/assets/icons/modified-light-gray.svg',
  MULTI_DOC_ALERT: '/assets/icons/multi-doc-alert.svg',
  NEW_PARKOUT_LOCATION_BLUE: '/assets/icons/new-parkout-location-blue.svg',
  NOTE_GREEN: '/assets/icons/note-green.svg',
  NOTE_GREY: '/assets/icons/note-grey.svg',
  NOTE_THIN_GREY: '/assets/icons/note-thin-grey.svg',
  PASSWORD_HIDE: '/assets/icons/password-hide.svg',
  PASSWORD_SHOW: '/assets/icons/password-show.svg',
  PLUS_GEY: '/assets/icons/ic-plus-grey.svg',
  PLUS_GREEN: '/assets/icons/ic-plus.svg',
  PROFILE_LIGHT_GREY: '/assets/icons/profile-grey-light.svg',
  REFRESH_GREEN: '/assets/icons/refresh-green.svg',
  RELOAD_GREEN: '/assets/icons/reload-green.svg',
  REORDER_TRIGGER: '/assets/icons/reorder-trigger.svg',
  RIDE_DRIVER_CHECKED_IN: '/assets/icons/ride-driver-checked-in.svg',
  RIDE_DRIVER_FINISHING_PREV_RIDE: '/assets/icons/ride-driver-finishing-prev-ride.svg',
  RIDE_DRIVER_NOT_CHECKED_ID: '/assets/icons/ride-driver-not-checked-in.svg',
  ROCKET_LAUNCH_GREY: '/assets/icons/rocket-launch-grey.svg',
  SCHOOL_ASSOCIATION_LIGHT_GREY: '/assets/icons/school-association-grey-light.svg',
  SEARCH_GREY: '/assets/icons/search-small-grey.svg',
  STAR_GREEN: '/assets/icons/star.svg',
  STOP_AFTER_PARKOUT_GRAY: '/assets/icons/stop-after-parkout-grey.svg',
  STOP_BEFORE_PARKOUT_ORANGE: '/assets/icons/stop-before-parkout-orange.svg',
  STUDENT_DESIGNATION_ATTENDANT: '/assets/icons/student-designation/attendant.svg',
  STUDENT_DESIGNATION_CORNER_STOP: '/assets/icons/student_designation_corner_stop.svg',
  STUDENT_DESIGNATION_DOOR_TO_DOOR: '/assets/icons/student_designation_door-to-door.svg',
  STUDENT_DESIGNATION_FOSTER_PROGRAM: '/assets/icons/student_designation_foster_program.svg',
  STUDENT_DESIGNATION_HEART_PROGRAM: '/assets/icons/student_designation_heart_program.svg',
  TRANSPORTATION_REQUESTS_GREEN: '/assets/icons/transportation-requests-green.svg',
  TRANSPORTATION_REQUESTS_GREY: '/assets/icons/transportation-requests-grey.svg',
  UNDO_GREEN: '/assets/icons/undo-green.svg',
  UNDO_GREY: '/assets/icons/undo-grey.svg',
  UTILIZATION_BARS_LEFT_GREEN: '/assets/icons/utilization-bars-left-green.svg',
  VEHICLE_INSPECTIONS_GREEN: '/assets/icons/vehicle-inspections-green.svg',
  VEHICLE_INSPECTIONS_GREY: '/assets/icons/vehicle-inspections-grey.svg',
  VIEW_EYE_GREEN: '/assets/icons/view-eye-green.svg',
  YARD_GREEN: '/assets/icons/yard-green.svg',
};

export const getAbsoluteIconPath = (relativePath: string): string => {
  return window.origin + relativePath;
};
