import { dateFormat, formattingConstants, yyyymmddToDate } from '@rootTypes/utils/common/date-time-fns';

/**
 *
 * @param source YYYYMMDDstring like 20200518
 * @param long display long format like August 11, 2010, otherwise Aug 11, 2016
 */
export const yyyymmddDisplay = (source: string, long?: boolean): string => {
  const m = yyyymmddToDate(source);
  if (long) {
    return dateFormat(m, formattingConstants.dateFullMonth);
  }
  return dateFormat(m, formattingConstants.dateMedium);
};
