import { EntitySearchResponseType, EntitySearchResult } from '@apiEntities/entity-search';
import { PortalEntity, VehiclePortalEntity } from '@rootTypes';
import { EntityFilterRequestType } from '../../../api/endpoints/entity-filter';
import { CrossEntitySearchRequestType } from '../../../api/endpoints/entity-search';

type ResultOfType<T extends EntitySearchResponseType> = Extract<EntitySearchResult, { type: T }>;

type CrossEntitySearchRequestTypeValuesUnion = `${CrossEntitySearchRequestType}`;

type MandateEntitySearchRequestTypeFieldValue<T extends EntitySearchResponseType> =
  T extends CrossEntitySearchRequestTypeValuesUnion
    ? { crossEntitySearchRequestType?: CrossEntitySearchRequestType }
    : { crossEntitySearchRequestType: CrossEntitySearchRequestType };

type EntityFilterRequestTypeValueUnion = `${EntityFilterRequestType}`;

type MandateEntityFilterRequestTypeFieldValue<T extends EntitySearchResponseType> =
  T extends EntityFilterRequestTypeValueUnion
    ? { entityFilterRequestType?: EntityFilterRequestType }
    : { entityFilterRequestType: EntityFilterRequestType | undefined };

/**
 * Both entitySearch and entityFilter return the same type of result (EntitySearchResponseType).
 * However, the request types are different.
 * This config maps EntitySearchResponseType to:
 *  1. The id field of the result
 *  2. The transformer function to convert the result to a PortalEntity (if needed). If absent, use default label and id field, copy all fields from result.
 *  3. The crossEntitySearchRequestType (if needed). If absent, use same as response type.
 *  4. The entityFilterRequestType (if needed). If absent, use same as response type.
 *
 *  If you see typescript errors, it likely means you need to add a new entry to this config.
 *  I.e. you've added a new EntitySearchResponseType, so you need to specify an id field in this config.
 *  If the corresponding entry is missing from CrossEntitySearchRequestType or EntityFilterRequestType,
 *  you'll need to add it in the config too. If an entity is searchable with entityFilter, but not entitySearch,
 *  specify undefined for crossEntitySearchRequestType, and vice versa.
 */

type EntitySearchConfigType = {
  [key in EntitySearchResponseType]: {
    idField: keyof ResultOfType<key>;
    // if absent, use default label and id field
    transformer?: (source: ResultOfType<key>) => PortalEntity;
    // if absent, use same as response type
  } & MandateEntitySearchRequestTypeFieldValue<key> &
    MandateEntityFilterRequestTypeFieldValue<key>;
};
export const entitySearchConfig: EntitySearchConfigType = {
  [EntitySearchResponseType.DRIVER]: {
    idField: 'driverId',
  },
  [EntitySearchResponseType.PARENT]: {
    idField: 'parentId',
    crossEntitySearchRequestType: CrossEntitySearchRequestType.CUSTOMER,
  },
  [EntitySearchResponseType.STUDENT]: {
    idField: 'studentId',
    crossEntitySearchRequestType: CrossEntitySearchRequestType.RIDER,
    entityFilterRequestType: EntityFilterRequestType.STUDENT,
  },
  [EntitySearchResponseType.DISTRICT]: {
    idField: 'districtId',
    crossEntitySearchRequestType: CrossEntitySearchRequestType.DISTRICT,
    entityFilterRequestType: EntityFilterRequestType.DISTRICT,
  },
  [EntitySearchResponseType.CAMPUS]: {
    idField: 'campusId',
  },
  [EntitySearchResponseType.SCHOOL]: {
    idField: 'campusId',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.VENDOR]: {
    idField: 'vendorId',
  },
  [EntitySearchResponseType.VEHICLE]: {
    idField: 'vehicleId',
    transformer: (source) => {
      let label = `${source.make} ${source.model}`;
      if (source.vehicleDisplayId) {
        label += `, ${source.vehicleDisplayId}`;
      }
      const entity: VehiclePortalEntity = {
        entityId: source.vehicleId,
        type: source.type,
        label,
        makeAndModel: `${source.make} ${source.model}`,
        licenseStateAndNumber: `${source.license.state}${source.license.number}`,
        assignedDriverText: 'Unassigned',
      };
      const assignedDriverId = source.assignedDriverId || source.assignedDriver?.id;
      if (assignedDriverId) {
        entity.assignedDriverId = assignedDriverId;
        entity.assignedDriverText = 'Assigned';
      }
      if (source.imagePath) {
        entity.imagePath = source.imagePath;
      }
      if (source.vehicleDisplayId) {
        entity.vehicleDisplayId = source.vehicleDisplayId;
      }
      return { ...source, ...entity };
    },
  },
  [EntitySearchResponseType.YARD]: {
    idField: 'yardId',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.DRIVER_DOCUMENT]: {
    idField: 'driverDocumentId',
  },
  [EntitySearchResponseType.ZUM_EMPLOYEE]: {
    idField: 'zumEmployeeId',
    entityFilterRequestType: undefined,
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.VENDOR_EMPLOYEE]: {
    idField: 'vendorEmployeeId',
  },
  [EntitySearchResponseType.SCHOOL_EMPLOYEE]: {
    idField: 'schoolEmployeeId',
  },
  [EntitySearchResponseType.CHARTER_TRIP]: {
    idField: 'tripId',
  },
  [EntitySearchResponseType.RIDE]: {
    idField: 'rideId',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.ROUTE]: {
    idField: 'routeId',
    entityFilterRequestType: undefined,
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.TRANSPORTATION_REGISTRATION]: {
    idField: 'id',
  },
  [EntitySearchResponseType.END_OF_RIDE_INSPECTION]: {
    idField: 'id',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.END_OF_DAY_INSPECTION]: {
    idField: 'id',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.STUDENT_RFID_ORDER]: {
    idField: 'orderId',
  },
  [EntitySearchResponseType.USER_ACCESS_POLICY]: {
    idField: 'userId',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.DRIVER_BUS_REPORT]: {
    idField: 'driverBusReportId',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.FINANCE_TRANSACTION]: {
    idField: 'id',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.ACTIVITY_TIME_ENTRY]: {
    idField: 'activityId',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.STUDENT_CHANGE_REQUEST]: {
    idField: 'studentChangeRequestId',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.ROUTE_GROUP_CHANGE_REQUEST]: {
    idField: 'changeRequestId',
    crossEntitySearchRequestType: undefined,
    entityFilterRequestType: EntityFilterRequestType.ROUTE_GROUP_CHANGE_REQUEST,
  },
  [EntitySearchResponseType.ROUTE_GROUP]: {
    idField: 'routeGroupId',
  },
  [EntitySearchResponseType.PARKOUT_LOCATION]: {
    idField: 'id',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.PARKOUT_LOCATION_EVENT]: {
    idField: 'id',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.PARKOUT_LOCATION_DRIVER_AUTHORIZATION]: {
    idField: 'id',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.DRIVER_COMMUNICATION]: {
    idField: 'id',
    crossEntitySearchRequestType: undefined,
  },
  [EntitySearchResponseType.MONITOR]: {
    idField: 'monitorId',
    crossEntitySearchRequestType: undefined,
  },
};
