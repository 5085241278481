import { Driver, Yard, RideAssignmentDriver, RideAssignmentVehicle } from '../types';

export const driverToAssignmentDriver = (
  driver: Driver,
  defaultVehicle?: RideAssignmentVehicle,
  yard?: Yard,
): RideAssignmentDriver => {
  return {
    id: driver.id,
    fullName: `${driver.firstName} ${driver.lastName}`,
    imagePath: driver.driverImagePath,
    defaultVehicle,
    vendor: driver.vendorId ? { vendorId: driver.vendorId, yardId: yard?.id } : undefined,
  };
};
