import { GetRouteIssuesByDistrictProgramsResponse } from './index';
import {
  ApiRouteIssueEntity,
  CampusProjection,
  RouteIssueEntityType,
  RouteIssueType,
  StudentProjection,
} from '@apiEntities/route-explorer/route-issues';
import { RouteIssueStudentProjectionMetadata } from '@apiEntities/route-explorer/route-issue-entity-metadata';

const isStudentProjection = (arg: any): arg is RouteIssueStudentProjectionMetadata => {
  return !!arg?.fullName;
};

const routeIssueTypeTypeToTitle = (routeIssueType: RouteIssueType): string => {
  switch (routeIssueType) {
    case RouteIssueType.STUDENT_CAMPUS_ENROLLMENT:
      return 'School enrollment does not match route';
    case RouteIssueType.STUDENT_ADDRESS:
      return 'Stop address does not match route';
    case RouteIssueType.STUDENT_TRANSPORTATION_NON_ELIGIBLE:
      return 'No longer eligible for transportation';
    case RouteIssueType.STUDENT_UNASSIGNED:
      return 'No assigned routes';
    case RouteIssueType.STUDENT_DUPLICATED_ROUTE:
      return 'Duplicate routes';
    case RouteIssueType.STUDENT_MISSING_ROUTE:
      return 'Incomplete routing';
    case RouteIssueType.CAMPUS_BELL_TIME:
      return 'Bell time not in sync with route schedule';
    case RouteIssueType.CAMPUS_BELL_TIME_EXCEPTION:
      return 'Bell time exceptions are not in sync with routes';
    case RouteIssueType.CAMPUS_CALENDAR:
      return 'Routes do not match campus calendar';
    case RouteIssueType.CAMPUS_STOP_ADDRESS:
      return 'Campus stop does not match route';
    default:
      return routeIssueType; // TODO: report a missing mapping
  }
};

export const transformRouteIssueByDistrictProgramsResponseToLegacy = (
  source: GetRouteIssuesByDistrictProgramsResponse,
): GetRouteIssuesByDistrictProgramsResponse => {
  const studentEntities: ApiRouteIssueEntity[] = [];
  const campusEntities: ApiRouteIssueEntity[] = [];

  Object.keys(source.byDistrictProgram).forEach((districtProgramId) => {
    const byDistrictProgram = source.byDistrictProgram[districtProgramId];
    byDistrictProgram.issueEntitiesMetadata.forEach((issueEntity) => {
      let entityProjectionLegacy: StudentProjection | CampusProjection;
      let entityTitle: string;
      if (isStudentProjection(issueEntity.entityProjection)) {
        entityProjectionLegacy = {
          fullName: issueEntity.entityProjection.fullName,
          primaryCampusId: issueEntity.entityProjection.primaryCampusId,
          homeAddresses: [],
          campusAddresses: [],
        };
        entityTitle = issueEntity.entityProjection.fullName;
      } else {
        entityProjectionLegacy = {
          name: issueEntity.entityProjection.name,
          addresses: [],
        };
        entityTitle = issueEntity.entityProjection.name;
      }
      const issueEntityLegacy: ApiRouteIssueEntity = {
        batchId: byDistrictProgram.batchId,
        entityType: issueEntity.entityType,
        entityId: issueEntity.entityId,
        districtProgramId: districtProgramId,
        entity: {
          title: entityTitle,
          description: {},
          views: [],
        },
        entityProjection: entityProjectionLegacy,
        issues: issueEntity.issuesMetadata.map((item) => {
          return {
            title: routeIssueTypeTypeToTitle(item.type),
            type: item.type,
            groups: [],
            affectedRouteIds: item.affectedRouteIds,
          };
        }),
        affectedRouteIds: issueEntity.affectedRouteIds,
      };
      if (issueEntity.entityType === RouteIssueEntityType.STUDENT) {
        studentEntities.push(issueEntityLegacy);
      } else {
        campusEntities.push(issueEntityLegacy);
      }
    });
  });
  return {
    studentEntities,
    campusEntities,
  };
};
