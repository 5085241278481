import { TraceDisposition } from '@rootTypes/entities/ride';
import { checkNotNull } from 'src/app/types/utils';
import { PolylineOptions } from './polyline-options';

export class AnimatedLine {
  private polyline: google.maps.Polyline;
  private initialColor: string;
  private highlightColor = '#2f5d6b';
  private initTimeoutHandle: any;
  private customHoverMarker: any;
  private customClickMarker: any;
  private isHoverDisabled = false;
  private isClickMessageOpen = false;
  private clickMessageListeners: { [id: string]: (isOpen: boolean) => void } = {};
  private viewDriverVideoListeners: { [id: string]: () => void } = {};
  private viewAllVideosListeners: { [id: string]: () => void } = {};

  constructor(
    public id: string,
    private map: google.maps.Map,
    private path: google.maps.LatLng[],
    private polylineOptions: PolylineOptions = {},
    private hoverHTML?: string | (() => string),
    private clickHTML?: string | (() => string),
    public traceDisposition?: TraceDisposition,
    public meta?: any,
  ) {
    checkNotNull(this.map, 'Animated Line: Map should not be null');
    checkNotNull(this.path, 'Animated Line: Path cannot be null');
    this.initialColor = polylineOptions.strokeColor;
    this.draw();
  }

  public hide(): void {
    if (this.initTimeoutHandle) {
      clearTimeout(this.initTimeoutHandle);
    }
    this.polyline.setMap(null);
    if (this.customHoverMarker) {
      this.customHoverMarker.setMap(null);
    }
    if (this.customClickMarker) {
      this.customClickMarker.setMap(null);
    }
  }

  public setHoverDisabled(isDisabled: boolean): void {
    this.isHoverDisabled = isDisabled;
  }

  public getFirstPoint(): google.maps.LatLng {
    if (!this.polyline) {
      return undefined;
    }
    return this.polyline.getPath().getArray()[0];
  }

  public getLastPoint(): google.maps.LatLng {
    if (!this.polyline) {
      return undefined;
    }
    const arr = this.polyline.getPath().getArray();
    return arr[arr.length - 1];
  }

  public show(): void {
    this.polyline.setMap(this.map);
  }

  public onViewAllVideoClicked(listener: () => void): string {
    const id = Math.random().toString();
    this.viewAllVideosListeners[id] = listener;
    return id;
  }

  public onViewDriverVideoClicked(listener: () => void): string {
    const id = Math.random().toString();
    this.viewDriverVideoListeners[id] = listener;
    return id;
  }

  public onClickMessageChange(listener: (isOpen: boolean) => void): string {
    const id = Math.random().toString();
    this.clickMessageListeners[id] = listener;
    return id;
  }

  public closeClickMessage(): void {
    if (this.customClickMarker) {
      this.customClickMarker.setMap(null);
    }
    this.isClickMessageOpen = false;
    this.update(this.initialColor);
    Object.values(this.clickMessageListeners).forEach((listener) => listener(false));
  }

  public removeListenerById(id: string): void {
    delete this.clickMessageListeners[id];
    delete this.viewAllVideosListeners[id];
    delete this.viewDriverVideoListeners[id];
  }

  public setPath(path: google.maps.LatLng[]): void {
    this.path = path;
    this.draw();
  }

  private update(strokeColor?: string): void {
    this.polyline.setOptions({
      ...this.polylineOptions,
      strokeColor,
    });
  }

  private draw(): void {
    if (this.polyline) {
      this.polyline.setMap(null);
    }
    this.polyline = new google.maps.Polyline({
      map: null,
      path: [...this.path],
      ...this.polylineOptions,
    });
    this.polyline.setMap(this.map);
    if (this.hoverHTML) {
      const points = this.polyline.getPath().getArray();
      if (points.length !== 2) {
        console.warn('Cannot display info window for polylines longer than two points');
        return;
      }
      let midPoint: google.maps.LatLng;
      const first = points[0];
      const last = points[1];
      midPoint = google.maps.geometry.spherical.interpolate(first, last, 0.5);
      import('../custom-html-marker').then((cm) => {
        this.polyline.addListener('mouseover', (event) => {
          this.update(this.highlightColor);
          if (this.isHoverDisabled) {
            return;
          }
          if (!this.customHoverMarker) {
            const hoverHTMLStr: string = typeof this.hoverHTML === 'string' ? this.hoverHTML : this.hoverHTML();
            this.customHoverMarker = new cm.CustomHtmlMarker(midPoint, null, hoverHTMLStr, '170px', '80px', 110);
          }
          this.customHoverMarker.setMap(this.map);
        });
        this.polyline.addListener('mouseout', () => {
          if (!this.isClickMessageOpen) {
            this.update(this.initialColor);
          }
          if (this.customHoverMarker) {
            this.customHoverMarker.setMap(null);
          }
        });
        if (this.clickHTML) {
          this.polyline.addListener('click', (event) => {
            this.isClickMessageOpen = true;
            if (!this.customClickMarker) {
              const clickHTMLStr: string = typeof this.clickHTML === 'string' ? this.clickHTML : this.clickHTML();
              this.customClickMarker = new cm.CustomHtmlMarker(
                midPoint,
                null,
                clickHTMLStr,
                '170px',
                '80px',
                110,
                true,
              );
            }
            if (this.customHoverMarker) {
              this.customHoverMarker.setMap(null);
            }
            this.customClickMarker.setMap(this.map);
            Object.values(this.clickMessageListeners).forEach((listener) => listener(true));
            setTimeout(() => {
              this.update(this.highlightColor);
              const closeSelector = `#close-${this.id}`;
              const closeBtn = document.querySelector(closeSelector);
              if (closeBtn) {
                closeBtn.addEventListener('click', () => {
                  this.customClickMarker.setMap(null);
                  this.update(this.initialColor);
                  Object.values(this.clickMessageListeners).forEach((listener) => listener(false));
                  this.isClickMessageOpen = false;
                });
              }
              const allVideosSelector = `#all-${this.id}`;
              const allVideosBtn = document.querySelector(allVideosSelector);
              if (allVideosBtn) {
                allVideosBtn.addEventListener('click', () => {
                  Object.values(this.viewAllVideosListeners).forEach((listener) => listener());
                });
              }
              const driverVideoSelector = `#driver-${this.id}`;
              const driverVideoBtn = document.querySelector(driverVideoSelector);
              if (driverVideoBtn) {
                driverVideoBtn.addEventListener('click', () => {
                  Object.values(this.viewDriverVideoListeners).forEach((listener) => listener());
                });
              }
            }, 900);
          });
        }
      });
    }
  }
}
