import { MapLocation } from '@rootTypes';
import { lightenDarkenColor } from '@rootTypes/utils/common/lighten-darken-color';
import { NearestPointOnPolylinePathNaive } from './nearest-point-on-polyline-path/nearest-point-on-polyline-path-naive';
import IconSequence = google.maps.IconSequence;
import { commonPaths } from './common-paths';
import { isArrayDeepDiff } from '@rootTypes/utils/common/is-array-deep-diff';

export type Polyline<M = any> = {
  id: string;
  path: MapLocation[];
  tooltip?: BasePolylineTooltip;
  tooltips?: BoundPolylineTooltip[];
  strokeColor?: string;
  strokeOpacity: number;
  zIndex: number;
  strokeWeight?: number;
  highlight?: boolean;
  icons?: google.maps.PolylineOptions['icons'];
  metadata?: M;
  clickCallbackFn?: (meta: M) => void;
};

const colorChangeOnHover = -35;

export type BasePolylineTooltip = {
  htmlGetter: () => string;
  width: string;
  height: string;
  zIndex: number;
  followCursor?: boolean;
};

export type BoundPolylineTooltip = BasePolylineTooltip & {
  location: MapLocation;
};

export type PolylineDiff = Partial<Polyline>;

export type PolylineEventType = 'click' | 'mouseover' | 'mouseout';
export type PolylineEvent = {
  type: PolylineEventType;
  polyline: Polyline;
};

export class DrawnPolyline implements Polyline {
  strokeColor: string;
  id: string;
  strokeOpacity: number;
  path: MapLocation[];
  strokeWeight: number;
  zIndex: number;
  icons: google.maps.PolylineOptions['icons'];
  eventListeners: {
    [id: string]: (event: PolylineEvent) => void;
  } = {};

  private gPolyline: google.maps.Polyline;
  private highlightPolyline: google.maps.Polyline;

  constructor(
    private map: google.maps.Map,
    private source: Polyline,
  ) {
    this.initFrom(source);
  }

  public draw(): void {
    if (this.gPolyline) {
      this.gPolyline.setMap(null);
    }

    this.gPolyline = new google.maps.Polyline({
      strokeColor: this.strokeColor,
      strokeOpacity: this.strokeOpacity,
      strokeWeight: this.strokeWeight,
      zIndex: this.zIndex,
      path: this.path.map((s) => new google.maps.LatLng(s.lat, s.lng)),
      map: this.map,
      icons: this.icons,
    });
    if (this.source.highlight) {
      this.highlightPolyline = new google.maps.Polyline({
        path: this.source.path,
        strokeWeight: this.strokeWeight + 2,
        strokeColor: lightenDarkenColor(this.source.strokeColor, colorChangeOnHover),
        zIndex: this.zIndex - 1,
        map: this.map,
      });
    }
    if (this.source.clickCallbackFn) {
      this.gPolyline.addListener('click', () => {
        this.source.clickCallbackFn(this.source.metadata);
      });
    }
    if (this.source.tooltip && this.source.path.length > 1) {
      let midPoint: google.maps.LatLng;
      const midInd = Math.floor(this.source.path.length / 2);
      const first = this.source.path[midInd];
      const last = this.source.path[midInd + 1];
      midPoint = google.maps.geometry.spherical.interpolate(
        new google.maps.LatLng(first.lat, first.lng),
        new google.maps.LatLng(last.lat, last.lng),
        0.5,
      );
      import('./custom-html-marker').then((cm) => {
        let hoverCustomHTMLMarker: any;
        this.gPolyline.addListener('mouseover', (event) => {
          const anchor = this.source.tooltip.followCursor ? event.latLng : midPoint;
          if (!hoverCustomHTMLMarker || this.source.tooltip.followCursor) {
            hoverCustomHTMLMarker = new cm.CustomHtmlMarker(
              anchor,
              null,
              this.source.tooltip.htmlGetter(),
              this.source.tooltip.width || '170px',
              this.source.tooltip.height || '80px',
              this.source.tooltip.zIndex || this.source.zIndex + 1,
            );
          }
          hoverCustomHTMLMarker.setMap(this.map);
          this.gPolyline.set('strokeColor', lightenDarkenColor(this.strokeColor, colorChangeOnHover));
          this.gPolyline.set('zIndex', 2000);
          if (this.source.clickCallbackFn) {
            // add onclick event to the tooltip
            google.maps.event.addListener(hoverCustomHTMLMarker, 'click', () => {
              this.source.clickCallbackFn(this.source.metadata);
            });
          }
        });
        this.gPolyline.addListener('mouseout', () => {
          if (hoverCustomHTMLMarker) {
            hoverCustomHTMLMarker.setMap(null);
          }
          this.gPolyline.set('strokeColor', this.strokeColor);
          this.gPolyline.set('zIndex', this.zIndex);
        });
      });
    }
    if (this.source.tooltips && this.source.tooltips.length) {
      import('./custom-html-marker').then((cm) => {
        let hoverCustomHTMLMarker: any;
        let hoverPolyline: google.maps.Polyline;
        let hoverBGPolyline: google.maps.Polyline;
        const tooltipsMap = this.source.tooltips.reduce((acc, tooltip) => {
          acc['' + tooltip.location.lat + tooltip.location.lng + this.source.id] = tooltip;
          return acc;
        }, {});
        const nearestPointFinder = new NearestPointOnPolylinePathNaive(this.source.path);
        let closeTimeout: any;
        this.gPolyline.addListener('mouseout', () => {
          this.emitEvent({ type: 'mouseover', polyline: this.source });
        });
        this.gPolyline.addListener('mousemove', (event) => {
          if (closeTimeout) {
            clearTimeout(closeTimeout);
            closeTimeout = null;
          }
          if (hoverCustomHTMLMarker) {
            hoverCustomHTMLMarker.setMap(null);
          }
          if (hoverPolyline) {
            hoverPolyline.setMap(null);
          }
          const nearestPoint = nearestPointFinder.findNearestPoints({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          });
          if (nearestPoint && nearestPoint.start) {
            const tooltipId = '' + nearestPoint.start.lat + nearestPoint.start.lng + this.source.id;
            const tooltip = tooltipsMap[tooltipId];
            if (tooltip) {
              hoverCustomHTMLMarker = new cm.CustomHtmlMarker(
                event.latLng as google.maps.LatLng,
                null,
                tooltip.htmlGetter(),
                tooltip.width || '170px',
                tooltip.height || '80px',
                this.zIndex + 2,
              );
              hoverCustomHTMLMarker.setMap(this.map);
              hoverPolyline = new google.maps.Polyline({
                strokeOpacity: this.strokeOpacity,
                strokeWeight: this.strokeWeight,
                strokeColor: lightenDarkenColor(this.source.strokeColor, colorChangeOnHover),
                zIndex: this.zIndex + 1,
                path: [nearestPoint.start, nearestPoint.end],
                map: this.map,
              });
              hoverPolyline.addListener('mouseover', () => {
                if (closeTimeout) {
                  clearTimeout(closeTimeout);
                  closeTimeout = null;
                }
              });
              hoverPolyline.addListener('mouseout', () => {
                closeTimeout = setTimeout(() => {
                  if (hoverCustomHTMLMarker) {
                    hoverCustomHTMLMarker.setMap(null);
                  }
                  if (hoverPolyline) {
                    hoverPolyline.setMap(null);
                  }
                  if (hoverBGPolyline) {
                    hoverBGPolyline.setMap(null);
                  }
                  this.emitEvent({ type: 'mouseout', polyline: this.source });
                }, 200);
              });
            }
          }
        });
        this.gPolyline.addListener('mouseout', () => {
          closeTimeout = setTimeout(() => {
            if (hoverCustomHTMLMarker) {
              hoverCustomHTMLMarker.setMap(null);
            }
            if (hoverPolyline) {
              hoverPolyline.setMap(null);
            }
            if (hoverBGPolyline) {
              hoverBGPolyline.setMap(null);
            }
            this.gPolyline.setOptions({ zIndex: this.source.zIndex });
            this.emitEvent({ type: 'mouseout', polyline: this.source });
          }, 200);
        });
      });
    }
  }

  public subscribeToEvents(cb: (event: PolylineEvent) => void): string {
    const id = '' + Math.random();
    this.eventListeners[id] = cb;
    return id;
  }

  public unsubscribeFromEvents(id: string): void {
    delete this.eventListeners[id];
  }

  public unsubscribeAllFromEvents(): void {
    this.eventListeners = {};
  }

  public merge(poly: Polyline): void {
    const diffs = this.getPolylineDiffs(this, poly, false);
    const polylineOptions: google.maps.PolylineOptions = {
      ...diffs,
    };
    if (diffs.path) {
      polylineOptions.path = diffs.path.map((s) => new google.maps.LatLng(s.lat, s.lng));
    }
    this.gPolyline.setOptions(polylineOptions);
    if (poly.highlight && !this.highlightPolyline) {
      this.highlightPolyline = new google.maps.Polyline({
        path: this.source.path,
        strokeWeight: this.strokeWeight + 2,
        strokeColor: lightenDarkenColor(this.source.strokeColor, colorChangeOnHover),
        zIndex: poly.zIndex - 1,
        map: this.map,
      });
    }
    if (!poly.highlight && this.highlightPolyline) {
      this.highlightPolyline.setMap(null);
      this.highlightPolyline = null;
    }
    if (diffs.zIndex !== undefined) {
      if (this.highlightPolyline) {
        this.highlightPolyline.setOptions({ zIndex: diffs.zIndex - 1 });
      }
    }
    this.initFrom(poly);
  }

  public remove(): void {
    if (this.gPolyline) {
      this.gPolyline.setMap(null);
    }
    if (this.highlightPolyline) {
      this.highlightPolyline.setMap(null);
    }
  }

  public isDifferentFrom(poly: Polyline): boolean {
    const diffs = this.getPolylineDiffs(this, poly, true);
    return !!Object.keys(diffs).length || poly.highlight !== this.source.highlight;
  }

  private initFrom(source: Polyline): void {
    this.id = source.id;
    this.strokeColor = source.strokeColor;
    this.strokeOpacity = source.strokeOpacity;
    this.path = [...(source.path || [])];
    this.strokeWeight = source.strokeWeight;
    this.zIndex = source.zIndex;
    this.icons = source.icons;
  }

  private getPolylineDiffs(source: Polyline, target: Polyline, returnOnFirstDiff: boolean): PolylineDiff {
    const diff: PolylineDiff = {};
    if (source.zIndex !== target.zIndex) {
      diff.zIndex = target.zIndex;
      if (returnOnFirstDiff) {
        return diff;
      }
    }
    if (source.strokeWeight !== target.strokeWeight) {
      diff.strokeWeight = target.strokeWeight;
      if (returnOnFirstDiff) {
        return diff;
      }
    }
    if (source.strokeColor !== target.strokeColor) {
      diff.strokeColor = target.strokeColor;
      if (returnOnFirstDiff) {
        return diff;
      }
    }
    if (source.strokeOpacity !== target.strokeOpacity) {
      diff.strokeOpacity = target.strokeOpacity;
      if (returnOnFirstDiff) {
        return diff;
      }
    }
    if (this.isDifferentPaths(source.path, target.path)) {
      diff.path = target.path;
    }
    if (this.isDifferentIcons(source.icons, target.icons)) {
      diff.icons = target.icons;
    }
    return diff;
  }

  private isDifferentPaths(path1: MapLocation[], path2: MapLocation[]): boolean {
    return isArrayDeepDiff(path1, path2, (loc1, loc2) => {
      return loc1.lat !== loc2.lat || loc1.lng !== loc2.lng;
    });
  }

  private isDifferentIcons(
    icons1: google.maps.PolylineOptions['icons'],
    icons2: google.maps.PolylineOptions['icons'],
  ): boolean {
    return isArrayDeepDiff(icons1, icons2, (a, b) => {
      return (
        a.icon?.path !== b.icon?.path ||
        a.icon?.scale !== b.icon?.scale ||
        a.icon?.strokeColor !== b.icon?.strokeColor ||
        a.icon?.strokeWeight !== b.icon?.strokeWeight ||
        a.icon?.strokeOpacity !== b.icon?.strokeOpacity ||
        a.icon?.fillColor !== b.icon?.fillColor ||
        a.icon?.fillOpacity !== b.icon?.fillOpacity ||
        a.offset !== b.offset ||
        a.repeat !== b.repeat
      );
    });
  }

  private emitEvent(event: PolylineEvent): void {
    Object.values(this.eventListeners).forEach((cb) => cb(event));
  }
}

interface PolylineBatchDiff {
  added: Polyline[];
  removed: string[];
  changed: Polyline[];
  newCompareObj: PolylineCompareObj;
}

type PolylineCompareObj = {
  [id: string]: Polyline | DrawnPolyline;
};

export class PolylineDrawer {
  private previous: DrawnPolyline[] = [];
  private listeners: { [id: string]: (event: PolylineEvent) => void } = {};

  constructor(private map: google.maps.Map) {}

  public setPolylines(polylines: Polyline[]): void {
    const { added, removed, changed, newCompareObj } = this.getPolylineBatchDiff(polylines);
    if (removed && removed.length) {
      removed.forEach((removedId) => {
        const removedIndex = this.previous.findIndex((p) => p.id === removedId);
        const removedPoly = this.previous[removedIndex];
        removedPoly.remove();
        removedPoly.unsubscribeAllFromEvents();
        this.previous.splice(removedIndex, 1);
      });
    }
    if (added && added.length) {
      added.forEach((addedPoly) => {
        const drawnPoly = new DrawnPolyline(this.map, addedPoly);
        drawnPoly.subscribeToEvents((event) => this.emitEvent(event));
        drawnPoly.draw();
        this.previous.push(drawnPoly);
      });
    }
    if (changed && changed.length) {
      changed.forEach((changedPoly) => {
        const sourcePoly = this.previous.find((s) => s.id === changedPoly.id);
        if (sourcePoly) {
          sourcePoly.merge(changedPoly);
        }
      });
    }
  }

  public subscribeToEvents(cb: (event: PolylineEvent) => void): string {
    const id = '' + Math.random();
    this.listeners[id] = cb;
    return id;
  }

  public unsubscribeFromEvents(id: string): void {
    delete this.listeners[id];
  }

  public unsubscribeAllFromEvents(): void {
    this.listeners = {};
  }

  private getPolylineBatchDiff(polylines: Polyline[]): PolylineBatchDiff {
    const previousCompareObj = this.polylinesToCompareObject(this.previous);
    const added: Polyline[] = [];
    const changed: Polyline[] = [];
    polylines.forEach((targetPoly) => {
      const previousPoly: DrawnPolyline = previousCompareObj[targetPoly.id] as DrawnPolyline;
      if (previousPoly) {
        const isChanged = previousPoly.isDifferentFrom(targetPoly);
        if (isChanged) {
          changed.push(targetPoly);
        }
      } else {
        added.push(targetPoly);
      }
    });
    const removed: string[] = [];
    const newCompareObj = this.polylinesToCompareObject(polylines);
    this.previous.forEach((prevPoly) => {
      if (!newCompareObj[prevPoly.id]) {
        removed.push(prevPoly.id);
      }
    });
    return {
      added,
      changed,
      removed,
      newCompareObj,
    };
  }

  private polylinesToCompareObject(polylines: Polyline[]): PolylineCompareObj {
    return polylines.reduce((prev, curr) => {
      return { ...prev, [curr.id]: curr };
    }, {});
  }

  private emitEvent(event: PolylineEvent): void {
    Object.values(this.listeners).forEach((cb) => cb(event));
  }
}

export const getPolylineNumberIconSequence = (
  n: number,
  strokeColor: string,
  repeat: string,
  offset: string,
): IconSequence[] => {
  const stringNumber = '' + n;
  const icons: IconSequence[] = [
    {
      icon: {
        path: commonPaths.CIRCLE,
        scale: 0.5,
        strokeColor,
        fillColor: 'white',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeOpacity: 1,
        anchor: new google.maps.Point(-2, -5),
      },
      fixedRotation: true,
      repeat: '100px',
      offset: '50%',
    },
  ];
  for (let i = 0; i < stringNumber.length; i++) {
    const digit = stringNumber[i];
    const anchorX = digit === '1' ? 1 : 2;
    const anchorY = digit === '1' ? 2 : 3;
    icons.push({
      icon: {
        path: commonPaths['NUMBER_' + digit],
        fillColor: strokeColor,
        fillOpacity: 1,
        strokeColor,
        scale: 0.7,
        anchor: new google.maps.Point(anchorX, anchorY),
      },
      fixedRotation: true,
      repeat,
      offset,
    });
  }
  return icons;
};
