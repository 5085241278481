import { createAction, props } from '@ngrx/store';
import { EvStatus } from '@rootTypes/entities';
import * as fromTypes from '../../types';

const moduleName = 'Data';
const submoduleName = 'Vehicles';

export const loadVehicleRequested = createAction(
  `[${moduleName}] [${submoduleName}]: Load vehicle requested`,
  props<{ vehicleId: string; options?: fromTypes.LoadVehicleOptions }>(),
);

export const loadVehicleSuccess = createAction(
  `[${moduleName}] [${submoduleName}]: Load vehicle success`,
  props<{
    vehicle: fromTypes.Vehicle;
    evStatus?: EvStatus;
    driver?: fromTypes.Driver;
    options?: fromTypes.LoadVehicleOptions;
  }>(),
);

export const loadVehicleFailed = createAction(
  `[${moduleName}] [${submoduleName}]: Load vehicle failed`,
  props<{ vehicleId: string; error: fromTypes.WpError }>(),
);

export const updateVehicleSuccess = createAction(
  '[EditVehicle]: Update vehicle success',
  props<{ vehicleId: string; vehicle: fromTypes.Vehicle; evStatus?: EvStatus }>(),
);
