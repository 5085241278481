import { Injectable } from '@angular/core';
import { VehicleStatus } from '@apiEntities/vehicle';
import { Store } from '@ngrx/store';
import { EvStatus } from '@rootTypes/entities';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Check, Vehicle, WpError, Driver, VendorVehicleIds } from '../../types';
import { loadVehicleRequested } from '../actions';
import {
  getVehicle,
  getVehicleError,
  getVehicleErrorMessage,
  getVehicleLoading,
  vehicleBackgroundCheckCodes,
  vehicleBackgroundCheckNames,
  vehicleBackgroundChecks,
  vehicleColor,
  vehicleDisplayName,
  vehicleImagePath,
  vehicleInsuranceExpDate,
  vehicleLicenseName,
  vehicleRegistrationExpDate,
  vehicleSeatingCapacity,
  vehicleYear,
  getDriverByVehicle,
  getVehicleEntity,
  vehicleDisplayId,
  vehicleComments,
  isVendorVehicle,
  isVehicleInitiallyLoading,
  getVIN,
  getVendorVehicleIds,
  vehicleStatus,
  getEvStatusByVehicle,
} from '../selectors';

@Injectable()
export class VehicleDataFacade {
  constructor(private store: Store) {}

  public loadVehicle(vehicleId: string): void {
    this.store.dispatch(loadVehicleRequested({ vehicleId }));
  }

  public loadVehicleLazily(vehicleId: string): void {
    this.store
      .select(getVehicleEntity, { vehicleId })
      .pipe(take(1))
      .subscribe((state) => {
        if (!state) {
          return this.loadVehicle(vehicleId);
        }
        if (!state.isLoading && !state.entity && !state.error) {
          this.loadVehicle(vehicleId);
        }
      });
  }

  public getVehicle(vehicleId: string): Observable<Vehicle> {
    return this.store.select(getVehicle, { vehicleId });
  }

  public getDriverByVehicle(vehicleId: string): Observable<Driver> {
    return this.store.select(getDriverByVehicle, { vehicleId });
  }

  public getEvStatusByVehicle(vehicleId: string): Observable<EvStatus> {
    return this.store.select(getEvStatusByVehicle, { vehicleId });
  }

  public getVehicleLoading(vehicleId: string): Observable<boolean> {
    return this.store.select(getVehicleLoading, { vehicleId });
  }

  public isVehicleInitiallyLoading(vehicleId: string): Observable<boolean> {
    return this.store.select(isVehicleInitiallyLoading, { vehicleId });
  }

  public getVehicleError(vehicleId: string): Observable<WpError> {
    return this.store.select(getVehicleError, { vehicleId });
  }

  public getVehicleErrorMessage(vehicleId: string): Observable<string> {
    return this.store.select(getVehicleErrorMessage, { vehicleId });
  }

  public getDisplayName(vehicleId: string): Observable<string> {
    return this.store.select(vehicleDisplayName, { vehicleId });
  }

  public getDisplayId(vehicleId: string): Observable<string> {
    return this.store.select(vehicleDisplayId, { vehicleId });
  }

  public getStatus(vehicleId: string): Observable<VehicleStatus> {
    return this.store.select(vehicleStatus, { vehicleId });
  }

  public getVIN(vehicleId: string): Observable<string> {
    return this.store.select(getVIN, { vehicleId });
  }

  public getLicenseName(vehicleId: string): Observable<string> {
    return this.store.select(vehicleLicenseName, { vehicleId });
  }

  public getColor(vehicleId: string): Observable<string> {
    return this.store.select(vehicleColor, { vehicleId });
  }

  public getImagePath(vehicleId: string): Observable<string> {
    return this.store.select(vehicleImagePath, { vehicleId });
  }

  public getSeatingCapacity(vehicleId: string): Observable<number> {
    return this.store.select(vehicleSeatingCapacity, { vehicleId });
  }

  public getRegistrationExpDate(vehicleId: string): Observable<Date> {
    return this.store.select(vehicleRegistrationExpDate, { vehicleId });
  }

  public getInsuranceExpDate(vehicleId: string): Observable<Date> {
    return this.store.select(vehicleInsuranceExpDate, { vehicleId });
  }

  public getBackgroundChecks(vehicleId: string): Observable<Check[]> {
    return this.store.select(vehicleBackgroundChecks, { vehicleId });
  }

  public getBackgroundCheckCodes(vehicleId: string): Observable<string[]> {
    return this.store.select(vehicleBackgroundCheckCodes, { vehicleId });
  }

  public getBackgroundCheckNames(vehicleId: string): Observable<string[]> {
    return this.store.select(vehicleBackgroundCheckNames, { vehicleId });
  }

  public getYear(vehicleId: string): Observable<number> {
    return this.store.select(vehicleYear, { vehicleId });
  }

  public getComments(vehicleId: string): Observable<string> {
    return this.store.select(vehicleComments, { vehicleId });
  }

  public isVendorVehicle(vehicleId: string): Observable<boolean> {
    return this.store.select(isVendorVehicle, { vehicleId }).pipe(filter((value) => typeof value === 'boolean'));
  }

  public getVendorVehicleIds(vehicleId: string): Observable<VendorVehicleIds> {
    return this.store.select(getVendorVehicleIds, { vehicleId });
  }
}
