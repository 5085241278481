<div tabindex="0" style="outline: none">
  <wp-dropdown
    [triggerTemplate]="triggerTemplate"
    [dropdownTemplate]="dropdownTemplate"
    [alignDropdown]="alignDropdown"
    [dropdownVerticalOffset]="'calc(100% - 23px)'"
    (closedDropdown)="onCloseDropdown()"
    #dropdownEl
  ></wp-dropdown>
</div>

<ng-template #triggerTemplate>
  <div class="wrap">
    <div
      #maskEl
      class="mask"
      (click)="onTriggerClick($event)"
      [ngClass]="{ disabled: isDisabled$ | async }"
    ></div>
    <div class="focus-underline"></div>
    <div [ngClass]="{ highlighted: highlighted$ | async }">
      <wp-input
        [label]="label"
        [control]="displayDateControl"
        [tabIndex]="'0'"
        [readOnly]="true"
      >
        <wp-input-suffix *ngIf="isIcon">
          <wp-icon-calendar></wp-icon-calendar>
        </wp-input-suffix>
      </wp-input>
    </div>
  </div>
</ng-template>

<ng-template #dropdownTemplate>
  <wp-dropdown-pane>
    <div class="datepicker-wrap" (click)="$event.stopPropagation()">
      <wp-date-range-picker
        [selected]="selectedDates"
        [disableDatesBefore]="disableDatesBefore"
        [disableDatesAfter]="disableDatesAfter"
        (selectedChanged)="onValueChangedByUserAction($event)"
      ></wp-date-range-picker>
      <div class="btns-row">
        <div class="btn">
          <wp-btn-secondary
            (clicked)="onClearDatesClicked()"
            [width]="'120px'"
            [height]="'42px'"
          >
            Clear dates
          </wp-btn-secondary>
        </div>
        <div class="btn">
          <wp-btn-primary
            (clicked)="onApplyClicked()"
            [width]="'120px'"
            [height]="'42px'"
          >
            Apply
          </wp-btn-primary>
        </div>
      </div>
    </div>
  </wp-dropdown-pane>
</ng-template>
