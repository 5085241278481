import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import * as fromTypes from '../../types';
import * as fromActions from '../actions/driver-data.actions';
import * as fromSelectors from '../selectors/driver-data.selectors';
import { DriverStatus, DriverType } from '../../types';
import { StandbyDriverStatus } from '@apiEntities';

@Injectable()
export class DriverDataFacade {
  constructor(private store: Store) {}

  public loadDriver(driverId: string, options: fromTypes.LoadDriverOptions = {}): void {
    this.store.dispatch(fromActions.loadDriverRequested({ driverId, options }));
  }

  public onGetDriverSecuritySettings(driverId: string): void {
    this.store.dispatch(fromActions.getSecuritySettingsForDriverRequested({ driverId }));
  }

  public loadDriverLazily(driverId: string): void {
    this.getDriverEntityState(driverId)
      .pipe(take(1))
      .subscribe((driverState) => {
        if (!driverState) {
          return this.loadDriver(driverId);
        }
        if (!driverState.isLoading && !driverState.entity && !driverState.error) {
          return this.loadDriver(driverId);
        }
      });
  }

  public getDriver(driverId: string): Observable<fromTypes.Driver> {
    return this.store.select(fromSelectors.getDriver, { driverId });
  }

  // Ignores the cached value if new request is in progress
  public getDriverLoaded(driverId: string): Observable<fromTypes.Driver> {
    return combineLatest([this.getDriver(driverId), this.getDriverLoading(driverId)]).pipe(
      filter(([driver, isLoading]) => !!driver && !isLoading),
      map(([driver]) => driver),
    );
  }

  // Emits value if driver is not loading
  public getDriverStable(driverId: string): Observable<fromTypes.Driver | undefined> {
    return combineLatest([this.getDriver(driverId), this.getDriverLoading(driverId)]).pipe(
      filter(([driver, isLoading]) => !isLoading),
      map(([driver]) => driver),
    );
  }

  public getDriverEntityState(driverId: string): Observable<fromTypes.EntityState<fromTypes.Driver>> | undefined {
    return this.store.select(fromSelectors.getDriverEntityState, { driverId });
  }

  public getDriverEntityStateLoading(driverId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getDriverEntityStateLoading, { driverId });
  }

  public getDriverLoading(driverId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getDriverLoading, { driverId });
  }

  public getDriverError(driverId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getDriverError, { driverId });
  }

  public getDriverErrorMessage(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.getDriverErrorMessage, { driverId });
  }

  public getFullName(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.driverFullName, { driverId });
  }

  public getFirstName(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.driverFirstName, { driverId });
  }

  public getLastName(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.driverLastName, { driverId });
  }

  public getEmail(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.driverEmail, { driverId });
  }

  public getPhoneNumber(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.driverPhoneNumber, { driverId });
  }

  public getPhotoPath(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.driverPhotoPath, { driverId });
  }

  public getStatus(driverId: string): Observable<DriverStatus> {
    return this.store.select(fromSelectors.driverStatus, { driverId });
  }

  public getStatusDisplay(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.driverStatusDisplay, { driverId });
  }

  public isDriverDeleted(driverId: string): Observable<boolean> {
    return this.store.select(fromSelectors.isDriverDeleted, { driverId });
  }

  public getRating(driverId: string): Observable<number> {
    return this.store.select(fromSelectors.getDriverRating, { driverId });
  }

  public getRatingDisplay(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.getDriverRatingDisplay, { driverId });
  }

  public getDriverType(driverId: string): Observable<DriverType> {
    return this.store.select(fromSelectors.driverType, { driverId });
  }

  public isVendorDriver(driverId: string): Observable<boolean> {
    return this.store.select(fromSelectors.isVendorDriver, { driverId });
  }

  public getGenderDisplay(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.driverGender, { driverId });
  }

  public getSeniority(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.getDriverSeniority(driverId));
  }

  public getExternalEmployeeId(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.driverExternalEmployeeId, { driverId });
  }

  public getLicenseExpirationDate(driverId: string): Observable<Date> {
    return this.store.select(fromSelectors.driverLicenseExpDate, { driverId });
  }

  public getLicenseType(driverId: string): Observable<fromTypes.LicenseType> {
    return this.store.select(fromSelectors.driverLicenseType, { driverId });
  }

  public getDriverVehicleId(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.getDriverVehicleId, { driverId });
  }

  public isDriverVehicle(driverId: string): Observable<boolean> {
    return this.store.select(fromSelectors.isDriverVehicle, { driverId });
  }

  public getDriverVendorId(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.getDriverVendorId, { driverId });
  }

  public getDriverYardId(driverId: string): Observable<string> {
    return this.store.select(fromSelectors.getDriverYardId, { driverId });
  }

  public getDriverSecuritySettings$(driverId: string): Observable<fromTypes.DriverLoginSetting[]> {
    return this.store.select(fromSelectors.getDriverSecuritySettings, { driverId });
  }

  public getDriverSecuritySettingsLoading$(driverId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getDriverSecuritySettingsLoading, { driverId });
  }

  public getDriverSecuritySettingsError$(driverId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getDriverSecuritySettingsError, { driverId });
  }

  public getStandbyStatus(driverId: string): Observable<StandbyDriverStatus> {
    return this.store.select(fromSelectors.getStandbyStatusByDriver(driverId));
  }

  public onDraftDriverDeleted(driverId: string): void {
    this.store.dispatch(fromActions.deleteDraftDriverSuccess({ driverId }));
  }
}
