import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Options, GetLegendFunction } from 'ngx-slider-v2';
import { defaultOptions, RangeValue } from './range-select';

@Component({
  selector: 'wp-range-select',
  templateUrl: './range-select.component.html',
  styleUrls: ['./range-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeSelectComponent implements OnInit {
  @Input() public selected: RangeValue;
  @Input() public floor: number;
  @Input() public ceil: number;
  @Input() public step: number;
  @Input() public showTicks: boolean;
  @Input() public getLegendFn: GetLegendFunction;
  @Input() public colorLow: string;
  @Input() public colorHigh: string;
  @Input() public hidePointerLabels: boolean;
  @Input() public hideLimitLabels: boolean;
  @Output() public selectedChanged = new EventEmitter<RangeValue>();

  public options: Options;

  public currentValueFrom: number;
  public currentValueTo: number;

  constructor() {}

  ngOnInit(): void {
    this.initOptions();
    this.initValue();
  }

  public emit(): void {
    this.selectedChanged.emit({
      from: this.currentValueFrom,
      to: this.currentValueTo,
    });
  }

  private initOptions(): void {
    if (this.ceil < this.floor) {
      throw new Error('Range Select: ceil cannot be less than floor');
    }
    this.options = {} as Options;
    this.options.hidePointerLabels = this.hidePointerLabels ?? defaultOptions.hidePointerLabels;
    this.options.hideLimitLabels = this.hideLimitLabels ?? defaultOptions.hideLimitLabels;
    this.options.showTicksValues = false;
    this.options.floor = this.floor ?? defaultOptions.floor;
    this.options.ceil = this.ceil ?? defaultOptions.ceil;
    this.options.step = this.step ?? defaultOptions.step;
    this.options.showTicks = this.showTicks ?? defaultOptions.showTicks;
    this.options.animate = false;
    this.options.getLegend = this.getLegendFn ?? defaultOptions.getLegend;
  }

  private initValue(): void {
    this.currentValueFrom = this.selected?.from ?? this.options.floor;
    this.currentValueTo = this.selected?.to ?? this.options.ceil;
  }
}
