import { RouterOutlets } from '@rootTypes/entities/router';
import { NavigationRequest } from '../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../types/portal-route';
import { PortalRouteConfig } from '../types/portal-route-config';
import { RouterStateUrl } from '../types/router-state-url';

export function createPortalRoute<T extends Record<string, string>>(config: PortalRouteConfig<T>): PortalRoute<T> {
  const path = config.path;
  const prefix = config.prefix;

  const outlet = config.outlet !== RouterOutlets.PRIMARY ? config.outlet : undefined;

  const parse = (state) => {
    if (config.parse) {
      return config.parse(state);
    }

    return {
      ...(outlet ? state[outlet].params : state.params),
      ...state.queryParams,
    };
  };

  const extractParams = config.extractParams;
  const extractQueryParams = config.extractQueryParams;

  const composePath = (data: T): string => {
    const template = [outlet ? `_/${outlet}` : ' ', prefix, path]
      .filter((segment) => !!segment)
      .join('/')
      .trim();

    return Object.entries({ ...(extractParams?.(data) ?? data) }).reduce((accumulator, [param, value]) => {
      return accumulator.replace(`:${param}`, value);
    }, template);
  };

  const request = (
    data: T,
    queryParamsHandling: QueryParamsHandlingStrategy,
    replaceUrl: boolean,
  ): NavigationRequest => ({
    path: composePath(data).split('/'),

    extras: {
      queryParams: extractQueryParams?.(data),
      queryParamsHandling,
      replaceUrl,
    },
  });

  const isOnRoute = (state: RouterStateUrl): boolean => {
    const data = parse(state);
    const path = composePath(data);

    return outlet ? state[outlet].url === path : state.url.startsWith(path);
  };

  return {
    path,
    outlet,

    parse,
    request,

    isOnRoute,
  };
}
