import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import * as fromTypes from './types';
import { YYYYMMDDString } from './types';
import { RangePickerDay } from './types/entities/range-picker-day';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { getWeekSpanForDate } from '@rootTypes/utils/common/date/get-current-week-span';
import {
  dateAddDay,
  dateAddMonth,
  dateAddWeek,
  dateAddYear,
  dateClone,
  dateEndOfWeek,
  dateFormat,
  dateIsAfter,
  dateIsBefore,
  datesGetSpan,
  datesIsSame,
  dateSubtractDay,
  dateSubtractMonth,
  dateSubtractWeek,
  dateSubtractYear,
  dateToYYYYMMDD,
  FormattingTokens,
  yyyymmddIsAfter,
  yyyymmddIsBefore,
  yyyymmddStartOfWeek,
  yyyymmddToDate,
} from '@rootTypes/utils/common/date-time-fns';

@Injectable()
export class DateRangePickerService implements fromTypes.RangePickerStore {
  private hoveredDates$!: Observable<{ [dayId: string]: YYYYMMDDString }>;
  private selectedDatesObj$: Observable<{ [dateId: string]: YYYYMMDDString }>;
  private dayConfigs$: Observable<fromTypes.DayConfigs>;
  private pickerConfig$: Observable<fromTypes.RangePickerConfig>;

  private state$: BehaviorSubject<fromTypes.RangePickerState> = new BehaviorSubject<fromTypes.RangePickerState>(
    fromTypes.initialRangePickerStore,
  );

  constructor() {
    this.hoveredDates$ = this.state$.asObservable().pipe(
      map((state) => state.hovered),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.dates),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.selectedDatesObj$ = this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged(
        (prev: fromTypes.RangePickerState['selected'], curr: fromTypes.RangePickerState['selected']) =>
          prev.updatedAt === curr.updatedAt,
      ),
      map((s) => s.dates),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.dayConfigs$ = this.state$.asObservable().pipe(
      map((state) => state.dayConfigs),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.pickerConfig$ = this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    // this.state$.asObservable().subscribe((val) => {
    //   console.log('Store value:');
    //   console.log(val);
    // });
  }
  setDayConfigs(dayConfigs: fromTypes.DayConfigs): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      dayConfigs: {
        entity: dayConfigs,
        updatedAt: new Date().getTime(),
      },
    });
  }
  getConfigs(): Observable<fromTypes.RangePickerConfig> {
    return this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity),
    );
  }
  setIsMouseDown(isDown: boolean): void {
    this.state$.next({
      ...this.state$.value,
      isMouseDown: isDown,
    });
  }
  setDisableAfterDate(date: YYYYMMDDString): void {
    const prevState = this.state$.value;
    const disableAfter = date;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          disableDatesAfter: disableAfter,
        },
        updatedAt: new Date().getTime(),
      },
    });
  }
  setDisableBeforeDate(date: YYYYMMDDString): void {
    const prevState = this.state$.value;
    const disableBefore = date;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          disableDatesBefore: disableBefore,
        },
        updatedAt: new Date().getTime(),
      },
    });
  }
  setIsSingleSelect(value: boolean): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          isSingleSelect: value,
        },
      },
    });
  }
  setIsDeselectOnClick(value: boolean): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        updatedAt: new Date().getTime(),
        entity: {
          ...prevState.config.entity,
          isDeselectOnClick: value,
        },
      },
    });
  }

  setWeekSelectCount(value: number): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          weekSelectCount: value,
        },
      },
    });
  }
  setIsStrictDisableWeek(value: boolean): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          isStrictDisableWeek: value,
        },
      },
    });
  }
  setIsStartWeekFromMonday(value: boolean): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      config: {
        ...prevState.config,
        entity: {
          ...prevState.config.entity,
          isStartWeekFromMonday: value,
        },
      },
    });
  }
  setSelectedDates(mm: YYYYMMDDString[]): void {
    let newSelectedObj: { [dateId: string]: YYYYMMDDString };
    if (mm) {
      const { weekSelectCount } = this.state$.value.config.entity;
      newSelectedObj = mm.reduce((prev, curr) => {
        const currMap = {};
        // if weekly select, select whole week of the moment
        if (weekSelectCount) {
          const week = this.getMomentsForWeek(curr, 1);
          week.forEach((m) => {
            currMap[dateToYYYYMMDD(m)] = dateToYYYYMMDD(m);
          });
        } else {
          currMap[curr] = curr;
        }
        return { ...prev, ...currMap };
      }, {});
    } else {
      newSelectedObj = {};
    }
    const state = this.state$.value;
    this.state$.next({
      ...state,
      selected: {
        ...state.selected,
        dates: { ...newSelectedObj },
        updatedAt: new Date().getTime(),
      },
    });
  }

  onYearClicked(year: fromTypes.RangePickerYear): void {
    const prev = this.state$.value;
    this.state$.next({
      ...prev,
      currentView: {
        ...prev.currentView,
        viewType: fromTypes.ViewType.DAY,
        date: year.moment,
        updatedAt: new Date().getTime(),
      },
    });
  }

  selectedChanged$(): Observable<YYYYMMDDString[]> {
    return this.selectedDatesObj$.pipe(map((dateObj) => Object.keys(dateObj).map((id) => dateObj[id])));
  }

  selectedChangedByUserAction$(): Observable<YYYYMMDDString[]> {
    return this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged((prev, curr) => prev.userUpdatedAt === curr.userUpdatedAt),
      map((state) => state.dates),
      map((dateObj) => Object.values(dateObj)),
    );
  }

  getCurrentViewType$(): Observable<fromTypes.ViewType> {
    return this.state$.asObservable().pipe(
      map((state) => state.currentView.viewType),
      distinctUntilChanged(),
    );
  }

  setViewType(viewType: fromTypes.ViewType): void {
    const prev = this.state$.value;
    this.state$.next({
      ...prev,
      currentView: {
        ...prev.currentView,
        yearViewDate: prev.currentView.date,
        viewType,
        updatedAt: new Date().getTime(),
      },
    });
  }

  dayClicked(m: YYYYMMDDString): void {
    const state = this.state$.value;
    const prevSelected = state.selected.dates;
    const isSingleSelect = state.config.entity.isSingleSelect;
    const weekSelectCount = state.config.entity.weekSelectCount;
    const isDeselectOnClick = state.config.entity.isDeselectOnClick;
    let newSelected;
    if (weekSelectCount) {
      newSelected = this.getSelectedOnDayClickedForWeekSelectMode(
        m,
        isSingleSelect,
        prevSelected,
        weekSelectCount,
        isDeselectOnClick,
      );
    } else {
      newSelected = this.getSelectedOnDayClickedForDateSelectMode(m, isSingleSelect, prevSelected);
    }
    this.state$.next({
      ...state,
      selected: {
        ...state.selected,
        dates: { ...newSelected },
        updatedAt: new Date().getTime(),
        userUpdatedAt: new Date().getTime(),
      },
    });
  }

  private getSelectedOnDayClickedForDateSelectMode(
    day: YYYYMMDDString,
    isSingleSelect: boolean,
    prevSelected: { [dayId: string]: YYYYMMDDString },
  ): { [dayId: string]: YYYYMMDDString } {
    const dayId = day;
    const wasSelected = prevSelected[dayId];
    let newSelected;
    if (wasSelected) {
      if (!isSingleSelect) {
        delete prevSelected[dayId];
      }
      newSelected = prevSelected;
    } else {
      if (isSingleSelect) {
        newSelected = { [dayId]: day };
      } else {
        newSelected = { ...prevSelected, [dayId]: day };
      }
    }
    return newSelected;
  }

  private getSelectedOnDayClickedForWeekSelectMode(
    day: YYYYMMDDString,
    isSingleSelect: boolean,
    prevSelected: { [dayId: string]: YYYYMMDDString },
    weekSelectCount: number,
    isDeselectOnClick: boolean,
  ): { [dayId: string]: YYYYMMDDString } {
    const dayId = day;
    const wasSelected = prevSelected[dayId];
    const weekMoments = this.getMomentsForWeek(day, weekSelectCount);
    const newSelected = isSingleSelect ? {} : { ...prevSelected };
    if (wasSelected && isDeselectOnClick) {
      weekMoments.forEach((m) => {
        delete newSelected[dateToYYYYMMDD(m)];
      });
    } else {
      weekMoments.forEach((m) => {
        newSelected[dateToYYYYMMDD(m)] = dateToYYYYMMDD(m);
      });
    }
    return newSelected;
  }

  weekdays$(): Observable<fromTypes.RangePickerWeekday[]> {
    const isMondayFirst$ = this.state$.pipe(
      map((state) => state.config.entity.isStartWeekFromMonday),
      distinctUntilChanged(),
    );
    return isMondayFirst$.pipe(
      map((isMondayFirst) => {
        const result = getWeekSpanForDate(new Date(), isMondayFirst).map((d) => {
          return {
            moment: d,
            label: dateFormat(d, FormattingTokens.WEEKDAY_TEXT_TWO_CHAR),
          };
        });
        return result;
      }),
    );
  }

  decades$(): Observable<fromTypes.RangePickerDecade[]> {
    const numMonthsDisplayed$ = this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity.numMonthsDisplayed),
    );
    const currentMonth$ = this.state$.asObservable().pipe(
      map((state) => state.currentView),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.yearViewDate),
    );
    return combineLatest([currentMonth$, numMonthsDisplayed$]).pipe(
      map(([currMonth, numDisplayed]) => {
        const result = [] as fromTypes.RangePickerDecade[];
        const currMonthDate = yyyymmddToDate(currMonth);
        for (let i = 0; i < numDisplayed * fromTypes.yearsInDecadeCount; i += fromTypes.yearsInDecadeCount) {
          const start = dateAddYear(currMonthDate, i);
          const end = dateAddYear(start, fromTypes.yearsInDecadeCount);
          result.push({
            moment: start,
            label: fromTypes.utils.getMomentDecadeLabel(start, end),
          });
        }
        return result;
      }),
    );
  }

  yearsForDecade$(decade: fromTypes.RangePickerDecade): Observable<fromTypes.RangePickerYear[]> {
    const result: fromTypes.RangePickerYear[] = [];
    for (let i = 0; i < fromTypes.yearsInDecadeCount; i++) {
      const m = dateAddYear(decade.moment, i);
      result.push({
        label: dateFormat(m, 'yyyy'),
        moment: dateToYYYYMMDD(m),
      });
    }
    return of(result);
  }

  public forward(): void {
    const state = this.state$.value;
    if (state.currentView.viewType === fromTypes.ViewType.DAY) {
      this.forwardDayView();
    } else if (state.currentView.viewType === fromTypes.ViewType.YEAR) {
      this.forwardYearView();
    }
  }
  public forwardDayView(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.date;
    const currentMonthDate = yyyymmddToDate(currentMonth);
    const nextMonth = dateAddMonth(currentMonthDate, 1);
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        date: dateToYYYYMMDD(nextMonth),
        updatedAt: new Date().getTime(),
      },
    });
  }
  public forwardYearView(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.yearViewDate;
    const currentMonthDate = yyyymmddToDate(currentMonth);
    const nextMonthDate = dateAddYear(
      currentMonthDate,
      fromTypes.yearsInDecadeCount * state.config.entity.numMonthsDisplayed,
    );
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        yearViewDate: dateToYYYYMMDD(nextMonthDate),
        updatedAt: new Date().getTime(),
      },
    });
  }
  public backward(): void {
    const state = this.state$.value;
    if (state.currentView.viewType === fromTypes.ViewType.DAY) {
      this.backwardDayView();
    } else if (state.currentView.viewType === fromTypes.ViewType.YEAR) {
      this.backwardYearView();
    }
  }
  public backwardDayView(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.date;
    const currentMonthDate = yyyymmddToDate(currentMonth);
    const prevMonth = dateSubtractMonth(currentMonthDate, 1);
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        date: dateToYYYYMMDD(prevMonth),
        updatedAt: new Date().getTime(),
      },
    });
  }
  public backwardYearView(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.yearViewDate;
    const currentMonthDate = yyyymmddToDate(currentMonth);
    const nextMonth = dateSubtractYear(
      currentMonthDate,
      fromTypes.yearsInDecadeCount * state.config.entity.numMonthsDisplayed,
    );
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        yearViewDate: dateToYYYYMMDD(nextMonth),
        updatedAt: new Date().getTime(),
      },
    });
  }
  public months$(): Observable<fromTypes.RangePickerMonth[]> {
    const numMonthsDisplayed$ = this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity.numMonthsDisplayed),
    );
    const currentMonth$ = this.state$.asObservable().pipe(
      map((state) => state.currentView),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.date),
    );
    return combineLatest([currentMonth$, numMonthsDisplayed$]).pipe(
      map(([currMonth, numDisplayed]) => {
        const result = [] as fromTypes.RangePickerMonth[];
        const currMonthDate = yyyymmddToDate(currMonth);
        for (let i = 0; i < numDisplayed; i++) {
          const m = dateAddMonth(currMonthDate, i);
          result.push({
            moment: m,
            label: fromTypes.utils.getMomentMonthLabel(m, true),
          });
        }
        return result;
      }),
    );
  }

  public isCurrentlySelectedYear(year: fromTypes.RangePickerYear): Observable<boolean> {
    const yearDate = yyyymmddToDate(year.moment);
    return this.state$.asObservable().pipe(
      map((state) => state.currentView),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.date),
      map((currentDate) => {
        return datesIsSame(yearDate, yyyymmddToDate(currentDate), 'year');
      }),
    );
  }

  public daysForMonth$(month: YYYYMMDDString): Observable<RangePickerDay[]> {
    const monthDate = yyyymmddToDate(month);
    return this.state$.pipe(
      map((state) => state.config.entity.isStartWeekFromMonday),
      distinctUntilChanged(),
      switchMap((isStartFromMonday) => {
        const state = this.state$.value;
        const monthId = month + isStartFromMonday;
        let resultDays: RangePickerDay[];
        if (state.monthsDaysCache[monthId]) {
          resultDays = state.monthsDaysCache[monthId];
        } else {
          resultDays = fromTypes.utils.getDaysForMonth(monthDate, isStartFromMonday);
          this.state$.next({
            ...state,
            monthsDaysCache: {
              ...state.monthsDaysCache,
              [monthId]: resultDays,
            },
          });
        }
        const daysWithUpdates = resultDays.map((day) => {
          return this.getStateForDate(day, null, state.selected.dates, state.dayConfigs.entity, state.config.entity);
        });
        return of([...daysWithUpdates]);
      }),
    );
  }

  public dayChanges$(source: RangePickerDay): Observable<RangePickerDay> {
    return combineLatest([this.hoveredDates$, this.selectedDatesObj$, this.dayConfigs$, this.pickerConfig$]).pipe(
      map(([hovered, selected, dayConfigs, pickerConfig]) =>
        this.getStateForDate(source, hovered, selected, dayConfigs, pickerConfig),
      ),
    );
  }

  public setCurrentMonth(m: YYYYMMDDString): void {
    this.state$.next({
      ...this.state$.value,
      currentView: {
        ...this.state$.value.currentView,
        date: m,
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setHovered(m: YYYYMMDDString | null): void {
    const state = this.state$.value;
    const hovered = this.getHoveredForDate(m);
    const hoveredMap = hovered.reduce((p, c) => {
      const yyyymmdd = dateToYYYYMMDD(c);
      return { ...p, [yyyymmdd]: yyyymmdd };
    }, {});
    this.state$.next({
      ...state,
      hovered: {
        ...state.hovered,
        dates: hoveredMap,
        updatedAt: new Date().getTime(),
      },
    });
    if (hovered.length) {
      if (state.isMouseDown) {
        this.dayClicked(m);
      }
    }
  }

  public setMonthView(month: number): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        entity: { ...state.config.entity, numMonthsDisplayed: month },
      },
    });
  }

  public setReadonly(isReadonly: boolean): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        readonly: isReadonly,
      },
    });
  }

  public initStore(): void {
    this.state$.next(fromTypes.initialRangePickerStore);
  }

  public isReadOnly(): Observable<boolean> {
    return this.state$.asObservable().pipe(map((state) => state.config.readonly));
  }

  private getStateForDate(
    source: RangePickerDay,
    hovered: { [dateId: string]: YYYYMMDDString },
    selected: { [dateId: string]: YYYYMMDDString },
    dayConfigs: fromTypes.DayConfigs,
    pickerConfig: fromTypes.RangePickerConfig,
  ): RangePickerDay {
    let css = '';
    const today: YYYYMMDDString = dateToYYYYMMDD(new Date());
    if (source.moment === today) {
      css += ' today';
    }
    if (source.isDisplayed) {
      css += ' displayed ';
    } else {
      return source;
    }

    const { disableDatesAfter, disableDatesBefore } = pickerConfig;

    if (this.isDateDisabled(source.moment, disableDatesBefore, disableDatesAfter)) {
      source.isDisabled = true;
      css += ' disabled';
    } else {
      if (hovered && hovered[source.moment]) {
        css += 'hovered';
      }
    }
    if (selected[source.moment]) {
      css += ' selected';
    }
    const dayConfigForDate = dayConfigs[source.moment];
    const isColor = dayConfigForDate ? !!dayConfigForDate.color : false;
    if (isColor) {
      css += ' color ' + ' ' + dayConfigForDate.color;
    }
    const tooltip = dayConfigForDate ? dayConfigForDate.tooltip : null;
    return {
      ...source,
      tooltip,
      css,
    };
  }

  private getHoveredForDate(m: YYYYMMDDString | null): Date[] {
    if (!m) {
      return [];
    }
    const currState = this.state$.value;
    const config = currState.config.entity;
    const weekSelectCount = config.weekSelectCount;
    if (!weekSelectCount) {
      return m ? [yyyymmddToDate(m)] : [];
    }
    return this.getMomentsForWeek(m, weekSelectCount);
  }

  private getMomentsForWeek(m: YYYYMMDDString, weekCount: number): Date[] {
    const forwardMoments = this.getMomentsForWeekForwardFromCurrent(m, weekCount);
    if (forwardMoments.length / 7 < weekCount) {
      const backwardMoments = this.getMomentsForWeekBackwardFromCurrent(m, weekCount);
      if (backwardMoments.length / 7 < weekCount) {
        return [];
      } else {
        return backwardMoments;
      }
    } else {
      return forwardMoments;
    }
  }

  private getMomentsForWeekForwardFromCurrent(m: YYYYMMDDString, weekCount: number): Date[] {
    const { disableDatesBefore, disableDatesAfter, isStartWeekFromMonday } = this.state$.value.config.entity;
    let startOfWeek = yyyymmddStartOfWeek(m, isStartWeekFromMonday);
    if (disableDatesBefore && yyyymmddIsBefore(startOfWeek, disableDatesBefore)) {
      startOfWeek = disableDatesBefore;
    }
    const startOfWeekDate = yyyymmddToDate(startOfWeek);
    let endNWeeks: Date = dateAddWeek(startOfWeekDate, weekCount);
    endNWeeks = dateSubtractDay(endNWeeks, 1);
    if (disableDatesAfter && yyyymmddIsAfter(dateToYYYYMMDD(endNWeeks), disableDatesAfter)) {
      endNWeeks = yyyymmddToDate(disableDatesAfter);
    }
    const result = datesGetSpan(startOfWeekDate, endNWeeks);
    return result;
  }

  private getMomentsForWeekBackwardFromCurrent(m: YYYYMMDDString, weekCount: number): Date[] {
    const date = yyyymmddToDate(m);
    const { disableDatesBefore, disableDatesAfter, isStartWeekFromMonday } = this.state$.value.config.entity;
    let endOfWeek = dateEndOfWeek(date, isStartWeekFromMonday);
    const disableAfterDate = disableDatesAfter ? yyyymmddToDate(disableDatesAfter) : null;
    if (disableDatesAfter && dateIsAfter(endOfWeek, disableAfterDate)) {
      endOfWeek = disableAfterDate;
    }
    const smom = dateClone(endOfWeek);
    let startNWeeks = dateSubtractWeek(smom, weekCount);
    startNWeeks = dateAddDay(startNWeeks, 1);
    const disableBeforeDate = disableDatesBefore ? yyyymmddToDate(disableDatesBefore) : null;
    if (disableDatesBefore && dateIsBefore(startNWeeks, disableBeforeDate)) {
      startNWeeks = disableBeforeDate;
    }
    const result = datesGetSpan(startNWeeks, endOfWeek);
    return result;
  }

  private isDateDisabled(date: YYYYMMDDString, disableBefore: YYYYMMDDString, disableAfter: YYYYMMDDString): boolean {
    if (disableBefore && yyyymmddIsBefore(date, disableBefore)) {
      return true;
    }
    if (disableAfter && yyyymmddIsAfter(date, disableAfter)) {
      return true;
    }
    return false;
  }
}
