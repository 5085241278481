import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RideGoogleMapComponent } from './ride-google-map/ride-google-map.component';
import { StreetViewComponent } from './street-view/street-view.component';
import { RecenterButtonComponent } from './recenter-button/recenter-button.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { StreetViewPanoService } from './street-view-pano.service';
import { ComponentsModule } from '../components/components.module';
import { GoogleMapComponent } from './google-map/google-map.component';

const components = [RideGoogleMapComponent, StreetViewComponent];

@NgModule({
  declarations: [...components, RecenterButtonComponent, GoogleMapComponent],
  imports: [CommonModule, TooltipModule, ComponentsModule],
  providers: [StreetViewPanoService],
  exports: [...components, GoogleMapComponent],
})
export class GoogleMapModule {}
