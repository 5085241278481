import { createSelector } from '@ngrx/store';
import { TripContact, VehicleCatalog } from '@rootTypes';
import { TripDataInfoState } from './reducer/trips-data.reducer';
import { getTripDataFeature } from './feature.selector';
import { getAllVehicleEntities } from '../vehicles/store/selectors';
import { TripQuoteData } from '@rootTypes/entities/trips/trip-quote-data';
import {
  getItineraryFirstReturnStop,
  getItineraryFirstStop,
  getItineraryLastStop,
  getTripDisplayId as getTripDisplayIdUtil,
} from '@rootTypes/utils/trips';
import { GetCharterTripResponse } from '../../api/endpoints/get-charter-trip';
import { GetCharterTripItineraryResponse } from '../../api/endpoints/get-charter-trip-itinerary';

const getTripDataInfoFeature = createSelector(getTripDataFeature, (state): TripDataInfoState => state.info);

/**
 * Drivers
 */

/**
 * Vehicles
 */
export const getAllVehicles = getAllVehicleEntities;

/**
 * Contracts
 */
export const getAllContracts = createSelector(getTripDataInfoFeature, (state) => state.contracts);

/**
 * Invoices
 */
export const getAllInvoicesByTrips = createSelector(getTripDataInfoFeature, (state) => state.invoicesByTrips);

/**
 * Charter trip data
 */
export const getAllCharterTrips = createSelector(getTripDataInfoFeature, (state) => state.trips);
const getCharterTripEntityState = (tripId) => createSelector(getAllCharterTrips, (state) => state[tripId]);
export const getCharterTrip = (tripId) =>
  createSelector(getCharterTripEntityState(tripId), (state): GetCharterTripResponse => state?.entity);
export const isCharterTripLoading = (tripId) =>
  createSelector(getCharterTripEntityState(tripId), (state) => state?.isLoading || false);
export const getCharterTripError = (tripId) =>
  createSelector(getCharterTripEntityState(tripId), (state) => state?.error);
export const getTripDisplayId = (tripId) =>
  createSelector(getCharterTrip(tripId), (state) => getTripDisplayIdUtil(state));
export const getPaymentStateByTrip = (tripId) => createSelector(getCharterTrip(tripId), (state) => state?.paymentState);

export const getVendorAssignmentStatusByTrip = (tripId: string) =>
  createSelector(getCharterTrip(tripId), (state) => state?.vendorAssignmentStatus);
export const getVendorAssignmentsByTrip = (tripId: string) =>
  createSelector(getCharterTrip(tripId), (state) => {
    if (state) {
      return state.vendorAssignments || [];
    }
    return undefined;
  });

// itinerary by trip
const getItineraryStateByTrip = (tripId) =>
  createSelector(getCharterTrip(tripId), getAllItineraries, (trip, itineraries) =>
    trip && trip.tripItineraryId ? itineraries[trip.tripItineraryId] : undefined,
  );
export const getItineraryByTrip = (tripId) =>
  createSelector(getItineraryStateByTrip(tripId), (state): GetCharterTripItineraryResponse => state?.entity);
export const getFirstStopByTrip = (tripId) =>
  createSelector(getItineraryByTrip(tripId), (state) => getItineraryFirstStop(state));
export const getLastStopByTrip = (tripId) =>
  createSelector(getItineraryByTrip(tripId), (state) => getItineraryLastStop(state));
export const getFirstReturnStopByTrip = (tripId: string) =>
  createSelector(getItineraryByTrip(tripId), (state) => getItineraryFirstReturnStop(state));
export const getTypeByTrip = (tripId) => createSelector(getItineraryByTrip(tripId), (state) => state?.tripType);
export const getStatusByTrip = (tripId) => createSelector(getCharterTrip(tripId), (state) => state?.status);
export const getStatusChangeReasonByTrip = (tripId) =>
  createSelector(getCharterTrip(tripId), (state) => state?.statusChangeReason);
export const getDistrictIdByTrip = (tripId) => createSelector(getItineraryByTrip(tripId), (state) => state?.districtId);
export const getCampusIdByTrip = (tripId) => createSelector(getItineraryByTrip(tripId), (state) => state?.campusId);
export const getCatalogsByTrip = (tripId) => createSelector(getCharterTrip(tripId), (state) => state?.catalogs || []);
export const getContactsByTrip = (tripId) =>
  createSelector(getCharterTrip(tripId), (state): TripContact[] => state?.contacts);
export const getInstructionsByTrip = (tripId) => createSelector(getCharterTrip(tripId), (state) => state?.note);
export const getPurposeByTrip = (tripId) => createSelector(getItineraryByTrip(tripId), (state) => state?.tripPurpose);
export const getActivityDescriptionByTrip = (tripId) =>
  createSelector(getItineraryByTrip(tripId), (state) => state?.activityDescription);
export const getDurationTotalByTrip = (tripId) =>
  createSelector(getItineraryByTrip(tripId), (state) => state?.totalDurationMinutes);
export const getMileageTotalByTrip = (tripId) =>
  createSelector(getItineraryByTrip(tripId), (state) => state?.totalMiles);
export const isWheelchairForTrip = (tripId) =>
  createSelector(getItineraryByTrip(tripId), (state) => state?.isWheelChairAccessible);
export const getPassengerCountByTrip = (tripId) =>
  createSelector(getItineraryByTrip(tripId), (state) => state?.numberOfPassengers);
export const isKeepDriverAndVehicleForTrip = (tripId) =>
  createSelector(getItineraryByTrip(tripId), (state) => state?.isKeepDriverForDuration);
export const getOutboundLegByTrip = (tripId) => createSelector(getItineraryByTrip(tripId), (state) => state?.outbound);
export const getFirstOutboundStopByTrip = (tripId) =>
  createSelector(getOutboundLegByTrip(tripId), (state) => (state?.stops?.length ? state.stops[0] : undefined));
export const getTripStartDate = (tripId) =>
  createSelector(getFirstOutboundStopByTrip(tripId), (state) => state?.arrivalDate);
export const getReturnLegByTrip = (tripId) => createSelector(getItineraryByTrip(tripId), (state) => state?.return);
export const getOutboundRidesByTrip = (tripId) =>
  createSelector(getCharterTrip(tripId), (state) => state?.rides?.outbound || []);
export const getReturnRidesByTrip = (tripId) =>
  createSelector(getCharterTrip(tripId), (state) => state?.rides?.return || []);
export const isItineraryByTripLoading = (tripId) =>
  createSelector(getItineraryStateByTrip(tripId), (state) => state?.isLoading);
export const getItineraryByTripError = (tripId) =>
  createSelector(getItineraryStateByTrip(tripId), (state) => state?.error);
export const getTimezoneByTrip = (tripId) => createSelector(getItineraryByTrip(tripId), (state) => state.timezone);
export const getOrganizationNameByTrip = (tripId) =>
  createSelector(getCharterTrip(tripId), (state) => state?.organizationName);
export const getCustomFieldsByTrip = (tripId: string) =>
  createSelector(getCharterTrip(tripId), (trip) => trip?.customFieldValues);

// invoice by a trip
const getInvoiceStateByTrip = (tripId) => createSelector(getAllInvoicesByTrips, (state) => state[tripId]);
export const getInvoiceByTrip = (tripId) => createSelector(getInvoiceStateByTrip(tripId), (state) => state?.entity);
export const isInvoiceByTripLoading = (tripId) =>
  createSelector(getInvoiceStateByTrip(tripId), (state) => state?.isLoading || false);
export const getInvoiceByTripError = (tripId) => createSelector(getInvoiceStateByTrip(tripId), (state) => state?.error);
export const getTripEstimatedTotal = (tripId) => createSelector(getInvoiceByTrip(tripId), (state) => state?.totalCents);
// contract by a trip
export const getContractIdByTrip = (tripId) => createSelector(getItineraryByTrip(tripId), (state) => state?.contractId);
const getContractStateByTrip = (tripId) =>
  createSelector(getContractIdByTrip(tripId), getAllContracts, (contractId, contracts) => contracts[contractId]);
export const getContractByTrip = (tripId) => createSelector(getContractStateByTrip(tripId), (state) => state?.entity);
export const isContractByTripLoading = (tripId) =>
  createSelector(getContractStateByTrip(tripId), (state) => state?.isLoading || false);
export const getContractByTripError = (tripId) =>
  createSelector(getContractStateByTrip(tripId), (state) => state?.error);

/**
 * Trip quote data
 */
// catalog map
export type VehicleCatalogMap = { [catalogId: string]: VehicleCatalog };
const getCatalogDataStatesByTrip = (tripId) =>
  createSelector(getCatalogsByTrip(tripId), getAllCatalogs, (catalogs, allCatalogs) => {
    if (!catalogs) {
      return [];
    }
    return catalogs.map(({ catalogId }) => allCatalogs[catalogId]);
  });
export const getCatalogMapByTrip = (tripId) =>
  createSelector(getCatalogDataStatesByTrip(tripId), (states): VehicleCatalogMap => {
    return states
      .filter((item) => !!item?.entity)
      .map((item) => item.entity)
      .reduce((p, c) => ({ ...p, [c.catalogId]: c }), {});
  });
export const isAnyCatalogByTripLoading = (tripId) =>
  createSelector(getCatalogDataStatesByTrip(tripId), (state): boolean => {
    return state.filter((s) => !!s).some((s) => s.isLoading);
  });

export const getTripQuoteData = (tripId) =>
  createSelector(
    getInvoiceByTrip(tripId),
    getContractByTrip(tripId),
    (invoice, contract): TripQuoteData | undefined => {
      if (!(invoice && contract)) {
        return undefined;
      }
      return {
        config: contract,
        quote: invoice,
      };
    },
  );

/**
 * Itinerary data
 */
export const getAllItineraries = createSelector(getTripDataInfoFeature, (state) => state.itineraries);
const getTripItineraryEntityState = (tripItineraryId) =>
  createSelector(getAllItineraries, (state) => state[tripItineraryId]);
export const getTripItinerary = (tripItineraryId) =>
  createSelector(getTripItineraryEntityState(tripItineraryId), (state) => state?.entity);
export const isTripItineraryLoading = (tripItineraryId) =>
  createSelector(getTripItineraryEntityState(tripItineraryId), (state) => state?.isLoading || false);
export const getTripItineraryError = (tripItineraryId) =>
  createSelector(getTripItineraryEntityState(tripItineraryId), (state) => state?.error);

/**
 * Charter catalogs
 */
export const getAllCatalogs = createSelector(getTripDataInfoFeature, (state) => state.catalogs);
const getCatalogEntityState = (catalogId) => createSelector(getAllCatalogs, (state) => state[catalogId]);
export const getCatalog = (catalogId) => createSelector(getCatalogEntityState(catalogId), (state) => state?.entity);
export const isCatalogLoading = (catalogId) =>
  createSelector(getCatalogEntityState(catalogId), (state) => state?.isLoading || false);
export const getCatalogLoadError = (catalogId) =>
  createSelector(getCatalogEntityState(catalogId), (state) => state?.error);

export const isTripDetailsPageLoading = (tripId: string) =>
  createSelector(
    isCharterTripLoading(tripId),
    isItineraryByTripLoading(tripId),
    isContractByTripLoading(tripId),
    (...args) => args.some((l) => !!l),
  );
export const getTripDetailsPageLoadingError = (tripId: string) =>
  createSelector(
    getCharterTripError(tripId),
    getItineraryByTripError(tripId),
    getContractByTripError(tripId),
    (tripError, itineraryError, contractError) => tripError || itineraryError || contractError,
  );
export const getCatalogDisplayName = (catalogId) => createSelector(getCatalog(catalogId), (state) => state.displayName);

/**
 * Status log history
 */
const getAllTripStatusLogs = createSelector(getTripDataInfoFeature, (state) => state.tripStatusHistory);

const getTripStatusLogHistoryEntityState = (tripId) => createSelector(getAllTripStatusLogs, (state) => state[tripId]);
export const getTripStatusLogHistory = (tripId) =>
  createSelector(getTripStatusLogHistoryEntityState(tripId), (state) => state?.entity);
export const isTripStatusLogHistoryLoading = (tripId) =>
  createSelector(getTripStatusLogHistoryEntityState(tripId), (state) => state?.isLoading);
export const getTripStatusLogHistoryError = (tripId) =>
  createSelector(getTripStatusLogHistoryEntityState(tripId), (state) => state?.error);
