import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityFilterComponent } from './entity-filter/entity-filter.component';
import { IconsModule } from '../icons/icons.module';
import { DirectivesModule } from '../directives/directives.module';
import { FormControlsModule } from '../form-controls/form-controls.module';
import { ComponentsModule } from '../components/components.module';
import { PhotosModule } from '../photos/photos.module';
import { SearchOptionComponent } from './search-option/search-option.component';
import { PipesModule } from '../pipes/pipes.module';
import { VehicleSelectComponent } from './vehicle-select/vehicle-select.component';
import { VehicleSelectOptionComponent } from './vehicle-select-option/vehicle-select-option.component';
import { EntityFilterApiService } from './entity-filter-api.service';
import { VendorSelectComponent } from './vendor-select/vendor-select.component';
import { DriverSelectComponent } from './driver-select/driver-select.component';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { SearchIconModule } from '../icons/search-icon/search-icon.module';
const exportComponents = [VehicleSelectComponent, VendorSelectComponent, DriverSelectComponent];

@NgModule({
  declarations: [...exportComponents, EntityFilterComponent, SearchOptionComponent, VehicleSelectOptionComponent],
  providers: [EntityFilterApiService],
  imports: [
    CommonModule,
    DirectivesModule,
    FormControlsModule,
    PipesModule,
    ComponentsModule,
    PhotosModule,
    IconCloseModule,
    SearchIconModule,
    IconsModule,
  ],
  exports: [...exportComponents, EntityFilterComponent],
})
export class EntityFilterModule {}
