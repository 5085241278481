import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { EntityFilterStoreService } from '../entity-filter-store.service';
import * as fromTypes from '../types';
import { SelectOption } from '../../form-controls';
import { EntityFilterRequest } from '../../../api/endpoints/entity-filter';

@Component({
  selector: 'wp-entity-filter',
  templateUrl: './entity-filter.component.html',
  styleUrls: ['./entity-filter.component.scss'],
  providers: [EntityFilterStoreService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public id: string;
  @Input() public placeholder: string;
  @Input() public initialDisplayValue: string;
  // if defined, will be shown at the top of the list
  @Input() public defaultOption: SelectOption;
  @Input() public searchByType: fromTypes.PortalEntityType;
  @Input() public entityStatus?: fromTypes.EntityStatus = fromTypes.EntityStatus.ACTIVE; // default is ACTIVE
  /**
   * @deprecated
   * just disable the search input control
   */
  @Input() public disabled = false;
  @Input() public isSearchGlassIcon = true;
  @Input() public districtIds: string[];
  @Input() public campusIds: string[];
  @Input() public operatorIds: string[];
  @Input() public parentIds: string[];
  @Input() public driverIds: string[];
  @Input() public vendorIds: string[];
  @Input() public yardIds: string[];
  @Input() public districtProgramIds: string[];
  @Input() public extraFilters: EntityFilterRequest['extraFilters'];
  /*
   * A custom template to be rendered for an option.
    The entity will be bound to let-entity template variable.
    If absent, will render default option template.
    See example here src/app/shared/entity-filter/vehicle-select/vehicle-select.component.html
   */
  @Input() public optionTemplate: any;
  @Input() public fillInputOnSelect = false;
  @Input() public resetSelectedOnInputClear = false;
  @Input() public required = false;
  @Input() public controlStateChange?: Observable<void>;
  @Input() public inputCustomErrorMessageKey: string;
  @Input() public tabIndex = '0';
  @Input() public autofocus: boolean;

  // string
  @Input()
  public searchInputControl: UntypedFormControl;

  @Output()
  public selected: EventEmitter<fromTypes.PortalEntity> = new EventEmitter<fromTypes.PortalEntity>();
  @Output() public inputEvent = new EventEmitter<string>();
  @Output() public inputCleared = new EventEmitter<void>();
  @Output() public inputBlur = new EventEmitter<void>();
  @Output() public defaultOptionSelected = new EventEmitter<SelectOption>();

  public items$: Observable<fromTypes.PortalEntity[]>;
  public empty$: Observable<boolean>;
  public error$: Observable<any | null>;
  public searchTermLoading$: Observable<boolean>;
  public hasNext$: Observable<boolean>;
  public nextPageLoading$: Observable<boolean>;
  public dropdownOpen$: Observable<boolean>;

  private sub = new Subscription();
  private isDisabledStateJustChanged = false;
  private inputEventSubject = new Subject<string>();

  constructor(private store: EntityFilterStoreService) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.store.dispose();
  }

  ngOnInit(): void {
    this.store.setSearchByType(this.searchByType);
    this.store.setEntityStatus(this.entityStatus);
    this.store.setFilterOptions(this.getEntityFilterOptionsFromInputs());
    this.initSearchControl();
    this.items$ = this.store.items$();
    this.empty$ = this.store.empty$();
    this.error$ = this.store.error$();
    this.nextPageLoading$ = this.store.pageLoading();
    this.hasNext$ = this.store.hasNext$();
    this.dropdownOpen$ = this.store.dropdownOpen$();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && !changes.disabled.isFirstChange()) {
      this.isDisabledStateJustChanged = true;
      if (this.disabled) {
        // Note preventing of valueChanges event leaves the input border disabled in UI
        this.searchInputControl.disable({ emitEvent: true });
      } else {
        this.searchInputControl.enable({ emitEvent: true });
      }
    }
    if (changes.searchByType && !changes.searchByType.isFirstChange()) {
      this.store.setSearchByType(changes.searchByType.currentValue);
      if (this.searchInputControl.value) {
        this.clearInput();
      }
    }
    if (
      (changes.yardIds && !changes.yardIds.isFirstChange()) ||
      (changes.districtIds && !changes.districtIds.isFirstChange()) ||
      (changes.campusIds && !changes.campusIds.isFirstChange()) ||
      (changes.driverIds && !changes.driverIds.isFirstChange()) ||
      (changes.vendorIds && !changes.vendorIds.isFirstChange()) ||
      (changes.parentIds && !changes.parentIds.isFirstChange()) ||
      (changes.operatorIds && !changes.operatorIds.isFirstChange()) ||
      (changes.districtProgramIds && !changes.districtProgramIds.isFirstChange()) ||
      (changes.extraFilters && !changes.extraFilters.isFirstChange())
    ) {
      this.store.setFilterOptions(this.getEntityFilterOptionsFromInputs());
      if (this.searchInputControl.value) {
        this.clearInput();
      }
    }
  }

  public onSearchInputClick(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    if (this.searchInputControl?.disabled) {
      return;
    }
    document.body.click();
    event.stopPropagation();
    this.store.openDropdown();
  }

  public onOptionClick(o: fromTypes.PortalEntity): void {
    this.selected.next(o);
    this.store.closeDropdown();
    if (this.fillInputOnSelect) {
      this.searchInputControl.setValue(o.label, { emitEvent: false });
    }
  }

  public onDefaultOptionClick(option: SelectOption): void {
    this.store.closeDropdown();
    this.defaultOptionSelected.emit(option);
    if (this.fillInputOnSelect) {
      this.searchInputControl.setValue(option.value, { emitEvent: false });
    }
  }

  public clearInput(): void {
    this.store.closeDropdown();
    this.searchInputControl.setValue(null);
    this.inputCleared.emit();
  }

  public onLoadNextPage(): void {
    this.store.loadNextPage();
  }

  public onClickOutside(): void {
    this.store.closeDropdown();
  }

  public onScrolled(): void {
    this.store.loadNextPage();
  }

  public onInputEvent(event: string): void {
    this.inputEvent.emit(event);
    this.inputEventSubject.next(event);
  }

  private onSearchTermChange(searchTerm: string): void {
    this.store.search(searchTerm);
  }

  private initSearchControl(): void {
    if (!this.searchInputControl) {
      const validators = this.required ? [Validators.required] : [];
      this.searchInputControl = new UntypedFormControl(this.initialDisplayValue, validators);
    }
    if (this.disabled) {
      this.searchInputControl.disable();
    }
    const sub = this.inputEventSubject.subscribe((val) => {
      this.onSearchTermChange(val);
    });
    this.sub.add(sub);

    if (this.resetSelectedOnInputClear) {
      const clearSub = this.searchInputControl.valueChanges.subscribe((val) => {
        if (!val) {
          this.selected.next(undefined);
        }
      });
      this.sub.add(clearSub);
    }
  }

  private getEntityFilterOptionsFromInputs(): fromTypes.FilterOptions {
    const result = {} as fromTypes.FilterOptions;
    if (this.districtIds && this.districtIds.length) {
      result.districtIds = this.districtIds;
    }
    if (this.campusIds && this.campusIds.length) {
      result.campusIds = this.campusIds;
    }
    if (this.operatorIds && this.operatorIds.length) {
      result.operatorIds = this.operatorIds;
    }
    if (this.parentIds && this.parentIds.length) {
      result.parentIds = this.parentIds;
    }
    if (this.driverIds && this.driverIds.length) {
      result.driverIds = this.driverIds;
    }
    if (this.vendorIds && this.vendorIds.length) {
      result.vendorIds = this.vendorIds;
    }
    if (this.yardIds && this.yardIds.length) {
      result.yardIds = this.yardIds;
    }
    if (this.districtProgramIds && this.districtProgramIds.length) {
      result.districtProgramIds = this.districtProgramIds;
    }
    if (this.extraFilters && Object.keys(this.extraFilters).length) {
      result.extraFilters = this.extraFilters;
    }
    return result;
  }
}
