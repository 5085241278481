import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { AuthApiService } from '../../services/auth-api.service';
import * as fromAuthActions from '../actions';
import * as fromRootStore from '@rootStore';
import { commonRoutes } from '@router';
import { Store } from '@ngrx/store';
import { AuthState } from '..';
import * as fromSelectors from '../selectors';
import { flashConnectRequested, flashDisconnectRequested } from '../../../data/flash';
import { ImageApiService } from '../../../api';
import { ErrorPopupService } from '../../../store/services/error-popup.service';
import { AuthenticationProvider } from '../../../api/endpoints/get-authentication-provider';
import { getConnectionInstance } from '../../../api/cache/index-db/get-connection-instance';
import { SessionStorageService } from '../../../core/services';

@Injectable()
export class AuthEffects {
  constructor(
    private authApiService: AuthApiService,
    private imageApi: ImageApiService,
    private actions$: Actions,
    private store: Store<AuthState>,
    private errorPopup: ErrorPopupService,
    private sessionStorage: SessionStorageService,
  ) {}

  public getAuthProviderRequested = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.getAuthProviderRequested),
      switchMap(({ email }) => {
        return this.authApiService.getAuthenticationProvider({ email, appDomain: location.hostname }).pipe(
          map((response) => {
            return fromAuthActions.getAuthProviderSuccess({ response });
          }),
          catchError((originalError) => {
            return of(
              fromAuthActions.getAuthProviderFailed({
                error: { text: 'Failed to get authentication provider', originalError },
              }),
            );
          }),
        );
      }),
    ),
  );

  public onAuthProviderSSO$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.getAuthProviderSuccess),
        filter(({ response }) => response.provider === AuthenticationProvider.SSO),
        withLatestFrom(this.store.select(fromSelectors.loginRelayState)),
        tap(([{ response }, relayState]) => {
          this.authApiService.signInWithGoogle(response.ssoUrl, relayState);
        }),
      ),
    { dispatch: false },
  );

  public getAuthProviderFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.getAuthProviderFailed),
        tap(({ error }) => {
          this.errorPopup.openDefaultErrorPopup(error);
        }),
      ),
    { dispatch: false },
  );

  public loginWithPasswordRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.loginWithPasswordRequested),
      withLatestFrom(this.store.select(fromSelectors.getAuthEmail)),
      switchMap(([{ password }, email]) => {
        return this.authApiService.signInWithEmailAndPassword(email, password).pipe(
          map((user) =>
            fromAuthActions.loginWithPasswordSuccess({
              firebaseUserId: user.userId,
            }),
          ),
          catchError((err: { message: string; code: string } | 'UNAUTHORIZED') => {
            if (typeof err === 'object' && err.message) {
              return of(
                fromAuthActions.loginWithPasswordFailed({
                  error: { text: err.message, code: err.code },
                }),
              );
            }
            return of(
              fromAuthActions.loginWithPasswordFailed({
                error: { text: "Can't login with email and password", originalError: err },
              }),
            );
          }),
        );
      }),
    ),
  );

  public loginWithTokenRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.loginWithTokenRequested),
      switchMap((action) => {
        return this.authApiService.signInWithToken(action.token).pipe(
          map((user) =>
            fromAuthActions.loginWithTokenSuccess({
              firebaseUserId: user.userId,
            }),
          ),
          catchError((err: { message: string; code: string } | 'UNAUTHORIZED') => {
            if (typeof err === 'object' && err.message) {
              return of(
                fromAuthActions.loginWithTokenFailed({
                  error: { text: err.message, code: err.code },
                }),
              );
            }
            return of(
              fromAuthActions.loginWithTokenFailed({
                error: { text: "Can't login with token", originalError: err },
              }),
            );
          }),
        );
      }),
    ),
  );

  public loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.loginWithPasswordSuccess, fromAuthActions.loginWithTokenSuccess),
      withLatestFrom(this.store.select(fromSelectors.loginRelayState)),
      map(([, RelayState]) => {
        return fromRootStore.go({
          path: [commonRoutes.selectAccount.url],
          query: RelayState ? { RelayState } : undefined,
        });
      }),
    ),
  );

  public checkAuthRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.checkAuthRequested),
      switchMap(() => {
        return this.store.select(fromSelectors.firebaseUserId).pipe(
          take(1),
          switchMap((firebaseUserId) => {
            if (firebaseUserId) {
              return of(fromAuthActions.checkAuthSuccess({ firebaseUserId }));
            }
            return this.authApiService.checkAuth().pipe(
              map((user) => {
                if (user) {
                  return fromAuthActions.checkAuthSuccess({
                    firebaseUserId: user.userId,
                  });
                } else {
                  return fromAuthActions.checkAuthSuccess({
                    firebaseUserId: null,
                  });
                }
              }),
              catchError((err) => {
                console.error(err);
                return of(fromAuthActions.checkAuthFailed({ error: { text: 'Failed to check user auth' } }));
              }),
            );
          }),
        );
      }),
    ),
  );

  public onSuccessfulAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.checkAuthSuccess),
      filter((a) => !!a.firebaseUserId),
      map(() => flashConnectRequested()),
    ),
  );

  public logoutPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromAuthActions.logoutPageRequested,
        fromAuthActions.homePageAutoLogoutRequested,
        fromAuthActions.logoutOnEmployeeImpersonationSessionEnd,
        fromAuthActions.accountTimezoneErrorLogoutRequested,
      ),
      map(() => {
        this.store.dispatch(fromRootStore.drawerClosedSelected());
      }),
      // Hotfix for automatic logout, wait for ride/route details drawer to close
      // This should be removed after the drawer update
      debounceTime(200),
      map(() => {
        return fromRootStore.go({ path: [commonRoutes.logout.url] });
      }),
    ),
  );

  public logoutPageLogoutRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.logoutPageLogoutRequested),
      switchMap(() => {
        return this.authApiService.getEmployeeImpersonationSessionDetails().pipe(
          take(1),
          mergeMap((employeeImpersonation) => {
            const signOut = employeeImpersonation
              ? () => this.authApiService.signOutFromFirebase() // BE session ends automatically on BE for impersonation
              : () => this.authApiService.signOut();
            return signOut().pipe(
              map(() => fromAuthActions.logoutPageLogoutSuccess()),
              catchError((err) => {
                console.log(err);
                return of(fromAuthActions.logoutPageLogoutFailed({ error: { text: 'Failed to logout' } }));
              }),
            );
          }),
        );
      }),
    ),
  );

  public logoutPageLogoutEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.logoutPageLogoutSuccess, fromAuthActions.logoutPageLogoutFailed),
      withLatestFrom(this.store.select(fromSelectors.logoutRelayState)),
      map(([, RelayState]) => {
        return fromRootStore.go({
          path: [commonRoutes.login.url],
          query: RelayState ? { RelayState } : undefined,
        });
      }),
    ),
  );

  public cleanUpStoreOnLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.logoutPageLogoutSuccess, fromAuthActions.logoutPageLogoutFailed),
      tap(() => {
        this.imageApi.cleanCache();
      }),
      tap(() => {
        getConnectionInstance().cleanData();
      }),
      tap(() => {
        this.sessionStorage.clear();
      }),
      map(() => {
        return fromRootStore.cleanUpStore();
      }),
    ),
  );

  public disconnectFlashOnLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.logoutPageLogoutSuccess, fromAuthActions.logoutPageLogoutFailed),
      map(() => {
        return flashDisconnectRequested();
      }),
    ),
  );
}
