import {
  ApiUserRole,
  DriverBusReportStatus,
  DriverDocumentEntityFilterStatus,
  DriverDocumentType,
  ParkoutLocationCategory,
  ParkoutLocationStatus,
  ParkoutLocationType,
  StudentChangeRequestStatus,
  VehicleInspectionDriverReviewStatus,
  VehicleInspectionMechanicAssessmentStatus,
  YYYYMMDDString,
} from '@apiEntities';
import { VehicleCondition } from '@apiEntities/inspections/end-of-day-inspections';
import { TransactionAggregatedStatus } from '@apiEntities/invoicing/transaction-aggregated-status';
import { TransactionIssueGroup } from '@apiEntities/invoicing/transaction-issue-group';
import { ActivityStatus } from '@apiEntities/timesheet-entries';

/**
 * Extending entity search
 * 1. Add a new response type (EntitySearchResponseType enum)
 * 2. Add a new interface for the response type below. It should extend BaseEntitySearchResult.
 *    Add this interface to the EntitySearchResult union type below!.
 * 3. Make new entry in the entitySearchConfig in (src/app/types/utils/portal-entity/entity-search-config.ts)
 */

/**
 * Cross-entity search/filter response
 */
export enum EntitySearchResponseType {
  DRIVER = 'driver',
  RIDE = 'ride',
  ROUTE = 'route',
  PARENT = 'parent',
  STUDENT = 'student',
  DISTRICT = 'district',
  CAMPUS = 'campus',
  SCHOOL = 'school',
  VENDOR = 'vendor',
  VEHICLE = 'vehicle',
  YARD = 'yard',
  DRIVER_DOCUMENT = 'driver_document',
  ZUM_EMPLOYEE = 'zum_employee',
  VENDOR_EMPLOYEE = 'vendor_employee',
  SCHOOL_EMPLOYEE = 'school_employee',
  STUDENT_CHANGE_REQUEST = 'student_change_request',
  ACTIVITY_TIME_ENTRY = 'activity_time_entry',
  ROUTE_GROUP_CHANGE_REQUEST = 'route_group_schedule_change_request',
  ROUTE_GROUP = 'route_group',
  USER_ACCESS_POLICY = 'user_access_policy',
  DRIVER_BUS_REPORT = 'driver_bus_report',
  CHARTER_TRIP = 'charter_trip',
  FINANCE_TRANSACTION = 'finance_transaction',
  TRANSPORTATION_REGISTRATION = 'transportation_registration',
  END_OF_RIDE_INSPECTION = 'end_of_ride_inspection',
  END_OF_DAY_INSPECTION = 'end_of_day_inspection',
  STUDENT_RFID_ORDER = 'student_rfid_order',
  PARKOUT_LOCATION = 'parkout_location',
  PARKOUT_LOCATION_EVENT = 'parkout_location_event',
  PARKOUT_LOCATION_DRIVER_AUTHORIZATION = 'parkout_location_driver_authorization',
  DRIVER_COMMUNICATION = 'driver_communication',
  MONITOR = 'monitor',
}

/**
 * Entity search/filter response
 */
export interface EntitySearchResponse {
  results: EntitySearchResult[];
  hits?: ApiEntitySearchHit[];
  nextSkip?: number;
}

export type ApiEntitySearchHit = {
  label: string; // Label to show up in the search drop down
  pillLabel: string; // Label show in the pill text
  field: string; // Name of the request field which can be used to call in ride or routes api
  value: string; // value of the search term
};

/**
 * Entity search/filter response. Extend the union type below to add new response type.
 */

export interface BaseEntitySearchResult {
  type: EntitySearchResponseType;
}

export type EntitySearchResult =
  | DriverResult
  | ParentResult
  | StudentResult
  | DistrictResult
  | CampusResult
  | SchoolResult
  | VehicleResult
  | VendorResult
  | RideResult
  | RouteResult
  | DriverDocumentResult
  | YardResult
  | ZumEmployeeResult
  | VendorEmployeeResult
  | SchoolEmployeeResult
  | StudentChangeRequestResult
  | ActivityResult
  | RouteGroupChangeRequestResult
  | RouteGroupResult
  | UserAccessPolicyResult
  | DriverBusReportResult
  | CharterTripResult
  | InvoicingTransactionResult
  | TransportationRegistrationResult
  | EndOfRideInspectionResult
  | EndOfDayInspectionResult
  | StudentRfidOrderResult
  | ParkoutLocationResult
  | ParkoutLocationEventResult
  | ParkoutLocationDriverAuthorizationResult
  | DriverCommunicationResult
  | MonitorResult;

export interface DriverResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.DRIVER;
  driverId: string;
  label: string; // FN + LN
}

export interface ParentResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.PARENT;
  parentId: string;
  label: string; // FN + LN
}

export interface RideResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.RIDE;
  rideId: string;
  label: string;
}

export interface RouteResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.ROUTE;
  routeId: string;
  label: string;
}

export interface UserAccessPolicyResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.USER_ACCESS_POLICY;
  userId: string;
  userRole: ApiUserRole;
  accessPersonas: string[];
  accessPermissions: string[];
  zumEmployeeIds?: string[];
  vendorEmployeeIds?: string[];
  schoolEmployeeIds?: string[];
}

export interface StudentResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.STUDENT;
  studentId: string;
  parentId: string;
  label: string; // FN + LN
}

export interface DistrictResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.DISTRICT;
  districtId: string;
  label: string; // district name, same as on admin dashboard
}

interface CampusResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.CAMPUS;
  districtId: string;
  campusId: string;
  label: string; // campus secondary name, same as on admin dashboard
}

interface SchoolResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.SCHOOL;
  districtId: string;
  campusId: string;
  label: string;
}

export interface VendorResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.VENDOR;
  vendorId: string;
  label?: string;
}

export interface YardResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.YARD;
  vendorId: string;
  yardId: string;
  code: string;
  operatorIds?: string[];
  label?: string;
}

export interface VehicleResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.VEHICLE;
  vehicleId: string;
  vehicleDisplayId?: string;
  imagePath: string;
  make: string;
  model: string;
  license: {
    number: string;
    state: string;
  };
  assignedDriverId?: string;
  assignedDriver?: {
    id: string;
    // firstName: string;
    // lastName: string;
  };
}

export interface ZumEmployeeResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.ZUM_EMPLOYEE;
  zumEmployeeId: string;
  label: string;
}

export interface VendorEmployeeResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.VENDOR_EMPLOYEE;
  vendorEmployeeId: string;
  vendorId: string;
  label: string;
}

export interface SchoolEmployeeResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.SCHOOL_EMPLOYEE;
  schoolEmployeeId: string;
  districtId: string;
  label: string;
  email: string;
  organizationName?: string;
  phoneNumber?: string;
}

export interface CharterTripResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.CHARTER_TRIP;
  tripId: string;
  label: string;
}

export interface DriverDocumentResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.DRIVER_DOCUMENT;
  driverDocumentId: string;
  driverId: string;
  docType: DriverDocumentType;
  uploadedDate: YYYYMMDDString;
  parentDocumentExpirationDate?: YYYYMMDDString;
  docStatus: DriverDocumentEntityFilterStatus;
}

export interface StudentChangeRequestResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.STUDENT_CHANGE_REQUEST;
  studentChangeRequestId: string;
  studentId: string;
  districtId: string;
  campusId?: string;
  status: StudentChangeRequestStatus;
}

export interface ActivityResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.ACTIVITY_TIME_ENTRY;
  activityName: string;
  activityConfigId: string;
  status: ActivityStatus;
  activityId: string;
  timeEntryId?: string;
  activityTypeId: string;
  activityStartTimestamp: number;
  activityEndTimestamp: number;
  timeEntryStartTimestamp?: number;
  timeEntryEndTimestamp?: number;
  driverId: string;
  timezone: string;
  label?: string;
  isScheduled: boolean;
}

export interface RouteGroupChangeRequestResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.ROUTE_GROUP_CHANGE_REQUEST;
  changeRequestId: string;
  label?: string;
}

export interface RouteGroupResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.ROUTE_GROUP;
  routeGroupId: string;
  label: string;
}

export interface DriverBusReportResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.DRIVER_BUS_REPORT;
  driverBusReportId: string;
  reportDate: YYYYMMDDString;
  driverId: string;
  reportStatus: DriverBusReportStatus;
  yardId: string;
}

export interface InvoicingTransactionResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.FINANCE_TRANSACTION;
  id: string;
  displayId: string;
  date: YYYYMMDDString;
  sourceNumber: string;
  sourceType: string;
  contractNumber: string;
  vehicleType: string;
  totalCents: number;
  status: TransactionAggregatedStatus;
  issueGroups: TransactionIssueGroup[];
  label?: string;
}

export interface TransportationRegistrationResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.TRANSPORTATION_REGISTRATION;
  id: string;
  label: string;
}

export interface EndOfRideInspectionResult {
  type: EntitySearchResponseType.END_OF_RIDE_INSPECTION;
  id: string;
  driverId: string;
  vehicleDisplayId: string;
  inspectionTimestamp: number;
  inspectionDate: string;
  vehicleId: string;
  rideId: string;
  numItemsLeft: number;
  numStudentsLeft: number;
  label?: string;
}

export interface EndOfDayInspectionResult {
  type: EntitySearchResponseType.END_OF_DAY_INSPECTION;
  id: string;
  driverId: string;
  vehicleDisplayId: string;
  inspectionTimestamp: number;
  inspectionDate: YYYYMMDDString;
  vehicleId: string;
  numItemsLeft: number;
  numStudentsLeft: number;
  inspectionStatus: 'COMPLETED';
  vehicleCondition: VehicleCondition;
  mechanicAssessmentStatus: VehicleInspectionMechanicAssessmentStatus;
  driverReviewStatus: VehicleInspectionDriverReviewStatus;
  label?: string;
}

export interface StudentRfidOrderResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.STUDENT_RFID_ORDER;
  orderId: string;
  label: string;
}

export interface ParkoutLocationResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.PARKOUT_LOCATION;
  id: string;
  label: string;
  locationType: ParkoutLocationType;
  status: ParkoutLocationStatus;
  formattedAddress: string;
  category: ParkoutLocationCategory;
  pendingRequestsCount: number;
}

export interface ParkoutLocationEventResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.PARKOUT_LOCATION_EVENT;
  id: string;
  timestamp: number;
}

export interface ParkoutLocationDriverAuthorizationResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.PARKOUT_LOCATION_DRIVER_AUTHORIZATION;
  id: string;
  driverId: string;
}

export interface DriverCommunicationResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.DRIVER_COMMUNICATION;
  id: string;
}

export interface MonitorResult extends BaseEntitySearchResult {
  type: EntitySearchResponseType.MONITOR;
  monitorId: string;
}
