import { createAction, props } from '@ngrx/store';
import { TripQuoteFinal, WpError } from '@rootTypes';
import { GetCharterConfigResponse } from '../../api/endpoints/get-charter-config';
import { GetCharterTripLoadOptions, GetTripItineraryLoadOptions } from './types';
import { GetCharterTripResponse } from '../../api/endpoints/get-charter-trip';
import { GetCharterTripItineraryResponse } from '../../api/endpoints/get-charter-trip-itinerary';
import { GetCharterContractRequest, GetCharterContractResponse } from 'src/app/api/endpoints/get-charter-contract';
import {
  GetCharterTripInvoiceRequest,
  GetCharterTripInvoiceResponse,
} from '../../api/endpoints/get-charter-trip-invoice';
import { GetCharterCatalogResponse } from '../../api/endpoints/get-charter-catalog';
import { GetCharterTripStatusHistoryResponse } from '../../api/endpoints/get-charter-trip-status-history';

export const getCharterConfigByGuard = createAction('[CharterConfigGuard]: getCharterConfig requested');
export const getCharterConfigForTripAssignmentList = createAction(
  '[WorkQueueTripAssignment]: getCharterConfig requested',
);

export const getCharterConfigRequested = createAction('[TripsConfigEffects] API getCharterConfig request');
export const getCharterConfigSuccess = createAction(
  '[TripsConfigEffects] API getCharterConfig success',
  props<{ data: GetCharterConfigResponse }>(),
);
export const getCharterConfigFailed = createAction(
  '[TripsConfigEffects] API getCharterConfig failed',
  props<{ error: WpError }>(),
);

/**
 * Get charter trip
 */
export const getCharterTripForTripListItem = createAction(
  '[TripListItem]: getCharterTrip requested',
  props<{ tripId: string; options?: GetCharterTripLoadOptions }>(),
);
export const getCharterTripForTripAssignmentListItem = createAction(
  '[WorkQueueTripAssignmentListItem]: getCharterTrip requested',
  props<{ tripId: string; options?: GetCharterTripLoadOptions }>(),
);
export const getCharterTripRequested = createAction(
  '[TripData] API: getCharterTrip requested',
  props<{ tripId: string; options?: GetCharterTripLoadOptions }>(),
);
export const getCharterTripSuccess = createAction(
  '[TripData] API: getCharterTrip success',
  props<{ trip: GetCharterTripResponse }>(),
);
export const getCharterTripFailed = createAction(
  '[TripData] API: getCharterTrip failed',
  props<{ tripId: string; error: WpError }>(),
);

/**
 * Update charter trip
 */
export const updateCharterTripWithDelayRequested = createAction(
  '[TripData]: API: Update charter trip with delay requested',
  props<{ tripId: string; options?: GetCharterTripLoadOptions }>(),
);

/**
 * Get charter itinerary
 */
export const getCharterTripItineraryRequested = createAction(
  '[TripData] API: getCharterTripItinerary requested',
  props<{ charterTripItineraryId: string; options?: GetTripItineraryLoadOptions }>(),
);
export const getCharterTripItinerarySuccess = createAction(
  '[TripData] API: getCharterTripItinerary success',
  props<{ charterTripItinerary: GetCharterTripItineraryResponse }>(),
);
export const getCharterTripItineraryFailed = createAction(
  '[TripData] API: getCharterTripItinerary failed',
  props<{ charterTripItineraryId: string; error: WpError }>(),
);

/**
 * Get charter catalog
 */
export const getCharterCatalogRequested = createAction(
  '[TripData] API: getCharterCatalog requested',
  props<{ catalogId: string }>(),
);
export const getCharterCatalogSuccess = createAction(
  '[TripData] API: getCharterCatalog success',
  props<{ catalogId: string; catalog: GetCharterCatalogResponse }>(),
);
export const getCharterCatalogFailed = createAction(
  '[TripData] API: getCharterCatalog failed',
  props<{ catalogId: string; error: WpError }>(),
);

/**
 * getCharterTripInvoice
 */
export const getTripInvoiceRequested = createAction(
  '[TripData]: API: getCharterTripInvoice requested',
  props<{ request: GetCharterTripInvoiceRequest }>(),
);
export const getTripInvoiceSuccess = createAction(
  '[TripData]: API: getCharterTripInvoice success',
  props<{ tripId: string; invoice: GetCharterTripInvoiceResponse }>(),
);
export const getTripInvoiceFailed = createAction(
  '[TripData]: API: getCharterTripInvoice failed',
  props<{ tripId: string; error: WpError }>(),
);

/**
 * getCharterContract
 */
export const getContractRequested = createAction(
  '[TripData]: API: getCharterContract requested',
  props<{ request: GetCharterContractRequest }>(),
);
export const getContractSuccess = createAction(
  '[TripData]: API: getCharterContract success',
  props<{ contractId: string; contract: GetCharterContractResponse }>(),
);
export const getContractFailed = createAction(
  '[TripData]: API: getCharterContract failed',
  props<{ contractId: string; error: WpError }>(),
);

/**
 * getCharterTripStatusHistory
 */
export const getTripStatusHistoryRequested = createAction(
  '[TripData]: API: getCharterTripStatusHistory requested',
  props<{ tripId: string; noLoadingIndicator?: boolean }>(),
);
export const getTripStatusHistorySuccess = createAction(
  '[TripData]: API: getCharterTripStatusHistory success',
  props<{ tripId: string; history: GetCharterTripStatusHistoryResponse }>(),
);
export const getTripStatusHistoryFailed = createAction(
  '[TripData]: API: getCharterTripStatusHistory failed',
  props<{ tripId: string; error: WpError }>(),
);

export const updateTripStatusHistoryWithDelayRequested = createAction(
  '[TripData]: API: update trip status history with delay requested',
  props<{ tripId: string }>(),
);

/**
 * Update/Finalize invoice
 */
export const updateInvoiceFinalDraftSuccess = createAction(
  '[EditTripInvoiceComponentStore]: API: update invoice final draft success',
  props<{ tripId: string; invoice: TripQuoteFinal }>(),
);
export const finalizeInvoiceSuccess = createAction(
  '[BookingAmountComponentStore]: API: finalize invoice success',
  props<{ tripId: string; invoice: TripQuoteFinal }>(),
);

/**
 * Trip assignment
 */
export const tripAssignmentChanged = createAction(
  '[TripAssignmentStore]: Change trip assignment success',
  props<{ updatedTripIds: { [tripId: string]: true } }>(),
);
export const workQueueBulkTripAssignmentSuccess = createAction(
  '[WorkQueueBulkTripAssignmentStore]: Change assignment success',
  props<{ updatedTripIds: { [tripId: string]: true } }>(),
);
