import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { MapLocation, MapMarker } from '@rootTypes';
import { Polyline, PolylineDrawer } from '../models/polyline-drawer';
import { MarkerRenderer } from '../models/marker-renderer';
import { mapCenterDefault } from '../models/map-center-default';
import { greyMapThemeStyles } from '../grey-map-theme-styles';
import { fitMapToLocations } from '../map-utils/fit-map-to-locations';
import { PortalPolygonOptions } from '../types/portal-polygon';
import { PolygonRenderer } from '../models/polygon-renderer';
import { MapCircleRenderer } from '../models/map-circle-renderer';
import { CustomControlRenderer } from '../models/map-custom-control-renderer';
import { MapCustomControlConfig } from '../interfaces';
import { MapDrawingManager } from '../models/map-drawing-manager';
import { PortalCircleOptions } from '../types/portal-map-circle';

@Component({
  selector: 'wp-google-map',
  template: `
    <div class="map-content">
      <div class="map" id="map" #mapComponent></div>
      <div *ngIf="isDefaultMapControls" class="recenter-button-wrap">
        <wp-recenter-button (click)="onRecenterClick()"></wp-recenter-button>
      </div>
    </div>
  `,
  styles: [
    `
      .map {
        width: 100%;
        height: 100%;
      }

      .map-content {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100%;
      }

      .recenter-button-wrap {
        position: absolute;
        right: 10px;
        top: 171px;
        z-index: 5;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleMapComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() public markers: MapMarker[];
  @Input() public polylines: Polyline[];
  @Input() public polygons: PortalPolygonOptions[];
  @Input() public circles: PortalCircleOptions[];
  @Input() public customControls: MapCustomControlConfig[];
  @Input() public zoomControl = false;
  @Input() public mapDrawingOptions: google.maps.drawing.DrawingManagerOptions = {
    drawingControl: false,
    drawingControlOptions: {
      drawingModes: [],
    },
  };

  @Input() public disableScrollZoom = false;
  @Input() public isAutoFocusOnLocations = true;
  // ensure map padding for markers and polylines
  @Input() public markerTopPaddingStr: string;
  @Input() public markerBottomPaddingStr: string;
  @Input() public markerLeftPaddingStr: string;
  @Input() public markerRightPaddingStr: string;
  @Input() public isDefaultMapControls: boolean;
  @Input() public zoomControlOptions: google.maps.ZoomControlOptions;
  @Output() public mapInitialized = new EventEmitter<google.maps.Map>();
  @Output() public polylineMouseOver = new EventEmitter<Polyline>();
  @Output() public polylineMouseOut = new EventEmitter<Polyline>();
  @Output() public userInteracted = new EventEmitter<void>();
  @Output() public polygonCreated = new EventEmitter<google.maps.Polygon>();
  public map: google.maps.Map;
  @ViewChild('mapComponent') private mapComponent;

  private mapTilesLoaded = false;

  // tools
  private polylineDrawer: PolylineDrawer;
  private markerRenderer: MarkerRenderer;
  private polygonRenderer: PolygonRenderer;
  private circleRenderer: MapCircleRenderer;
  private customControlRenderer: CustomControlRenderer;
  private mapDrawingManager: MapDrawingManager;

  private currentLocationHash: string;
  private mapDrawerEventListener: google.maps.MapsEventListener;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    const options: google.maps.MapOptions = {
      center: mapCenterDefault,
      zoom: 14,
      clickableIcons: false,
      disableDefaultUI: true,
      fullscreenControl: true,
      mapTypeControlOptions: { mapTypeIds: [] },
      zoomControl: this.isDefaultMapControls || this.zoomControl,
      zoomControlOptions: this.isDefaultMapControls
        ? {
            position: google.maps.ControlPosition.TOP_RIGHT,
          }
        : this.zoomControlOptions,
      streetViewControl: false,
      gestureHandling: this.disableScrollZoom ? 'greedy' : 'cooperative',
      styles: [...greyMapThemeStyles],
    };
    this.map = new google.maps.Map(this.mapComponent.nativeElement, options);

    google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
      this.onInitTools();
      this.mapTilesLoaded = true;
      this.mapInitialized.emit(this.map);
      this.cd.detectChanges();
    });
    google.maps.event.addListener(this.map, 'click', () => {
      this.userInteracted.emit();
    });
    this.updatePaddings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.polylines) {
      if (this.polylineDrawer) {
        this.polylineDrawer.setPolylines(this.polylines || []);
        this.onLocationInputsChange();
      }
    }
    if (changes.markers) {
      if (this.markerRenderer) {
        this.markerRenderer.setMarkers(this.markers || []);
        this.onLocationInputsChange();
      }
    }
    // BD-3569 TODO on polygon changes and circle changes render them
    if (changes.circles) {
      if (this.circleRenderer) {
        this.circleRenderer.setCircles(this.circles || []);
        this.onLocationInputsChange();
      }
    }

    if (changes.polygons) {
      if (this.polygonRenderer) {
        this.polygonRenderer.setPolygons(this.polygons || []);
        this.onLocationInputsChange();
      }
    }

    if (changes.customControls) {
      if (this.customControlRenderer) {
        this.customControlRenderer.setControls(this.customControls || []);
      }
    }

    if (changes.mapDrawingOptions) {
      if (this.mapDrawingManager) {
        this.mapDrawingManager.setDrawingManager(this.mapDrawingOptions);
      }
    }

    if (
      (changes.markerTopPaddingStr && !changes.markerTopPaddingStr.isFirstChange()) ||
      (changes.markerBottomPaddingStr && !changes.markerBottomPaddingStr.isFirstChange()) ||
      (changes.markerLeftPaddingStr && !changes.markerLeftPaddingStr.isFirstChange()) ||
      (changes.markerRightPaddingStr && !changes.markerRightPaddingStr.isFirstChange())
    ) {
      this.updatePaddings();
    }
  }

  public openPolygonEditor(): void {
    // BD-3569 TODO
  }

  public setDrawingMode(drawingMode: google.maps.drawing.OverlayType): void {
    this.mapDrawingManager.setDrawingMode(drawingMode || null);
  }

  public getDrawingMode(): google.maps.drawing.OverlayType {
    return this.mapDrawingManager.getDrawingMode();
  }

  private onInitTools(): void {
    // draw polylines
    this.polylineDrawer = new PolylineDrawer(this.map);
    this.polylineDrawer.setPolylines(this.polylines || []);
    this.polylineDrawer.subscribeToEvents((event) => {
      if (event.type === 'mouseover') {
        this.polylineMouseOver.emit(event.polyline);
      } else if (event.type === 'mouseout') {
        this.polylineMouseOut.emit(event.polyline);
      }
    });

    // draw markers
    this.markerRenderer = new MarkerRenderer(this.map);
    this.markerRenderer.setMarkers(this.markers || []);

    //draw circles
    this.circleRenderer = new MapCircleRenderer(this.map);
    this.circleRenderer.setCircles(this.circles || []);

    //draw polygons
    this.polygonRenderer = new PolygonRenderer(this.map);
    this.polygonRenderer.setPolygons(this.polygons || []);

    //draw custom controls
    this.customControlRenderer = new CustomControlRenderer(this.map);
    this.customControlRenderer.setControls(this.customControls || []);

    //enable drawing manager
    this.mapDrawingManager = new MapDrawingManager(this.map);
    this.mapDrawingManager.setDrawingManager(this.mapDrawingOptions);
    this.subscribeToMapDrawerEvent();
    this.onLocationInputsChange();
  }

  ngOnDestroy(): void {
    if (this.polylineDrawer) {
      this.polylineDrawer.unsubscribeAllFromEvents();
    }

    if (this.mapDrawingManager) {
      this.unsubscribeFromMapDrawerEvent();
    }
  }

  private subscribeToMapDrawerEvent(): void {
    this.mapDrawerEventListener = google.maps.event.addListener(
      this.mapDrawingManager.getDrawerManagerInstance(),
      'overlaycomplete',
      (event: { type: google.maps.drawing.OverlayType; overlay: google.maps.Polygon }) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const newPolygon = event.overlay;
          this.polygonCreated.emit(newPolygon);
        }
      },
    );
  }

  public onRecenterClick(): void {
    this.userInteracted.emit();
    this.map.setZoom(16);
    this.onRecenter(this.getAllLocations());
  }

  public setControls(buttonConfig: MapCustomControlConfig[]): void {
    this.customControlRenderer.setControls(buttonConfig);
  }

  public updateCustomControlIcon(controlId: string, icon: string): void {
    this.customControlRenderer.updateCustomControlIcon(controlId, icon);
  }

  public updateCustomControlState(controlId: string, disabled: boolean): void {
    this.customControlRenderer.updateCustomControlState(controlId, disabled);
  }

  public onFullScreenClick(): void {
    const elementToSendFullScreen: any = this.map.getDiv().firstChild;
    if (elementToSendFullScreen) {
      if (elementToSendFullScreen.requestFullscreen) {
        elementToSendFullScreen.requestFullscreen();
      } else if (elementToSendFullScreen.mozRequestFullScreen) {
        elementToSendFullScreen.mozRequestFullScreen();
      } else if (elementToSendFullScreen.webkitRequestFullScreen) {
        elementToSendFullScreen.webkitRequestFullScreen((Element as any).ALLOW_KEYBOARD_INPUT);
      }
    }
  }

  private unsubscribeFromMapDrawerEvent(): void {
    google.maps.event.removeListener(this.mapDrawerEventListener);
  }

  public onZoomInClick(): void {
    const zoom = this.map.getZoom();
    const newZoom = Math.min(20, zoom + 1);
    this.map.setZoom(newZoom);
  }

  public onZoomOutClick(): void {
    const zoom = this.map.getZoom();
    const newZoom = Math.max(0, zoom - 1);
    this.map.setZoom(newZoom);
  }

  public getPolygonCenter(polygon: google.maps.Polygon): google.maps.LatLng {
    return this.polygonRenderer.getPolygonCenter(polygon);
  }

  private updatePaddings(): void {
    if (!this.map) {
      return;
    }
    this.clearPadders('top');
    if (this.markerTopPaddingStr !== undefined) {
      this.addPadder('top', this.markerTopPaddingStr);
    }
    this.clearPadders('bottom');
    if (this.markerBottomPaddingStr !== undefined) {
      this.addPadder('bottom', this.markerBottomPaddingStr);
    }
    this.clearPadders('left');
    if (this.markerLeftPaddingStr !== undefined) {
      this.addPadder('left', this.markerLeftPaddingStr);
    }
    this.clearPadders('right');
    if (this.markerRightPaddingStr !== undefined) {
      this.addPadder('right', this.markerRightPaddingStr);
    }
  }

  private clearPadders(position: 'top' | 'bottom' | 'left' | 'right') {
    const controls: google.maps.ControlPosition =
      position === 'top'
        ? google.maps.ControlPosition.TOP_CENTER
        : position === 'bottom'
          ? google.maps.ControlPosition.BOTTOM_CENTER
          : position === 'left'
            ? google.maps.ControlPosition.LEFT_CENTER
            : google.maps.ControlPosition.RIGHT_CENTER;
    const id = `${position}-padder`;
    const index = this.map.controls[controls].getArray().findIndex((c) => c.id === id);
    if (index >= 0) {
      this.map.controls[controls].removeAt(index);
    }
  }

  private addPadder(position: 'top' | 'bottom' | 'left' | 'right', paddingStr: string) {
    const controls: google.maps.ControlPosition =
      position === 'top'
        ? google.maps.ControlPosition.TOP_CENTER
        : position === 'bottom'
          ? google.maps.ControlPosition.BOTTOM_CENTER
          : position === 'left'
            ? google.maps.ControlPosition.LEFT_CENTER
            : google.maps.ControlPosition.RIGHT_CENTER;
    const id = `${position}-padder`;
    const div = document.createElement('div');
    div.id = id;
    if (position === 'top' || position === 'bottom') {
      div.style.width = '100%';
      div.style.height = paddingStr;
    } else {
      div.style.width = paddingStr;
      div.style.height = '100%';
    }
    this.map.controls[controls].push(div);
    this.onRecenter(this.getAllLocations());
  }

  private onLocationInputsChange(): void {
    if (!this.isAutoFocusOnLocations) {
      return;
    }
    const locations = this.getAllLocations();
    const locationHash = this.getLocationCheckSum(locations);
    if (locationHash !== this.currentLocationHash) {
      this.currentLocationHash = locationHash;
      this.onRecenter(locations);
    }
  }

  private getAllLocations(): MapLocation[] {
    const polyPaths = (this.polylines || []).map((p) => p.path).filter((p) => !!p);
    const polyPoints = polyPaths.reduce((prev, curr) => {
      return [...prev, ...curr];
    }, []);
    const markerPoints = (this.markers || []).map((m) => m.location);
    const polygonCenters = (this.polygons || []).map((p) => {
      const polygonCenter = this.polygonRenderer.getPolygonCenter(new google.maps.Polygon(p.shape));
      return {
        lat: polygonCenter.lat(),
        lng: polygonCenter.lng(),
      } as MapLocation;
    });
    const circleCenters = (this.circles || []).map((c) => {
      return { lat: c.shape.center.lat, lng: c.shape.center.lng } as MapLocation;
    });
    return [...polyPoints, ...markerPoints, ...polygonCenters, ...circleCenters];
  }

  public onRecenter(locations: MapLocation[]): void {
    fitMapToLocations(this.map, locations);
  }

  private getLocationCheckSum(locations: MapLocation[]): string {
    return (locations || []).reduce((prev, curr) => prev + curr.lat + curr.lng + ',', '');
  }
}
