import { XOR } from 'ts-essentials';
import { Check, YYYYMMDDString } from '../../endpoints/common';

export type Vehicle = VehicleProps & XOR<ZumerVehicleInfo, VendorVehicleInfo>;

type VehicleProps = {
  id: string;
  VIN?: string;
  vehicleImagePath: string;
  make: string;
  model: string;
  color: string;
  year: number;
  seatingCapacity: number;
  comments?: string;
  license: License;
  registration: Registration;
  insurance: Insurance;
  equipment: Equipment;
  checks: Check[];
  status: VehicleStatus;
};

export enum VehicleStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type ZumerVehicleInfo = {
  type: VehicleType.ZUMER_VEHICLE;
  primaryDriver: PrimaryDriver;
};

export type VendorVehicleInfo = {
  primaryDriver?: PrimaryDriver;
  type: VehicleType.VENDOR_VEHICLE;
  vendorId: string;
  yardId: string;
  vehicleDisplayId: string;
};

export type License = {
  number: string;
  state: string;
};

export type PrimaryDriver = {
  driverId: string;
  effectiveDate?: YYYYMMDDString;
};

export enum VehicleType {
  VENDOR_VEHICLE = 'VENDOR_VEHICLE',
  ZUMER_VEHICLE = 'ZUMER_VEHICLE',
}

export type Registration = {
  expirationDate: YYYYMMDDString;
};

export type Insurance = {
  expirationDate: YYYYMMDDString;
};

export type Equipment = {
  boosterSeatCount?: number;
  wheelchairCount?: number;
};
