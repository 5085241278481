<div
  class="app-progress-bar-mask"
  [ngStyle]="{display: (accountLoading$ | async) ? null : 'none' }"
>
  <wp-progress-bar></wp-progress-bar>
</div>

<router-outlet
  [ngStyle]="{display: (accountLoading$ | async) ? 'none' : null }"
>
</router-outlet>

<wp-popup></wp-popup>
<wp-drawer (maskClicked)="onDrawerMaskClicked()"></wp-drawer>

<wp-new-app-version-available-popup></wp-new-app-version-available-popup>
