<section
  class="wp-side-bar__item"
  [matTooltip]="displayIconTooltip"
  [matTooltipPosition]="'right'"
  [matTooltipDisabled]="isSideBarOpened"
  [ngClass]="{active: isActive$ | async}"
  (click)="onSelect()"
>
  <ng-content></ng-content>
</section>
