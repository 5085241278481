import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './containers/page-not-found/page-not-found.component';
import { HomeGuardService } from './guards/home-guard.service';
import { GoogleInitGuardService } from './guards/google-init-guard.service';
import { ErrorComponent } from './containers/error/error.component';
import * as fromRootTypes from '@rootTypes';
import { HomeComponent } from './home/containers/home/home.component';
import { homeChildRoutes } from './home/home-child-routes';
import { DrawerGuardService } from './guards/drawer-guard.service';
import { HomePageResolverGuard } from './guards/home-page-resolver.guard';
import { HomePageResolverComponent } from './home/containers/home-page-resolver/home-page-resolver.component';
import { DrawerAccessDeniedComponent } from './containers/drawer-access-denied/drawer-access-denied.component';
import { accessDeniedRoute, drawerAccessDeniedRoute } from './router/portal-routes';
import { NoViewPermissionsComponent } from './containers/no-view-permissions/no-view-permissions.component';
import { AccessDeniedComponent } from './containers/access-denied/access-denied.component';
import { DeeplinkComponent } from './containers/deeplink/deeplink.component';
import { CharterLoginComponent } from './auth/containers/charter-login/charter-login.component';
import { commonRoutes } from '@router';
import { accountTimezoneErrorRoute } from './router/portal-routes/account-timezone-error/account-timezone-error.router-service';
import { AccountTimezoneErrorComponent } from './containers/account-timezone-error/account-timezone-error.component';
import { multiAccountManagementOptionsGuardFn } from './auth/guards/multi-account-management-options.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: commonRoutes.home.path,
    pathMatch: 'full',
  },
  // Home path for main router outlet
  {
    path: '',
    canActivate: [HomeGuardService, GoogleInitGuardService, multiAccountManagementOptionsGuardFn()],
    children: [
      {
        path: 'route-management',
        loadChildren: () =>
          import('./features/route-explorer/route-management.module').then((m) => m.RouteManagementModule),
      },
      {
        path: commonRoutes.home.path,
        canActivate: [HomePageResolverGuard],
        component: HomePageResolverComponent,
      },
      {
        path: '',
        component: HomeComponent,
        children: [
          ...homeChildRoutes,
          {
            path: commonRoutes.noViewPermissions.path,
            component: NoViewPermissionsComponent,
          },
          {
            path: accessDeniedRoute.path,
            component: AccessDeniedComponent,
          },
          ...fromRootTypes.utils.router.getFeatureRoutesWithPrefix(),
          {
            path: 'deeplink',
            component: DeeplinkComponent,
          },
          {
            path: wpEnvironment.charterLoginInPortalPath,
            component: CharterLoginComponent,
          },
          {
            path: accountTimezoneErrorRoute.path,
            component: AccountTimezoneErrorComponent,
          },
        ],
      },
    ],
  },

  /**
   * Home path for drawer routes
   */
  {
    path: '_',
    canActivate: [DrawerGuardService, GoogleInitGuardService],
    outlet: fromRootTypes.router.RouterOutlets.DRAWER,
    children: [
      // drawer routes should start with 'drawer' to differentiate them from
      // main outlet routes, e.g. we won't show section loader, while
      // lazy-loading drawer routes
      ...fromRootTypes.utils.router.getFeatureRoutesWithPrefix('drawer'),
      {
        path: drawerAccessDeniedRoute.path,
        component: DrawerAccessDeniedComponent,
      },
    ],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
