import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PortalFeature } from '../entities/common/portal-features';
import { driverListRoute } from '../../router/portal-routes/drivers/driver-list';
import { workQueuePath } from '../../router/portal-routes/work-queue/work-queue-path';
import { driverEditorPath } from '../../router/portal-routes/driver-editor/driver-editor-path';
import { driverDocumentEditorPath } from '../../router/portal-routes/driver-document-editor/driver-document-editor-path';
import { rideAssignmentRoute } from '../../router/portal-routes/rides/ride-assignment';
import { driverStatusEditorPath } from '../../router/portal-routes/driver-status-editor/driver-status-editor-path';
import { studentChangeRequestRootPath } from '../../router/portal-routes/student-change-requests';
import { reportsRootPath } from '../../router/portal-routes/reports/reports-root-path';
import { dashboardRootPath } from '../../router/portal-routes/dashboard/dashboard-root-path';
import { dashboardRoute } from '../../router/portal-routes/dashboard/dashboard-router.service';
import { vehicleListRoute, vehiclesRootPath } from '../../router/portal-routes/vehicles';
import { vehicleInspectionListRoute } from '../../router/portal-routes/vehicle-inspections';
import { rideList } from '../../router/portal-routes/rides';
import { PortalPermissionsFacade } from '../../auth/store/facades/portal-permissions.facade';
import { routeList } from '../../router/portal-routes/routes';
import { studentList } from '../../router/portal-routes/students';
import { districtListRoute } from '../../router/portal-routes/districts';
import { campusListRoute } from '../../router/portal-routes/campuses';
import { vendorListRoute } from '../../router/portal-routes/vendors';
import { vendorEmployeeListRoute } from '../../router/portal-routes/employees-vendor';
import { reportsRoute } from '../../router/portal-routes/reports';
import { yardListRoute } from '../../router/portal-routes/yards';
import { schoolEmployeeListRoute } from '../../router/portal-routes/employees-school';
import { getWorkQueueTabs } from '../../router/portal-routes/work-queue';
import { mainActivityListRoute } from '../../router/portal-routes/activities';
import {
  accessManagementRootPath,
  accessUserListRoute,
  standbyDriverStatusPath,
  vehicleStatusEditorPath,
} from '../../router/portal-routes';
import { driverBusReportsPath } from '../../router/portal-routes/driver-bus-reports';
import { tripsPath } from '../../router/portal-routes/trips/trips-path';
import { tripListRoute } from '../../router/portal-routes/trips/trip-list';
import { invoicingPath } from '../../router/portal-routes/invoicing/invoicing-path';
import { accountSelectRoute } from '../../router/portal-routes/invoicing/account-select';
import { transportationRequestsPath } from '../../router/portal-routes/transportation-requests/transportation-requests-path';
import { transportationRequestListRoute } from '../../router/portal-routes/transportation-requests/transportation-request-list.router-service';
import { inspectionsRootPath } from '../../router/portal-routes/inspections/inspections-path';
import { inspectionsRoute } from '../../router/portal-routes/inspections/inspections';
import { inspectionsEndOfRidePath } from '../../router/portal-routes/inspections-end-of-ride/inspections-end-of-ride-path';
import { inspectionsEndOfDayPath } from '../../router/portal-routes/inspections-end-of-day/inspections-end-of-day-path';
import { studentRfidPath } from '../../router/portal-routes/student-rfid/student-rfid-path';
import { transportationRequestBusLookupPath } from '../../router/portal-routes/transportation-request-bus-lookup/transportation-request-bus-lookup-path';
import { parkoutEditorPath } from '../../router/portal-routes/parkout-editor';
import { parkoutRequestManagePath } from '../../router/portal-routes/parkout-request-manage/parkout-request-manage-path';
import { driverAttendanceRootPath } from '../../router/portal-routes/driver-attendance/driver-attendance-root-path';
import { driverAttendanceEditorPath } from '../../router/portal-routes/driver-attendance-editor/driver-attendance-editor-path';

/**
 * This is a single place of registration of portal features
 */
export const getPortalFeatures = (): readonly PortalFeature[] => {
  return Object.freeze<PortalFeature[]>([
    {
      id: dashboardRootPath,
      path: dashboardRootPath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isDashboardView$(),
      menuIndex: 5,
      menuName: 'Dashboard',
      link: dashboardRoute.getHref(),
      firstUrlSegment: dashboardRootPath,
      loadChildren: () => import('../../features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
      id: 'rides',
      path: 'rides',
      menuName: 'Rides',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isRideView$(),
      menuIndex: 10,
      link: rideList.getHref(),
      firstUrlSegment: 'rides',
      loadChildren: () => import('../../features/rides/rides.module').then((m) => m.RidesModule),
    },
    {
      id: 'routes',
      path: 'routes',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isRouteView$(),
      menuName: 'Runs',
      menuIndex: 20,
      link: routeList.getHref(),
      firstUrlSegment: 'routes',
      loadChildren: () => import('../../features/runs/routes.module').then((m) => m.RoutesModule),
    },
    {
      id: tripsPath,
      path: tripsPath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isCharterTripsView$(),
      menuName: 'Trips',
      menuIndex: 23,
      link: tripListRoute.getHref(),
      firstUrlSegment: tripsPath,
      loadChildren: () => import('../../features/trips/trips.module').then((m) => m.TripsModule),
    },
    {
      id: 'route-group',
      path: 'route-group',
      menuName: 'Routes',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isRouteGroupView$(),
      menuIndex: 25,
      link: 'route-group',
      firstUrlSegment: 'route-group',
      loadChildren: () => import('../../features/route-group/route-group.module').then((m) => m.RouteGroupModule),
    },
    {
      id: 'drivers',
      path: 'drivers',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isDriverView$(),
      menuIndex: 30,
      menuName: 'Drivers',
      link: driverListRoute.getHref(),
      firstUrlSegment: 'drivers',
      loadChildren: () => import('../../features/drivers/drivers.module').then((m) => m.DriversModule),
    },
    {
      id: 'students',
      path: 'students',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isStudentView$(),
      menuIndex: 50,
      menuName: 'Students',
      link: studentList.getHref(),
      firstUrlSegment: 'students',
      loadChildren: () => import('../../features/students/students.module').then((m) => m.StudentsModule),
    },
    {
      id: 'districts',
      path: 'districts',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isDistrictView$(),
      menuIndex: 60,
      menuName: 'Districts',
      link: districtListRoute.getHref(),
      firstUrlSegment: 'districts',
      loadChildren: () => import('../../features/districts/districts.module').then((m) => m.DistrictsModule),
    },
    {
      id: 'campuses',
      path: 'campuses',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isCampusView$(),
      menuIndex: 70,
      menuName: 'Campuses',
      link: campusListRoute.getHref(),
      firstUrlSegment: 'campuses',
      loadChildren: () => import('../../features/campuses/campuses.module').then((m) => m.CampusesModule),
    },
    {
      id: 'campus-programs',
      path: 'campus-programs',
      isInMenu: false,
      firstUrlSegment: 'campus-programs',
      loadChildren: () =>
        import('../../features/campus-programs/campus-programs.module').then((m) => m.CampusProgramsModule),
    },
    {
      id: 'vendors',
      path: 'vendors',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isVendorView$(),
      menuIndex: 80,
      menuName: 'Vendors',
      link: vendorListRoute.getHref(),
      firstUrlSegment: 'vendors',
      loadChildren: () => import('../../features/vendors/vendors.module').then((m) => m.VendorsModule),
    },
    {
      id: 'yards',
      path: 'yards',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isYardView$(),
      menuIndex: 85,
      menuName: 'Yards',
      link: yardListRoute.getHref(),
      firstUrlSegment: 'yards',
      loadChildren: () => import('../../features/yards/yards.module').then((m) => m.YardsModule),
    },
    {
      id: vehiclesRootPath,
      path: vehiclesRootPath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isVehicleView$(),
      menuIndex: 86,
      menuName: 'Vehicles',
      link: vehicleListRoute.getHref(),
      firstUrlSegment: vehiclesRootPath,
      loadChildren: () => import('../../features/vehicles/vehicles.module').then((m) => m.VehiclesModule),
    },
    {
      id: vehicleStatusEditorPath,
      path: vehicleStatusEditorPath,
      isInMenu: false,
      loadChildren: () =>
        import('../../features/vehicle-status-editor/vehicle-status-editor.module').then(
          (m) => m.VehicleStatusEditorModule,
        ),
    },
    {
      id: vehicleInspectionListRoute.path,
      path: vehicleInspectionListRoute.path,
      isInMenu: false,
      loadChildren: () =>
        import('../../features/vehicle-inspections/vehicle-inspections.module').then((m) => m.VehicleInspectionsModule),
    },
    {
      id: inspectionsRootPath,
      path: inspectionsRootPath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => {
        return combineLatest([
          permissions.isVehicleInspectionView$(),
          permissions.isEndOfDayInspectionView$(),
          permissions.isEndOfRideInspectionView$(),
        ]).pipe(
          map(
            ([vehicleInspection, endOfDayInspection, endOfRideInspection]) =>
              vehicleInspection || endOfDayInspection || endOfRideInspection,
          ),
        );
      },
      menuIndex: 87,
      menuName: 'Inspections',
      menuSmallFont: false,
      link: inspectionsRoute.getHref({}),
      firstUrlSegment: inspectionsRootPath,
      loadChildren: () => import('../../features/inspections/inspections.module').then((m) => m.InspectionsModule),
    },
    {
      id: inspectionsEndOfRidePath,
      path: inspectionsEndOfRidePath,
      isInMenu: false,
      loadChildren: () =>
        import('../../features/inspections-end-of-ride/inspections-end-of-ride.module').then(
          (m) => m.InspectionsEndOfRideModule,
        ),
    },
    {
      id: inspectionsEndOfDayPath,
      path: inspectionsEndOfDayPath,
      isInMenu: false,
      loadChildren: () =>
        import('../../features/inspections-end-of-day/inspections-end-of-day.module').then(
          (m) => m.InspectionsEndOfDayModule,
        ),
    },
    {
      id: transportationRequestsPath,
      path: transportationRequestsPath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isTransportationRegistrationView$(),
      menuIndex: 88,
      menuName: 'Requests',
      menuSmallFont: false,
      link: transportationRequestListRoute.getHref(),
      firstUrlSegment: transportationRequestsPath,
      loadChildren: () =>
        import('../../features/transportation-requests/transportation-requests.module').then(
          (m) => m.TransportationRequestsModule,
        ),
    },
    {
      id: transportationRequestBusLookupPath,
      path: transportationRequestBusLookupPath,
      isInMenu: false,
      loadChildren: () =>
        import('../../features/transportation-request-bus-lookup/transportation-request-bus-lookup.module').then(
          (m) => m.TransportationRequestBusLookupModule,
        ),
    },
    {
      id: invoicingPath,
      path: invoicingPath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isAccountingView$(),
      menuIndex: 89,
      menuName: 'Accounting',
      link: accountSelectRoute.getHref(),
      firstUrlSegment: invoicingPath,
      loadChildren: () => import('../../features/invoicing/invoicing.module').then((m) => m.InvoicingModule),
    },
    {
      id: 'work-queue',
      path: workQueuePath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => {
        return getWorkQueueTabs(permissions).pipe(map((tabs) => tabs.length > 0));
      },
      menuIndex: 90,
      menuName: 'Work queue',
      link: (permissions: PortalPermissionsFacade): Observable<string> => {
        return getWorkQueueTabs(permissions).pipe(
          map((tabs) => {
            if (tabs.length) {
              return tabs[0].getHref();
            }
            return '';
          }),
        );
      },
      firstUrlSegment: workQueuePath,
      loadChildren: () => import('../../features/work-queue/work-queue.module').then((m) => m.WorkQueueModule),
    },
    {
      id: 'insights',
      path: 'insights',
      isInMenu: false,
      menuIndex: 100,
      menuName: 'Insights',
      link: 'insights',
      firstUrlSegment: 'insights',
      loadChildren: () => import('../../features/insights/insights.module').then((m) => m.InsightsModule),
    },
    {
      id: parkoutEditorPath,
      isInMenu: false,
      path: parkoutEditorPath,
      loadChildren: () =>
        import('../../features/parkout-editor/parkout-editor.module').then((m) => m.ParkoutEditorModule),
    },
    {
      id: parkoutRequestManagePath,
      isInMenu: false,
      path: parkoutRequestManagePath,
      loadChildren: () =>
        import('../../features/parkout-request-manage/parkout-request-manage.module').then(
          (m) => m.ParkoutRequestManageModule,
        ),
    },
    {
      id: 'driver-editor',
      isInMenu: false,
      path: driverEditorPath,
      loadChildren: () => import('../../features/driver-editor/driver-editor.module').then((m) => m.DriverEditorModule),
    },
    {
      id: 'driver-document-editor',
      isInMenu: false,
      path: driverDocumentEditorPath,
      loadChildren: () =>
        import('../../features/driver-document-editor/driver-document-editor.module').then(
          (m) => m.DriverDocumentEditorModule,
        ),
    },
    {
      id: driverStatusEditorPath,
      isInMenu: false,
      path: driverStatusEditorPath,
      loadChildren: () =>
        import('../../features/driver-status-editor/driver-status-editor.module').then(
          (m) => m.DriverStatusEditorModule,
        ),
    },
    {
      id: 'ride-assignment',
      isInMenu: false,
      path: rideAssignmentRoute.path,
      loadChildren: () =>
        import('../../features/rides/ride-assignment/ride-assignment.module').then((m) => m.RideAssignmentModule),
    },
    {
      id: 'vendor-employees',
      path: 'vendor-employees',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isVendorEmployeeMainListView$(),
      menuIndex: 95,
      menuName: 'Employees',
      link: vendorEmployeeListRoute.getHref(),
      firstUrlSegment: 'vendor-employees',
      loadChildren: () =>
        import('../../features/employees-vendor/employees-vendor.module').then((m) => m.EmployeesVendorModule),
    },
    {
      id: 'school-employees',
      path: 'school-employees',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isSchoolEmployeeMainListView$(),
      menuIndex: 96,
      menuName: 'Employees',
      link: schoolEmployeeListRoute.getHref(),
      firstUrlSegment: 'school-employees',
      loadChildren: () =>
        import('../../features/employees-school/employees-school.module').then((m) => m.EmployeesSchoolModule),
    },
    {
      id: studentChangeRequestRootPath,
      path: studentChangeRequestRootPath,
      isInMenu: false,
      loadChildren: () =>
        import('../../features/student-change-requests/student-change-requests.module').then(
          (m) => m.StudentChangeRequestsModule,
        ),
    },
    {
      id: 'timesheet-entries',
      path: 'timesheet-entries',
      isInMenu: false,
      loadChildren: () =>
        import('../../features/timesheet-entries/timesheet-entries.module').then((m) => m.TimesheetEntriesModule),
    },
    {
      id: 'reports',
      path: reportsRootPath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isReportView$(),
      menuIndex: 97,
      menuName: 'Reports',
      link: reportsRoute.getHref(),
      firstUrlSegment: reportsRootPath,
      loadChildren: () => import('../../features/reports/reports.module').then((m) => m.ReportsModule),
    },
    {
      id: 'activities',
      path: 'activities',
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isTimesheetEntryView$(),
      menuIndex: 107,
      menuName: 'Activities',
      link: mainActivityListRoute.getHref(),
      firstUrlSegment: 'activities',
      loadChildren: () => import('../../features/activities/activities.module').then((m) => m.ActivitiesModule),
    },
    {
      id: 'route-group-change-requests',
      path: 'route-group-change-requests',
      isInMenu: false,
      firstUrlSegment: 'route-group-change-requests',
      loadChildren: () =>
        import('../../features/route-group-change-requests/route-group-change-requests.module').then(
          (m) => m.RouteGroupChangeRequestsModule,
        ),
    },
    {
      id: accessManagementRootPath,
      path: accessManagementRootPath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isAccessManagement$(),
      menuIndex: 110,
      menuName: 'Access',
      link: accessUserListRoute.getHref(),
      firstUrlSegment: accessManagementRootPath,
      loadChildren: () =>
        import('../../features/access-management/access-management.module').then((m) => m.AccessManagementModule),
    },
    {
      id: driverBusReportsPath,
      path: driverBusReportsPath,
      isInMenu: true,
      isInMenuForUser$: (permissions: PortalPermissionsFacade) => permissions.isDriverBusReportView$(),
      menuIndex: 111,
      menuName: 'DBR',
      link: 'dbr',
      firstUrlSegment: driverBusReportsPath,
      loadChildren: () =>
        import('../../features/driver-bus-reports/driver-bus-reports.module').then((m) => m.DriverBusReportsModule),
    },
    {
      id: standbyDriverStatusPath,
      path: standbyDriverStatusPath,
      isInMenu: false,
      loadChildren: () =>
        import('../../features/driver-standby-status/driver-standby-status.module').then(
          (m) => m.DriverStandbyStatusModule,
        ),
    },
    {
      id: studentRfidPath,
      isInMenu: false,
      path: studentRfidPath,
      loadChildren: () => import('../../features/student-rfid/student-rfid.module').then((m) => m.StudentRfidModule),
    },
    {
      id: driverAttendanceRootPath,
      isInMenu: false,
      path: driverAttendanceRootPath,
      loadChildren: () =>
        import('../../features/driver-attendance/driver-attendance.module').then((m) => m.DriverAttendanceModule),
    },
    {
      id: driverAttendanceEditorPath,
      isInMenu: false,
      path: driverAttendanceEditorPath,
      loadChildren: () =>
        import('../../features/driver-attendance-editor/driver-attendance-editor.module').then(
          (m) => m.DriverAttendanceEditorModule,
        ),
    },
  ]);
};
