import { createAction, props } from '@ngrx/store';
import * as fromRootTypes from '@rootTypes';
import * as fromApi from 'src/app/api';
import { SignedInDistrict, SignedInVendor } from '@rootTypes/entities/auth';

export const homeGuardAccountRequested = createAction('[HomeGuard]: Account requested');
export const homeGuardAccountSuccess = createAction(
  '[HomeGuard]: Account success',
  props<{
    data: fromApi.account.SelectedAccount;
    permissions: fromRootTypes.PortalPermissionItem[];
    vendor?: SignedInVendor;
    district?: SignedInDistrict;
  }>(),
);
export const homeGuardAccountFailed = createAction(
  '[HomeGuard]: Account failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const selectAccountGuardAccountRequested = createAction('[SelectAccountGuard]: Account requested');
export const selectAccountGuardAccountSuccess = createAction(
  '[SelectAccountGuard]: Account success',
  props<{
    data: fromApi.account.SelectedAccount;
    permissions: fromRootTypes.PortalPermissionItem[];
    vendor?: SignedInVendor;
    district?: SignedInDistrict;
  }>(),
);
export const selectAccountGuardAccountFailed = createAction(
  '[SelectAccountGuard]: Account failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const homeGuardAccountsRequested = createAction('[HomeGuard]: Accounts requested');
export const homeGuardAccountsSuccess = createAction(
  '[HomeGuard]: Accounts success',
  props<{ data: fromApi.account.UserAccount[] | null }>(),
);
export const homeGuardAccountsFailed = createAction(
  '[HomeGuard]: Accounts failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const selectAccountGuardAccountsRequested = createAction('[SelectAccountGuard]: Accounts requested');
export const selectAccountGuardAccountsSuccess = createAction(
  '[SelectAccountGuard]: Accounts success',
  props<{ data: fromApi.account.UserAccount[] | null }>(),
);
export const selectAccountGuardAccountsFailed = createAction(
  '[SelectAccountGuard]: Accounts failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const switchAccountPopupAccountsRequested = createAction('[SwitchAccountPopup]: Accounts requested');
export const switchAccountPopupAccountsSuccess = createAction(
  '[SwitchAccountPopup]: Accounts success',
  props<{ data: fromApi.account.UserAccount[] | null }>(),
);
export const switchAccountPopupAccountsFailed = createAction(
  '[SwitchAccountPopup]: Accounts failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const homeGuardSetAccountRequested = createAction(
  '[HomeGuard]: Set account requested',
  props<{ request: fromApi.account.SetAccountRequest }>(),
);
export const homeGuardSetAccountSuccess = createAction(
  '[HomeGuard]: Set account success',
  props<{ response: fromApi.account.SetAccountResponse }>(),
);
export const homeGuardSetAccountFailed = createAction(
  '[HomeGuard]: Set account failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const selectAccountGuardSetAccountRequested = createAction(
  '[SelectAccountGuard]: Set account requested',
  props<{ request: fromApi.account.SetAccountRequest }>(),
);
export const selectAccountGuardSetAccountSuccess = createAction(
  '[SelectAccountGuard]: Set account success',
  props<{ response: fromApi.account.SetAccountResponse }>(),
);
export const selectAccountGuardSetAccountFailed = createAction(
  '[SelectAccountGuard]: Set account failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const selectAccountPageSetAccountRequested = createAction(
  '[SelectAccountPage]: Set account requested',
  props<{
    request: fromApi.account.SetAccountRequest;
    relayState?: string;
  }>(),
);
export const selectAccountPageSetAccountSuccess = createAction(
  '[SelectAccountPage]: Set account success',
  props<{ response: fromApi.account.SetAccountResponse }>(),
);
export const selectAccountPageSetAccountFailed = createAction(
  '[SelectAccountPage]: Set account failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const switchAccountPopupSetAccountRequested = createAction(
  '[SwitchAccountPopup]: Set account requested',
  props<{ request: fromApi.account.SetAccountRequest }>(),
);
export const switchAccountPopupSetAccountSuccess = createAction(
  '[SwitchAccountPopup]: Set account success',
  props<{ response: fromApi.account.SetAccountResponse }>(),
);
export const switchAccountPopupSetAccountFailed = createAction(
  '[SwitchAccountPopup]: Set account failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const homeGuardCheckAccountStart = createAction('[HomeGuard]: Check account start');
export const homeGuardCheckAccountEnd = createAction('[HomeGuard]: Check account end');

export type GetAccountActionCreator = typeof selectAccountGuardAccountRequested | typeof homeGuardAccountRequested;

export type GetAccountsActionCreator =
  | typeof selectAccountGuardAccountsRequested
  | typeof homeGuardAccountsRequested
  | typeof switchAccountPopupAccountsRequested;

export type GuardSetAccountActionCreator =
  | typeof selectAccountGuardSetAccountRequested
  | typeof homeGuardSetAccountRequested;
